<template>
    <v-container fluid class="fill-height d-flex dark justify-center primary">
        <v-alert  dense
  dismissible
  elevation="1"
  type="error" v-if="errorResult" style="position: fixed;top:10px" >
            Something Went wrong.
        </v-alert>
        <v-card class="pa-10 mx-auto" outlined max-width="75%">

            <v-card-title class="d-flex justify-center mb-2">
                <div class="text-center">Reset Your Password</div>
            </v-card-title>
            <v-card-text class="fill-height pa-0">
               
                <v-form v-if="!successMsg" ref="form" @submit.prevent="onSubmit"
                        id="check-login-form">
                    <v-text-field outlined rounded dense id="mdi-password" prepend-icon="mdi-lock" v-model="password"
                             name="password" label="New Password"
                            :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPwd = !showPwd" 
                            loading>
                            <template v-slot:progress>
                                <v-progress-linear
                                class="ma-2"
                                :value="progress"
                                :color="color"
                                absolute
                                height="10"
                                style="max-width:75%"
                                ></v-progress-linear>
                            </template>
                        </v-text-field>
                       
                        <v-text-field class="mt-2" outlined rounded dense id="mdi-password" prepend-icon="mdi-lock" v-model="confirmpassword"
                             name="confirmpassword" label="Confirm Password"
                            :type="showConfirmPwd ? 'text' : 'password'" :append-icon="showConfirmPwd ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showConfirmPwd = !showConfirmPwd" :error-messages="errorMsg" @focus="errorMsg = ''">
                        </v-text-field>
                        <div class="d-flex justify-center">
                            <v-list>
                                <v-list-item  dense v-for="p in passRules" :key="p.label" :class="p.pass ? 'green--text  text-caption':'grey--text  text-caption'" >
                                    <template v-if="p.pass">
                                        <v-icon  class="mr-1" small color="green ">mdi-check</v-icon> {{p.label}}
                                    </template>
                                    
                                    <template v-if="!p.pass">
                                        <v-icon  class="mr-1" small color="grey ">mdi-minus</v-icon> {{p.label}}
                                    </template>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div class="d-flex justify-center ">
                            <v-btn block rounded :disabled="!enableBtn" color="primary" type="submit" form="check-login-form">Reset Password</v-btn>
                        </div>
                        <div class="mt-2 text-center  text-caption">Remembered your password?<strong style=" cursor: pointer;" @click="backtoLogin" class="text-decoration-underline"> Let's SignIn!</strong>
                        </div>
                </v-form>
                <div class="ma-2" v-if="successMsg">
                    <v-alert
                        outlined
                        text
                        dense
                        type="success"
                        >{{this.successMsg}}</v-alert>
                </div>
                <v-btn v-if="successMsg" rounded block @click="backtoLogin" color="primary">Let's Sign In!</v-btn>
            </v-card-text>
        </v-card>
   
    </v-container>

</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '../stores/user'
export default {
    name: 'ResetPassword',
    props: {
        source: String,
    },
    data() {
        return {
            email:'',
            errorResult:false,
            successMsg:'',
            token:'',
            password:'',
            confirmpassword:'',
            showPwd: false,
            showConfirmPwd:false,
            errorMsg:'',
            passRules:[
                {
                    "label":'Lowercase & Uppercase',
                    "pass": false,
                    check: val => {
                        return val.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)
                    }
                },
                {
                    "label":'Number (0-9)',
                    pass:false,
                    check: val => {
                        return val.match(/([0-9])/)
                    }
                },
                {
                    "label":'Special Character (!@#$%^&*)',
                    pass:false,
                    check: val => {
                        return val.match(/([!,%,&,@,#,$,^,*,?,_,~])/)
                    }
                },
                {
                    "label":'Atleast 8 Character',
                    pass:false,
                    check: val => {
                        return val.length > 7
                    }
                }
            ]
        }
    },
    watch:{
        password(newVal){
            var rules = this.passRules
            rules.forEach(r=>{
                r.pass = r.check(newVal)
            })
            this.passRules = rules
          
        },
        errorResult(new_val){
        if(new_val){
            setTimeout(()=>{this.errorResult=false},3000)
        }
        } 
    },  
    methods: {
        ...mapActions(useUserStore, ['setTenantId','resetPassword']),
        backtoLogin(){
            this.$router.push('login')
        },  
        validate() {
            if (this.password != this.confirmpassword){
                this.errorMsg = "The passwords you entered doesn't match"
                return false
            }
            else{
                this.errorMsg = ''
                return true
            }
        },
        async onSubmit() {
            if (this.validate()) {
               
                const res = await this.resetPassword(this.email,this.password, this.token)
                if(res){
                    this.successMsg = res
                }else{
                    this.errorResult = true
                }
            }
        }
    },
    mounted() {
        const code = this.$route.query.code
        const email = this.$route.query.email
        if( !code || !email){
            this.$router.push('login')
        }
        this.setTenantId()
        this.email = email
        this.token = code
    },
    computed: {
      ...mapState(useUserStore,['tenantid']),
      progress () {
        return Math.min(100,  this.passRules.filter(r=>r.pass).length * 25)
      },
      color () {
        return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
      },
      enableBtn(){
        return  this.passRules.filter(r=>!r.pass).length == 0 && !!this.confirmpassword
      }
    }

}

</script>

