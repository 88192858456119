import axios from 'axios';
//
import router from '@/router';
import { HOST_API } from '@/global-config';
import { useUserStore } from '@/stores/user';

const axiosInstance = axios.create({
    baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = window.localStorage.getItem('token')?.trim();

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    async (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            const userStore = useUserStore();
            router.push('login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
