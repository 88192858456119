<template>
  <v-row class="px-4 py-2">
    <v-card class="overflow-y-auto" width="100%">
      <v-btn class="white--text ma-4" color="primary">
        <v-icon left>mdi-plus</v-icon>Add Product
      </v-btn>
      <v-data-table
        :headers="headers"
        :items-per-page="30"
        fixed-header
        class="products-table"
        :footer-props="footerProps"
        :items="productItems"
        sort-by="category"
        @click:row="() => {}"
        group-by="segment"
      >
        <template v-slot:[`group.header`]="{ items }">
          <div
            class="pl-4 font-weight-bold primary700--text"
            style="background-color: white"
          >
            {{ items[0].segment }}
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="pl-6 font-weight-medium">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:[`item.min`]="{ item }">
          <div class="d-flex primary700--text font-weight-bold">
            <span>$</span>
            <span>{{ getMin(item)?.toFixed(2) }}</span>
          </div>
        </template>
        <template v-slot:[`item.max`]="{ item }">
          <div class="d-flex primary700--text font-weight-bold">
            <span>$</span>
            <span>{{  getMax(item)?.toFixed(2)  }}</span>
          </div>
        </template>
        <template v-slot:[`item.recommendation`]="{ item }">
          <div class="d-flex primary700--text font-weight-bold">
            <span>$</span>

            <span>{{ getRecommendation(item)?.toFixed(2)  }}</span>
          </div>
        </template>
        <template v-slot:[`item.alternativeRequestPrice`]="{ item }">
          <v-text-field
            dense
            outlined
            single-line
            hide-details=""
            v-model="item.alternativeRequestPrice"
            prefix="$"
            background-color="primary50"
            class="primary700--text price-input"
          ></v-text-field>
        </template>
        <template v-slot:[`item.alternativeRequestPercent`]="{ item }">
          <v-text-field
            dense
            outlined
            single-line
            hide-details
            background-color="primary50"
            v-model="item.alternativeRequestPercent"
            suffix="%"
            class="primary700--text price-input"
          ></v-text-field>
        </template>
        <template v-slot:[`item.withinlimit`]="{ item }">
          <v-chip v-if="isWithinLimit(item) === true" color="white" text-color="success700"
            >Within Limits</v-chip
          >
          <v-chip v-else-if="isWithinLimit(item) === false" label color="error50" text-color="error700"
            >Outside Guidelines
            <v-icon right color="error700">mdi-alert-circle-outline</v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.variance`]="{ item }">
          <v-chip
            v-if="isWithinLimit(item) === false"
            color="error50"
            text-color="error700"
            class="px-4"
          >
            <span>$</span>
            <v-spacer></v-spacer>
            <span>{{ calcVarAmount(item) }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.variancePercent`]="{ item }">
          <v-chip
          v-if="isWithinLimit(item) === false"
            color="error50"
            class="px-4"
            text-color="error700"
          >
            <span>{{calcVarPercent(item) }}%</span>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-row>
</template>
<script>
import { pricing } from "@/common/mockdata/structuredpricing";
export default {
  name: "CustomerPricingProductsTable",
  props: {
    customerZone: {
      type: String,
    },
    classification: {
      type: String,
    },
    customerName: {
      type: String,
    },
  },
  data: () => {
    return {
      headers: [
        {
          text: "Products",
          value: "name",
          class: "primary--text",
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Min",
          value: "min",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Max",
          value: "max",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Recommendation",
          value: "recommendation",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Alternative Request",
          value: "alternativeRequestPrice",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Alternative Request",
          value: "alternativeRequestPercent",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Direction",
          value: "withinlimit",
          class: "primary--text",
          width: 200,
          cellClass: "tbcell",
          sortable: false,
        },
        {
          text: "Var.$",
          value: "variance",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
          align: "center",
        },
        {
          text: "Var.%",
          value: "variancePercent",
          class: "primary--text",
          width: 140,
          cellClass: "tbcell",
          sortable: false,
          align: "center",
        },
      ],
      
      productItems: [
        {
          name: "Product 1",
          alternativeRequestPrice: 0,
          alternativeRequestPercent: 0,
          variance: 0,
          variancePercent: 0,
          segment: "Segment 1",
        },
        {
          name: "Product 2",
          alternativeRequestPrice: 0,
          alternativeRequestPercent: 0,
          variance: 0,
          variancePercent: 0,
          segment: "Segment 1",
        },
        {
          name: "Product 3",
          alternativeRequestPrice:0,
          alternativeRequestPercent: 0,
          variance: 0,
          variancePercent: 0,
          segment: "Segment 2",
        },
        {
          name: "Product 4",
          alternativeRequestPrice: 0,
          alternativeRequestPercent: 0,
          variance: 0,
          variancePercent: 0,
          segment: "Segment 3",
        },
      ],
      footerProps: {
        "items-per-page-options": [15, 30, 50, -1],
        showFirstLastPage: true,
      },
    };
  },
  methods: {
    getMin(item) {
      return pricing.find(p=>p.key === `${item.name}_${this.customerZone}_${this.classification}` )?.min
    },
    getMax(item) {
      return pricing.find(p=>p.key === `${item.name}_${this.customerZone}_${this.classification}` )?.max
    },
    getRecommendation(item) {
      return pricing.find(p=>p.key === `${item.name}_${this.customerZone}_${this.classification}` )?.recommendation
    },
    isWithinLimit(item){
      if (item.alternativeRequestPrice === 0 || item.alternativeRequestPrice === undefined ) return undefined
      return (item.alternativeRequestPrice < this.getMax(item)) && (item.alternativeRequestPrice > this.getMin(item))
    },
    calcVarAmount(item){
      return (item.alternativeRequestPrice - this.getRecommendation(item)).toFixed(2)
    },
    calcVarPercent(item){
      return (((item.alternativeRequestPrice - this.getRecommendation(item)) / this.getRecommendation(item)) * 100).toFixed(2)
    }
  },

};
</script>