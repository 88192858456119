/*
Customer Name	Customer code
Morningside Supermarket	1101
Penrith Supermarket	1102
Salisbury Supermarket	1103
Lonsdale Supermarket	1104
Tullamarine Supermarket	1105
Wingfield Restaurant	1106
Laverton Restaurant	1107
Molendinar Restaurant	1108
Chelsea Heights Restaurant	1109
Campbellfield Restaurant	1110
Chelsea Coffee Shop	1111
Wulkuraka Coffee Shop	1112
Chullora Coffee Shop	1113
Brighton Coffee Shop	1114
Salisbury Coffee Shop	1115
*/

export const customers = [
    {
        code: "1101",
        name: "Morningside Supermarket",
    },
    {
        code: "1102",
        name: "Penrith Supermarket",
    },
    {
        code: "1103",
        name: "Salisbury Supermarket",
    },
    {
        code: "1104",
        name: "Lonsdale Supermarket",
    },
    {
        code: "1105",
        name: "Tullamarine Supermarket",
    },
    {
        code: "1106",
        name: "Wingfield Restaurant",
    },
    {
        code: "1107",
        name: "Laverton Restaurant",
    },
    {
        code: "1108",
        name: "Molendinar Restaurant",
    },
    {
        code: "1109",
        name: "Chelsea Heights Restaurant",
    },
    {
        code: "1110",
        name: "Campbellfield Restaurant",
    },
    {
        code: "1111",
        name: "Chelsea Coffee Shop",
    },
    {
        code: "1112",
        name: "Wulkuraka Coffee Shop",
    },
    {
        code: "1113",
        name: "Chullora Coffee Shop",
    },
    {
        code: "1114",
        name: "Brighton Coffee Shop",
    },
    {
        code: "1115",
        name: "Salisbury Coffee Shop",
    },
]


/*
Postcode	State	Zone
0828	NT	Zone 5
0870	NT	Zone 4
2086	NSW	Metro
2190	NSW	Metro
2261	NSW	Zone 1
2317	NSW	Zone 2
2322	NSW	Zone 1
2340	NSW	Zone 3
2360	NSW	Zone 3
2370	NSW	Zone 3
2380	NSW	Zone 3
2390	NSW	Zone 3
2420	NSW	Zone 2
2428	NSW	Zone 2
2430	NSW	Zone 2
2440	NSW	Zone 3
2444	NSW	Zone 3
2450	NSW	Zone 3
2536	NSW	Zone 2
2539	NSW	Zone 2
2541	NSW	Zone 1
2549	NSW	Zone 3
2576	NSW	Zone 1
2580	NSW	Zone 1
2582	NSW	Zone 2
2603	ACT	Zone 2
2630	NSW	Zone 3
2641	NSW	Zone 2
2650	NSW	Zone 3
2653	NSW	Zone 3
2680	NSW	Zone 3
2711	NSW	Zone 3
2720	NSW	Zone 2
2722	NSW	Zone 2
2750	NSW	Metro
2765	NSW	Metro
2794	NSW	Zone 1
2795	NSW	Zone 1
2799	NSW	Zone 1
2800	NSW	Zone 1
2830	NSW	Zone 2
2832	NSW	Zone 3
2835	NSW	Zone 3
2840	NSW	Zone 3
2848	NSW	Zone 1
2850	NSW	Zone 1
2852	NSW	Zone 2
2870	NSW	Zone 2
2880	NSW	Zone 3
3028	VIC	Metro
3043	VIC	Metro
3061	VIC	Metro
3186	VIC	Metro
3196	VIC	Metro
3220	VIC	Zone 1
3250	VIC	Zone 1
3273	VIC	Zone 2
3444	VIC	Zone 1
3505	VIC	Zone 2
3555	VIC	Zone 1
3585	VIC	Zone 2
3630	VIC	Zone 1
3690	VIC	Zone 2
3840	VIC	Zone 1
3875	VIC	Zone 2
3940	VIC	Zone 1
3950	VIC	Zone 1
4107	QLD	Metro
4170	QLD	Metro
4214	QLD	Metro
4305	QLD	Metro
4312	QLD	Metro
4350	QLD	Zone 1
4415	QLD	Zone 2
4455	QLD	Zone 2
4556	QLD	Zone 1
4655	QLD	Zone 1
4670	QLD	Zone 1
4680	QLD	Zone 2
4702	QLD	Zone 2
4740	QLD	Zone 2
4807	QLD	Zone 2
4814	QLD	Zone 1
4820	QLD	Zone 2
4825	QLD	Zone 2
4850	QLD	Zone 1
4854	QLD	Zone 1
4870	QLD	Zone 1
4877	QLD	Zone 1
4880	QLD	Zone 1
4885	QLD	Zone 1
5013	SA	Metro
5031	SA	Metro
5108	SA	Metro
5160	SA	Metro
5253	SA	Zone 1
5271	SA	Zone 2
5491	SA	Zone 1
5573	SA	Zone 1
5606	SA	Zone 3
5690	SA	Zone 3
5700	SA	Zone 2
6058	WA	Zone 1
6102	WA	Zone 1
6172	WA	Zone 1
6230	WA	Zone 1
6530	WA	Zone 2
6707	WA	Zone 3
6714	WA	Zone 3
6721	WA	Zone 3
6743	WA	Zone 3
6751	WA	Zone 3
7008	TAS	Zone 1
7030	TAS	Metro
7212	TAS	Zone 1
7310	TAS	Zone 1
7320	TAS	Zone 1
*/

export const zones = [
    {
        postCode: "2261",
        state: "NSW",
        name: "Zone 1",
    },
    {
        postCode: "2317",
        state: "NSW",
        name: "Zone 2",
    },
    {
        postCode: "2322",
        state: "NSW",
        name: "Zone 1",
    },
    {
        postCode: "2340",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2360",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2370",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2380",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2390",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2420",
        state: "NSW",
        name: "Zone 2",
    },
    {
        postCode: "2428",
        state: "NSW",
        name: "Zone 2",
    },
    {
        postCode: "2430",
        state: "NSW",
        name: "Zone 2",
    },
    {
        postCode: "2440",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2444",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2450",
        state: "NSW",
        name: "Zone 3",
    },
    {
        postCode: "2536",
        state: "NSW",
        name: "Zone 2",
    },
    {
        postCode: "2539",
        state: "NSW",
        name: "Zone 2",
    },
]


export const products = [
    {
        name: "Product 1",
        segment: "Segment 1",
    },
    {
        name: "Product 2",
        segment: "Segment 1",
    },
    {
        name: "Product 3",
        segment: "Segment 2",
    },
    {
        name: "Product 4",
        segment: "Segment 3",
    }
]

/*
Concatenate	Max	Min	Recommendation
Product 1_Zone 1_A	31.0	21.0	24
Product 1_Zone 1_B	32.0	22.0	25.0
Product 1_Zone 1_C	33.0	23.0	26.0
Product 1_Zone 2_A	34.0	24.0	27.0
Product 1_Zone 2_B	35.0	25.0	28.0
Product 1_Zone 2_C	36.0	26.0	29.0
Product 1_Zone 3_A	37.0	27.0	30.0
Product 1_Zone 3_B	38.0	28.0	31.0
Product 1_Zone 3_C	39.0	29.0	32.0
Product 2_Zone 1_A	50.0	40.0	44.0
Product 2_Zone 1_B	52.0	42.0	46.0
Product 2_Zone 1_C	54.0	44.0	48.0
Product 2_Zone 2_A	56.0	46.0	50.0
Product 2_Zone 2_B	58.0	48.0	52.0
Product 2_Zone 2_C	60.0	50.0	54.0
Product 2_Zone 3_A	62.0	52.0	56.0
Product 2_Zone 3_B	64.0	54.0	58.0
Product 2_Zone 3_C	66.0	56.0	60.0
Product 3_Zone 1_A	56.0	46.0	49.0
Product 3_Zone 1_B	57.0	47.0	50.0
Product 3_Zone 1_C	58.0	48.0	51.0
Product 3_Zone 2_A	59.0	49.0	52.0
Product 3_Zone 2_B	60.0	50.0	53.0
Product 3_Zone 2_C	61.0	51.0	54.0
Product 3_Zone 3_A	62.0	52.0	55.0
Product 3_Zone 3_B	63.0	53.0	56.0
Product 3_Zone 3_C	64.0	54.0	57.0
Product 4_Zone 1_A	100.0	80.0	85.0
Product 4_Zone 1_B	102.0	82.0	87.0
Product 4_Zone 1_C	104.0	84.0	89.0
Product 4_Zone 2_A	106.0	86.0	91.0
Product 4_Zone 2_B	108.0	88.0	93.0
Product 4_Zone 2_C	110.0	90.0	95.0
Product 4_Zone 3_A	112.0	92.0	97.0
Product 4_Zone 3_B	114.0	94.0	99.0
Product 4_Zone 3_C	116.0	96.0	101.0
*/
export const pricing = [
    {
        key: "Product 1_Zone 1_A",
        max: 31,
        min: 21,
        recommendation: 24,
    },
    {
        key: "Product 1_Zone 1_B",
        max: 32,
        min: 22,
        recommendation: 25,
    },
    {
        key: "Product 1_Zone 1_C",
        max: 33,
        min: 23,
        recommendation: 26,
    },
    {
        key: "Product 1_Zone 2_A",
        max: 34,
        min: 24,
        recommendation: 27,
    },
    {
        key: "Product 1_Zone 2_B",
        max: 35,
        min: 25,
        recommendation: 28,
    },
    {
        key: "Product 1_Zone 2_C",
        max: 36,
        min: 26,
        recommendation: 29,
    },
    {
        key: "Product 1_Zone 3_A",
        max: 37,
        min: 27,
        recommendation: 30,
    },
    {
        key: "Product 1_Zone 3_B",
        max: 38,
        min: 28,
        recommendation: 31,
    },
    {
        key: "Product 1_Zone 3_C",
        max: 39,
        min: 29,
        recommendation: 32,
    },
    {
        key: "Product 2_Zone 1_A",
        max: 50,
        min: 40,
        recommendation: 44,
    },
    {
        key: "Product 2_Zone 1_B",
        max: 52,
        min: 42,
        recommendation: 46,
    },
    {
        key: "Product 2_Zone 1_C",
        max: 54,
        min: 44,
        recommendation: 48,
    },
    {
        key: "Product 2_Zone 2_A",
        max: 56,
        min: 46,
        recommendation: 50,
    },
    {
        key: "Product 2_Zone 2_B",
        max: 58,
        min: 48,
        recommendation: 52,
    },
    {
        key: "Product 2_Zone 2_C",
        max: 60,
        min: 50,
        recommendation: 54,
    },
    {
        key: "Product 2_Zone 3_A",
        max: 62,
        min: 52,
        recommendation: 56,
    },
    {
        key: "Product 2_Zone 3_B",
        max: 64,
        min: 54,
        recommendation: 58,
    },
    {
        key: "Product 2_Zone 3_C",
        max: 66,
        min: 56,
        recommendation: 60,
    },
    {
        key: "Product 3_Zone 1_A",
        max: 56,
        min: 46,
        recommendation: 49,
    },
    {
        key: "Product 3_Zone 1_B",
        max: 57,
        min: 47,
        recommendation: 50,
    },
    {
        key: "Product 3_Zone 1_C",
        max: 58,
        min: 48,
        recommendation: 51,
    },
    {
        key: "Product 3_Zone 2_A",
        max: 59,
        min: 49,
        recommendation: 52,
    },
    {
        key: "Product 3_Zone 2_B",
        max: 60,
        min: 50,
        recommendation: 53,
    },
    {
        key: "Product 3_Zone 2_C",
        max: 61,
        min: 51,
        recommendation: 54,
    },
    {
        key: "Product 3_Zone 3_A",
        max: 62,
        min: 52,
        recommendation: 55,
    },
    {
        key: "Product 3_Zone 3_B",
        max: 63,
        min: 53,
        recommendation: 56,
    },
    {
        key: "Product 3_Zone 3_C",
        max: 64,
        min: 54,
        recommendation: 57,
    },
    {
        key: "Product 4_Zone 1_A",
        max: 100,
        min: 80,
        recommendation: 85,
    },
    {
        key: "Product 4_Zone 1_B",
        max: 102,
        min: 82,
        recommendation: 87,
    },
    {
        key: "Product 4_Zone 1_C",
        max: 104,
        min: 84,
        recommendation: 89,
    },
    {
        key: "Product 4_Zone 2_A",
        max: 106,
        min: 86,
        recommendation: 91,
    },
    {
        key: "Product 4_Zone 2_B",
        max: 108,
        min: 88,
        recommendation: 93,
    },
    {
        key: "Product 4_Zone 2_C",
        max: 110,
        min: 90,
        recommendation: 95,
    },
    {
        key: "Product 4_Zone 3_A",
        max: 112,
        min: 92,
        recommendation: 97,
    },
    {
        key: "Product 4_Zone 3_B",
        max: 114,
        min: 94,
        recommendation: 99,
    },
    {
        key: "Product 4_Zone 3_C",
        max: 116,
        min: 96,
        recommendation: 101,
    }
]