<template src="./forecast_main.html"></template>

<script>
import { usePlanStore } from '@/stores/plan';
import { masterDataStore } from '@/stores/masterdata';
import { useUserStore } from '@/stores/user';
import { mapState, mapActions } from 'pinia';
import planProductHeaders from '../../../../common/planproductsheaders';
import { formatNumber } from '../../../../common/methods';
import { updatePlan } from '@/common/contextapi';
import Vue from 'vue';
import ConfirmDialog from '../../ConfirmDialog.vue';
import PowerBILoader from '../../PowerBILoader.vue';
import DUMMY_INPUTS from './dummy_inputs.js';
import { calculateStatusOfProducts } from '@/lib';

const dayjs = require('dayjs');

export default {
    name: 'Forecast_Main',
    data: () => ({
        viewReportMode: false,
        alertError: false,
        isFormValid: false,
        isFormValid2: false,
        showDateDialog: false,
        showColumnsError: false,
        accordions: [0],
        showAccordion: true,
        selectedStartDate: null,
        activeFilters: {},
        metricsData: null,
        showInputsLookupDialog: false,
        weeksData: null,
        inputsData: null,
        panel: [0],
        plansavesnackbar: false,
        forecast: [],
        duplicatePlanDialog: false,
        duplicatePlanMode: false,
        customerId: null,
        segmentId: null,
        selectedProductId: null,
        showPanel: true,
        search: '',
        dialogDelete: false,
        dialogPlanDelete: false,
        strategies: ['Promo', 'CPI', 'NPD'],
        promoMechanicList: ['EDLP', 'High Low', 'Multibuy'],
        addProductDialog: false,
        selectAllCols: false,
        colsDialog: false,
        dialog: false,
        loading: false,
        selectedPlanId: null,
        selectedWeekVal: {},
        deSelectWeekMsg: null,
        newPlanDialog: false,
        newPlanName: '',
        WeekComment: '',
        WeekFinalValPV: '',
        WeekMultibuyRP: '',
        WeekDisplay: false,
        dateMenu: false,
        newPlanStartDate: null,
        WeekCategory: false,
        newPlanYear: '',
        weekcalData: null,
        newPlanCategory: '',
        switchValue: false,
        newPlanMode: false,
        editedItem: {},
        activeCols: {},
        snackbar: false,
        selectedItem: null,
        weekcalBody: null,
        selectedCols: {},
        selectedInputs: {},
        saveAsPlanName: null,
        activeColsList: ['productName', 'customer', 'category', 'productCode', 'size', 'details'],
        activeInputsList: [],
        showInputsError: false,
        promoGridDetails: ['rsp'],
        fixedClasses: [
            'colum_one',
            'colum_two',
            'colum_three',
            'colum_four',
            'colum_five',
            'colum_six',
        ],
        selectedDetails: [],
        dateAlreadySelected: false,
        page: 1,
        isFullView: true,
        pageSize: 1,
        saveAsDialog: false,
        planEndDate: null,
        iscCalculatedWeek: false,
        items: ['2024', '2025', '2026', '2027', '2028'],
        productsList: null,
        rules: {
            required: (value) => !!value || 'Required.',
            isNumber: (value) => !isNaN(value) || 'Invalid Number',
            positiveNumber: (value) =>
                (!isNaN(value) && parseFloat(value) >= 0) || 'Invalid Number',
            frequency: (value) =>
                (!isNaN(value) && value > 0 && value <= 52) ||
                'Total Frequency cannot be greater than 52',
        },
        ex11: [
            'red',
            'info',
            'error',
            'indigo',
            'orange',
            'success',
            'warning',
            'primary',
            'secondary',
            'red-darken-3',
            'indigo-darken-3',
            'orange-darken-3',
        ],
        row1Height: 0,
        row2Height: 420,
        toBeDeletedPlanProducts: [],
        defaultItem: {},
        dirtyItems: [],

        filterFuncs: {},
        //add new prod filters
        categoryFilter: [],
        brandFilter: [],
        segmentFilter: [],
        location: 'end',
        //
        dateFormatOptions: { year: '2-digit', month: 'numeric', day: 'numeric' },

        //
        percentFields: [
            'roi_nr',
            'roi_gm',
            'promo_discount',
            'percentage_of_profit_pool_planned',
            'percentage_promo_funding_planned',
            'rm_percentage_planned',
            'gm_percentage_planned',
            'ti_percentage_planned',
        ],
    }),
    components: {
        ConfirmDialog: ConfirmDialog,
        PowerBILoader: PowerBILoader,
    },
    computed: {
        ...mapState(usePlanStore, [
            'setError',
            'draftPlan',
            'draftPlanData',
            'fcPlans',
            'existingFcPlans',
            'fcTableData',
            'draftPlanBase',
            'productCols',
            'unsavedChanges',
            'deleteFcPlanHandler',
        ]),
        ...mapState(masterDataStore, ['products', 'referenceData', 'specialRefData']),
        ...mapState(masterDataStore, ['products', 'referenceData', 'specialRefData']),
        ...mapState(useUserStore, ['userProfile']),
        filteredProducts() {
            var filteredProducts = JSON.parse(JSON.stringify(this.products));
            if (this.categoryFilter.length > 0) {
                filteredProducts = filteredProducts.filter((p) =>
                    this.referenceData.categories
                        .filter((c) => this.categoryFilter.includes(c.name))
                        ?.map((c) => {
                            return c.id;
                        })
                        .includes(p.categoryId)
                );
            }
            if (this.brandFilter.length > 0) {
                filteredProducts = filteredProducts.filter((p) =>
                    this.referenceData.brands
                        .filter((c) => this.brandFilter.includes(c.name))
                        ?.map((c) => {
                            return c.id;
                        })
                        .includes(p.brandId)
                );
            }

            return filteredProducts;
        },
        canEditPlan() {
            return this.draftPlan.createdBy == this.userProfile.id || this.draftPlan.newName;
        },

        filters() {
            let filters = {};
            planProductHeaders
                .filter((h) => h.basicFilter)
                .forEach((h) => {
                    filters[h.value] = [];
                });
            return filters;
        },

        userFcPlans() {
            const sbPlans = this.fcPlans.sbPlans;
            // const plandata = sbPlans?.map((p) => p.name);
            console.log('sbPlans', sbPlans);
            return sbPlans;
        },

        userExistingFcPlans() {
            const data = this.existingFcPlans;
            // const plandata = sbPlans?.map((p) => p.name);
            console.log('data12', data);
            return data;
        },

        clashReportItemData() {
            const data = this.clashingPlans;
            // const plandata = sbPlans?.map((p) => p.name);
            console.log('data666', data);
            return data;
        },

        // FcTableData() {
        //     const tableData = this.fcTableData.forecast;
        //     // const plandata = sbPlans?.map((p) => p.name);
        //     // console.log("sbPlans", plandata);
        //     return tableData;
        // },

        userFcCategory() {
            const category = this.fcPlans.category;
            // const categoryData = category?.map((p) => p.name);
            return category;
        },

        userFcYear() {
            const years = this.fcPlans.years;
            console.log('sbPlans12', years);
            return years;
        },

        // rows been added/edited/deleted
        hasChanges() {
            return this.draftPlan.newName != undefined || this.dirtyItems.length > 0;
        },

        headers() {
            planProductHeaders
                .filter((h) => h.basicFilter)
                .forEach((h) => {
                    h.filter = (value) => {
                        return this.activeFilters && this.activeFilters[h.value]
                            ? this.activeFilters[h.value].includes(value)
                            : true;
                    };
                });
            let hs = planProductHeaders
                .filter((h) => this.activeCols[h.value] == true)
                .concat([
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false,
                        class: 'primary--text',
                        width: 100,
                    },
                ]);
            hs.forEach((h) => {
                if (this.filterFuncs[h.value]) {
                    h.filter = this.filterFuncs[h.value];
                }
            });
            return hs;
        },
        draftProducts() {
            var products = this.draftPlan.products;
            return products;
        },
        pldata() {
            return [
                {
                    description: 'Volume (Units)',
                    shortdesc: 'Vol.',
                    original: this.draftPlanBase.sumTotalUnits,
                    new: this.sumTotalUnits,
                    variance: this.totalUnitsVar,
                    variancepercent: this.totalUnitsVarPercent,
                },
                {
                    description: 'Gross Sales Value',
                    shortdesc: 'GSV',
                    original: this.draftPlanBase.sumGSV,
                    new: this.sumGSV,
                    variance: this.sumGSVVar,
                    variancepercent: this.sumGSVPercent,
                },
                {
                    description: 'Trade Investment',
                    shortdesc: 'TI',
                    original: this.draftPlanBase.sumTI,
                    new: this.sumTI,
                    variance: this.sumTIDiff,
                    variancepercent: this.sumTIPercent,
                },
                {
                    description: 'Net Revenue',
                    shortdesc: 'NR',
                    original: this.draftPlanBase.sumNR,
                    new: this.sumNR,
                    variance: this.sumNRDiff,
                    variancepercent: this.sumNRPercent,
                },
                {
                    description: 'COGS',
                    shortdesc: 'COGS',
                    original: this.draftPlanBase.sumCOGS,
                    new: this.sumCOGS,
                    variance: this.sumCOGSDiff,
                    variancepercent: this.sumCOGSPercent,
                },
                {
                    description: 'Gross Margin',
                    shortdesc: 'GM',
                    original: this.draftPlanBase.sumGM,
                    new: this.sumGM,
                    variance: this.sumGMDiff,
                    variancepercent: this.sumGMPercent,
                },
                {
                    description: 'Retailer Margin',
                    shortdesc: 'RM',
                    original: this.draftPlanBase.sumRM,
                    new: this.sumRM,
                    variance: this.sumRMDiff,
                    variancepercent: this.sumRMPercent,
                },
                {
                    description: 'Retaile Sales value',
                    shortdesc: 'RSV',
                    original: this.draftPlanBase.sumRSV,
                    new: this.sumRSV,
                    variance: this.sumRSVDiff,
                    variancepercent: this.sumRSVPercent,
                },
                {
                    description: 'Gross Margin %',
                    shortdesc: 'GM %',
                    original: this.originalGMPercent,
                    new: this.newGMPercent,
                    variance: this.gmPercentDiff,
                },
                {
                    description: 'Retailer Margin %',
                    shortdesc: 'RM %',
                    original: this.originalRMPercent,
                    new: this.newRMPercent,
                    variance: this.rmPercentDiff,
                },
            ];
        },
        sumTotalUnits() {
            return this.draftPlan.products
                .filter((p) => p.totalUnits > 0)
                .reduce((a, b) => a + b.totalUnits, 0);
        },
        totalUnitsVar() {
            return this.sumTotalUnits - this.draftPlanBase.sumTotalUnits;
        },
        totalUnitsVarPercent() {
            return ((this.totalUnitsVar / this.draftPlanBase.sumTotalUnits) * 100).toFixed(2);
        },
        sumGSV() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.gsv))
                .reduce((a, b) => a + parseFloat(b.gsv), 0);
        },
        sumGSVVar() {
            return this.sumGSV - this.draftPlanBase.sumGSV;
        },
        sumGSVPercent() {
            return ((this.sumGSVVar / this.draftPlanBase.sumGSV) * 100).toFixed(2);
        },

        sumTI() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.tradeInvestment))
                .reduce((a, b) => a + parseFloat(b.tradeInvestment), 0);
        },
        sumTIDiff() {
            return this.sumTI - this.draftPlanBase.sumTI;
        },
        sumTIPercent() {
            return ((this.sumTIDiff / this.draftPlanBase.sumTI) * 100).toFixed(2);
        },

        sumNR() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.netRevenue))
                .reduce((a, b) => a + parseFloat(b.netRevenue), 0);
        },
        sumNRDiff() {
            return this.sumNR - this.draftPlanBase.sumNR;
        },
        sumNRPercent() {
            return ((this.sumNRDiff / this.draftPlanBase.sumNR) * 100).toFixed(2);
        },

        sumCOGS() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.cogs))
                .reduce((a, b) => a + parseFloat(b.cogs), 0);
        },
        sumCOGSDiff() {
            return this.sumCOGS - this.draftPlanBase.sumCOGS;
        },
        sumCOGSPercent() {
            return ((this.sumCOGSDiff / this.draftPlanBase.sumCOGS) * 100).toFixed(2);
        },
        sumGM() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.grossMargin))
                .reduce((a, b) => a + parseFloat(b.grossMargin), 0);
        },
        sumGMDiff() {
            return this.sumGM - this.draftPlanBase.sumGM;
        },
        sumGMPercent() {
            return ((this.sumGMDiff / this.draftPlanBase.sumGM) * 100).toFixed(2);
        },

        sumRM() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.retailerMargin))
                .reduce((a, b) => a + parseFloat(b.retailerMargin), 0);
        },
        sumRMDiff() {
            return this.sumRM - this.draftPlanBase.sumRM;
        },
        sumRMPercent() {
            return ((this.sumRMDiff / this.draftPlanBase.sumRM) * 100).toFixed(2);
        },

        sumRSV() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.rsv))
                .reduce((a, b) => a + parseFloat(b.rsv), 0);
        },
        sumRSVDiff() {
            return this.sumRSV - this.draftPlanBase.sumRSV;
        },
        sumRSVPercent() {
            return ((this.sumRSVDiff / this.draftPlanBase.sumRSV) * 100).toFixed(2);
        },
        originalGMPercent() {
            return (
                ((this.draftPlanBase.sumGM / this.draftPlanBase.sumNR) * 100)
                    .toFixed(2)
                    .toString() + '%'
            );
        },
        newGMPercent() {
            return ((this.sumGM / this.sumNR) * 100).toFixed(2).toString() + '%';
        },
        gmPercentDiff() {
            return (
                (
                    parseFloat(this.newGMPercent.slice(0, -1)) -
                    parseFloat(this.originalGMPercent.slice(0, -1))
                )
                    .toFixed(2)
                    .toString() + '%'
            );
        },
        originalRMPercent() {
            return (
                ((this.draftPlanBase.sumRM / (this.draftPlanBase.sumRSV / 1.1)) * 100)
                    .toFixed(2)
                    .toString() + '%'
            );
        },
        newRMPercent() {
            return ((this.sumRM / (this.sumRSV / 1.1)) * 100).toFixed(2).toString() + '%';
        },
        rmPercentDiff() {
            return (
                (
                    parseFloat(this.newRMPercent.slice(0, -1)) -
                    parseFloat(this.originalRMPercent.slice(0, -1))
                )
                    .toFixed(2)
                    .toString() + '%'
            );
        },

        tableHeight() {
            return window.innerHeight - this.row1Height - this.row2Height - 40;
        },
        comparisonPlan() {
            return this.fcPlans.find((p) => p.id == this.draftPlan.comparisionPlanId) || {};
        },
        comparisonGrowth() {
            let val =
                ((this.sumNR - this.comparisonPlan?.revenue) / this.comparisonPlan?.revenue) * 100;
            return (isNaN(val) ? 0 : val).toFixed(2);
        },
        comparisonGrowthInMillion() {
            let val = (this.sumNR - this.comparisonPlan?.revenue) / 1000000;
            return (isNaN(val) ? 0 : val).toFixed(0);
        },
        footerProps() {
            return {
                'items-per-page-options': [15, 30, 50, -1],
                showFirstLastPage: true,
            };
        },
        sortedPromoTypes() {
            return this.specialRefData.promotypes.slice()?.sort((a, b) => a.rspRate - b.rspRate);
        },
    },
    methods: {
        log(item) {
            console.log(item);
        },
        isInputField(detailName) {
            if (
                detailName === 'RSP' ||
                detailName === 'Comments' ||
                detailName === 'Multibuy redemption %' ||
                detailName === 'Catalogue' ||
                detailName === 'Display' ||
                detailName === 'Fixed Amount (Per week)'
            ) {
                return true;
            } else {
                return false;
            }
        },
        ...mapActions(usePlanStore, [
            'getFcPlanList',
            'getExistingFcPlanList',
            'getClasingPlansList',
            'getFcTableDataHandler',
            'getFcSavedTableDataHandler',
            'getWeekCalDataHandler',
            'GetClashingTableData',
            'LoadExistingFcPlan',
            'LoadExistingPlan',
            'AddPlanProduct',
            'UpdateEditRowItem',
            'duplicateCurrentPlan',
            'duplicateCurrentFcPlan',
            'getDetailsListHandler',
            'UpdatePlanProducts',
            'CreatePlan',
            'DeletePlan',
            'tearDown',
            'ClearUnSavedChanges',
            'saveFcPlanHandler',
            'saveAsFcPlanHandler',
            'getProductsFromCustomerAndSegmentHandler',
        ]),
        ...mapActions(masterDataStore, ['GetAllProducts']),
        ...mapActions(useUserStore, ['setSelectedItemObj']),
        onViewReport() {
            this.viewReportMode = true;
            //window.open("https://app.powerbi.com/groups/me/reports/55633fdd-4559-4ca4-977e-e4175b122514/ReportSection4c4e272100478c370c8b?experience=power-bi")
        },
        resetPage() {
            this.tearDown();
            this.forecast = [];
            this.selectedStartDate = null;
            this.productsList = null;
            this.customerId = null;
            this.segmentId = null;
            this.selectedProductId = null;
        },
        checkSelectionText() {
            var count = 0;
            var text = '';

            if (!this.customerId) {
                text = count > 0 ? text + ', Customer' : 'Customer';
                count++;
            }

            if (!this.segmentId) {
                text = count > 0 ? text + ', Segment' : 'Segment';
                count++;
            }

            if (!this.selectedProductId) {
                text = count > 0 ? text + ', Product' : 'Product';
                count++;
            }

            if (!this.selectedStartDate) {
                text = count > 0 ? text + ', Start Date' : 'Start Date';
                count++;
            }

            return `Select '${text?.toUpperCase()}'`;
        },
        beforeWindowUnload(e) {
            console.log(111);
            if (this.dirtyItems.length > 0) {
                e.preventDefault();
                e.returnValue = 'sure?';
            }
        },
        clearAddProductFilters() {
            this.brandFilter = [];
            this.categoryFilter = [];
            this.segmentFilter = [];
        },
        isExceedFrequency(item) {
            var groupProducts = this.draftPlan.products.filter(
                (p) => p.productId == item.productId && p.customerId == item.customerId
            );
            var totalFrequency = groupProducts
                .map((p) => {
                    return p.frequency;
                })
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

            return totalFrequency > 52;
        },
        groupProductTotalFrequency(item) {
            var groupProducts = this.draftPlan.products.filter(
                (p) => p.productId == item.productId && p.customerId == item.customerId
            );
            var totalFrequency = groupProducts
                .map((p) => {
                    return p.frequency;
                })
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            return totalFrequency;
        },
        newPlanNameRule(value) {
            return (
                this.fcPlans.findIndex((p) => p.name == value) < 0 || 'Plan name already existed'
            );
        },
        numberOnly(evt) {
            evt = evt ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();

            if (!/^[1-9]\d*$/g.test(expect)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        decimalOnly(evt) {
            evt = evt ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();
            if (evt.key == '.') {
                expect = parseFloat(expect);
            }

            if (!/^\s*?\d+(\.\d{1,2})?\s*$/.test(expect)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onRowError(val) {
            if (val) {
                this.editedItem.errors += this.editedItem.errors + 1;
            } else {
                this.editedItem.errors += this.editedItem.errors - 1;
            }
        },
        row_classes(item) {
            if (
                !this.draftPlan.newName &&
                item.isNew == true &&
                this.dirtyItems.length > 0 &&
                this.dirtyItems.findIndex((i) => i.id == item.id) != -1
            ) {
                return 'dirtyRow'; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        initFilters() {
            for (var col in this.filters) {
                this.filters[col] = this.draftPlan.products
                    .map((d) => {
                        return d[col];
                    })
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
            }
            // TODO restore previous activeFilters before add/remove item
            this.activeFilters = Object.assign({}, this.filters);
            /*if (Object.keys(this.activeFilters).length === 0) this.activeFilters = Object.assign({}, this.filters)
			  else {
				setTimeout(() => {
				  console.log(this.activeFilters)
				  //this.activeFilters = Object.assign({}, this.filters)
				}, 1)
			  }*/
        },
        async saveAsNewPlan() {
            this.loading = true;

            let params = {
                name: this.saveAsPlanName,
                startDate: this.selectedStartDate,
            };

            await this.saveAsFcPlanHandler(this.forecast[0], params);

            await this.getExistingFcPlanList();

            this.saveAsDialog = false;
            this.loading = false;

            this.loading = false;
        },
        async onSavePlan(isSaveAs = false) {
            console.log('PLAN SAVED', this.forecast[0]);

            this.loading = true;

            await this.saveFcPlanHandler(this.forecast[0], this.selectedStartDate);

            await this.getExistingFcPlanList();

            this.loading = false;
        },

        onNewPlanCancel() {
            if (this.duplicatePlanMode) {
                this.newPlanDialog = false;
                this.newPlanMode = false;
                this.selectedPlanId = null;
                this.newPlanName = '';
                this.duplicatePlanMode = false;
            } else {
                this.newPlanMode = false;
                this.newPlanName = '';
            }
        },
        recalculate(item) {
            planProductHeaders
                .filter((h) => h.formula)
                .slice()
                .sort((a, b) => a.calLvl - b.calLvl)
                .forEach((h) => {
                    h.formula(item);
                });
            this.updateDirtyItems(item);
        },
        updateDirtyItems(item) {
            let tempItem = this.dirtyItems.find((i) => i.id == item.id);
            if (tempItem) {
                tempItem = item;
            } else {
                this.dirtyItems.push(item);
                this.unsavedChanges.push(item);
            }
        },
        onRowClick(item) {
            this.editedItem = item;
        },
        getIncrementalTI(item) {
            let val = 0;
            let promoType = this.getPromoType(item);
            let range = this.draftPlan.products.filter(
                (p) =>
                    p.productId == item.productId &&
                    p.segment == item.segment &&
                    this.getPromoType(p) == promoType
            );
            let aveTiPerUnit =
                range.map((p) => p.tiPerUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            let aveBaseUnit =
                range.map((p) => p.baseUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            val = item.tiPerUnit * item.volPerWeek - aveTiPerUnit * aveBaseUnit;
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(0);
        },
        getIncrementalNR(item) {
            let val = 0;
            let promoType = this.getPromoType(item);
            let range = this.draftPlan.products.filter(
                (p) =>
                    p.productId == item.productId &&
                    p.segment == item.segment &&
                    this.getPromoType(p) == promoType
            );
            let aveNetLandedPrice =
                range
                    .map((p) => p.netLandedPrice)
                    .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / range.length;
            let aveBaseUnit =
                range.map((p) => p.baseUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            val = item.netLandedPrice * item.volPerWeek - aveNetLandedPrice * aveBaseUnit;
            console.warn('getIncrementalNR');
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(0);
        },
        getIncrementalGM(item) {
            let val = 0;
            let promoType = this.getPromoType(item);
            let range = this.draftPlan.products.filter(
                (p) =>
                    p.productId == item.productId &&
                    p.segment == item.segment &&
                    this.getPromoType(p) == promoType
            );
            let aveGMPerUnit =
                range.map((p) => p.gmPerUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            let aveBaseUnit =
                range.map((p) => p.baseUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            val = item.gmPerUnit * item.volPerWeek - aveGMPerUnit * aveBaseUnit;
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(0);
        },
        getROINR(item) {
            let val = (this.getIncrementalNR(item) * 100) / this.getIncrementalTI(item);
            return this.getPromoType(item) == 'Base' ? 0 : isNaN(val) ? 0 : val.toFixed(0);
        },
        getROIGM(item) {
            let val = (this.getIncrementalGM(item) * 100) / this.getIncrementalTI(item);
            return this.getPromoType(item) == 'Base' ? 0 : isNaN(val) ? 0 : val.toFixed(0);
        },
        getPromoType(item) {
            if (!item.rsp || !item.baseRSP) return 'Error';
            let rate = (1 - item.rsp / item.baseRSP) * 100;
            if (rate < 0) return 'Error';
            let promoType = 'Error';
            //sorted = sorted.slice().sort((a, b) => a.rspRate - b.rspRate)
            for (let idx = 0; idx < this.sortedPromoTypes.length; idx++) {
                if (
                    idx == this.sortedPromoTypes.length - 1 &&
                    rate > this.sortedPromoTypes[idx].rspRate
                ) {
                    promoType = this.sortedPromoTypes[idx].name;
                    break;
                }
                if (
                    rate == this.sortedPromoTypes[idx].rspRate ||
                    (rate > this.sortedPromoTypes[idx].rspRate &&
                        rate < this.sortedPromoTypes[idx + 1].rspRate)
                ) {
                    promoType = this.sortedPromoTypes[idx].name;
                    break;
                }
            }
            //item.promoType = promoType

            return promoType;
        },

        onSelectedTableCols() {
            const selected = Object.entries(this.selectedCols)
                .filter((item) => {
                    if (item[1] && item[0] !== 'details') {
                        return item[0];
                    }
                })
                .map((item) => item[0]);

            if (selected?.length <= 5) {
                this.showColumnsError = false;
                this.activeColsList = [...selected, 'details'];
                this.colsDialog = false;
            } else {
                this.showColumnsError = true;
            }
        },

        allowedStartDate(val) {
            return this.editedItem && val
                ? dayjs(val).isBefore(dayjs(this.editedItem.endDate))
                : true;
        },

        allowedEndDate(val) {
            return this.editedItem && val
                ? dayjs(val).isAfter(dayjs(this.editedItem.startDate))
                : true;
        },

        // onSelectAllChange(val) {
        //     var cols = {};
        //     if (val) {
        //         planProductHeaders.forEach((h) => {
        //             cols[h.value] = true;
        //         });
        //     } else {
        //         Object.assign(cols, this.selectedCols);
        //         for (let k in cols) {
        //             cols[k] = false;
        //         }
        //     }
        //     this.selectedCols = cols;
        // },

        // onDuplicateCurrentPlan(newName) {
        //     console.log("newName", newName);
        //     this.loading = true;
        //     this.newPlanDialog = false;
        //     this.duplicateCurrentPlan(newName);
        //     this.newPlanMode = false;
        //     this.duplicatePlanMode = false;
        //     this.newPlanName = "";
        //     this.selectedPlanId = null;
        //     this.loading = false;
        // },

        async onDuplicateCurrentFcPlan(
            newPlanName,
            selectedPlanId,
            newPlanStartDate,
            newPlanYear,
            newPlanCategory,
            switchValue
        ) {
            console.log(
                'data1212',
                newPlanName,
                selectedPlanId,
                newPlanStartDate,
                newPlanYear,
                newPlanCategory,
                switchValue
            );

            this.loading = true;
            this.newPlanDialog = false;

            await this.duplicateCurrentFcPlan(
                newPlanName,
                selectedPlanId,
                newPlanStartDate,
                newPlanYear,
                newPlanCategory,
                switchValue
            );

            if (this.draftPlan.endDate) {
                this.planEndDate = this.draftPlan?.endDate;
            }

            if (this.draftPlan.startDate) {
                this.selectedStartDate = new Date(this.draftPlan?.startDate)
                    .toISOString()
                    .split('T')[0];
            }

            this.newPlanMode = false;
            this.duplicatePlanMode = false;
            this.newPlanName = '';
            this.selectedPlanId = this.draftPlan?.forwardPlanId;
            this.newPlanYear = '';
            this.newPlanCategory = '';
            this.switchValue = false;

            this.loading = false;

            return;
        },

        async loadExistingFcPlan(planID, newName) {
            this.loading = true;

            // console.log('newName222', this.loading, this.selectedPlanId, newName);

            if (planID) {
                this.selectedPlanId = planID;
            }

            const apiResponse = await this.LoadExistingFcPlan(this.selectedPlanId, newName);

            // console.log(apiResponse);

            // if (apiResponse?.startDate) {
            this.customerId = apiResponse?.customerId || null;
            this.segmentId = apiResponse?.segmentId || null;
            this.planEndDate = apiResponse?.endDate || null;
            this.selectedProductId = apiResponse?.productId || null;
            this.selectedStartDate = apiResponse?.startDate
                ? new Date(apiResponse?.startDate).toISOString().split('T')[0]
                : null;
            // }

            if (this.customerId && this.segmentId) {
                await this.fetchProducts(false);
            }

            if (this.selectedProductId) {
                // this.loading = true;
                // console.log('TRUE==', this.loading);
                this.handleSelectDate();
                // this.loading = false;
            }
            this.loading = false;
            // console.log('FALSE==', this.loading);
        },

        async loadExistingPlan(newName) {
            // console.log('newName222', this.loading, this.selectedPlanId, newName);
            this.loading = true;
            // console.log('electedPlanId33', this.selectedPlanId);
            await this.LoadExistingPlan(this.selectedPlanId, newName);
            this.initFilters();
            this.dirtyItems = [];
            //await this.GetAllProducts()
            this.loading = false;
        },

        duplicateProduct(item) {
            let newItem = JSON.parse(JSON.stringify(item));
            newItem.id = new Date().getTime();
            newItem.isNew = true;
            this.dirtyItems.push(newItem);
            this.unsavedChanges.push(newItem);
            this.draftPlan.products.unshift(newItem);
            this.snackbar = true;
        },

        addProductToPlan() {
            var newPlanProduct = {};

            planProductHeaders.forEach((h) => {
                newPlanProduct[h.value] = h.defaultValue != undefined ? h.defaultValue : '';
            });
            // newPlanProduct = JSON.parse(JSON.stringify( this.draftPlan.products.find(p=>p.productId == this.editedItem.productId)))
            let masterProduct = this.products.find((p) => p.id == this.editedItem.productId);
            (newPlanProduct.id = new Date().getTime()), //fake id
                (newPlanProduct.isNew = true),
                (newPlanProduct.name = masterProduct.name);
            (newPlanProduct.productId = this.editedItem.productId),
                (newPlanProduct.startDate = this.draftPlan.startDate.substr(0, 10)),
                (newPlanProduct.endDate = this.draftPlan.endDate.substr(0, 10)),
                (newPlanProduct.product = masterProduct.name),
                (newPlanProduct.productCode = masterProduct.code),
                (newPlanProduct.brand = this.referenceData.brands.find(
                    (b) => b.id == masterProduct.brandId
                )?.name),
                this.dirtyItems.push(newPlanProduct);
            this.unsavedChanges.push(newPlanProduct);
            this.draftPlan.products.unshift(newPlanProduct);
            /*
			  planProductHeaders.filter(h => h.formula).sort((a, b) => a.calLvl - b.calLvl).forEach(h => {
				  h.formula(newPlanProduct)
			  })*/
            this.initFilters();

            this.addProductDialog = false;
            this.clearAddProductFilters();
            this.snackbar = true;
        },

        getColDefinitions(groupTitle) {
            return this.productCols.filter((c) => c.groupTitle == groupTitle);
        },

        init() {
            planProductHeaders
                .filter((h) => h.isDefault)
                .forEach((h) => {
                    this.activeCols[h.value] = true;
                });
        },

        deleteItem(item) {
            this.editedIndex = this.draftPlan.products.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deletePlanConfirm() {
            this.dialogPlanDelete = false;
            this.loading = true;
            await this.DeletePlan(this.draftPlan.id);
            this.setSelectedItemObj('dashboard');
            this.loading = false;
        },

        deleteItemConfirm() {
            this.toBeDeletedPlanProducts.push(this.editedItem);
            this.draftPlan.products.splice(this.editedIndex, 1);
            let tempItem = this.dirtyItems.find((i) => i.id == this.editedItem.id);
            if (tempItem) {
                if (tempItem.isNew) {
                    this.dirtyItems.splice(this.dirtyItems.indexOf(tempItem));
                } else {
                    tempItem.isDelete = true;
                }
            } else {
                this.editedItem.isDelete = true;
                this.dirtyItems.push(this.editedItem);
                this.unsavedChanges.push(this.editedItem);
            }
            this.closeDelete();
        },

        close() {
            //this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        toggleAll(col) {
            this.activeFilters[col] = this.draftPlan.products
                .map((d) => {
                    return d[col];
                })
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
        },

        clearAll(col) {
            this.activeFilters[col] = [];
        },

        toggleDatePicker() {
            this.showDateDialog = !this.showDateDialog;
        },

        toggleMetricsDialog() {
            this.colsDialog = !this.colsDialog;
        },

        async handleSelectDate() {
            this.showDateDialog = false;

            if (!this.productsList) {
                await this.fetchProducts();
            }

            if (this.selectedProductId && this.customerId && this.segmentId) {
                console.log('TRUE==');
                this.loading = true;

                let payload = {
                    forwardPlanId: this.selectedPlanId,
                    startDate: this.selectedStartDate,
                    customerId: this.customerId,
                    segmentId: this.segmentId,
                    productId: this.selectedProductId,
                };

                // console.log('payload123', payload);

                await this.getFcTableDataHandler(payload);

                var detailsResponse = await this.getDetailsListHandler();

                this.inputsData = detailsResponse || DUMMY_INPUTS;

                this.forecast = this.fcTableData.forecast;
                this.weeksData = this.fcTableData.weeks;
                this.metricsData = this.fcTableData.productHeaders;
                // this.inputsData = this.fcTableData.details || DUMMY_INPUTS;

                // console.log('METRICS=', this.metricsData, this.weeksData);
                this.inputsData?.map((item) => {
                    this.selectedInputs = {
                        ...this.selectedInputs,
                        [item?.slug]: true,
                    };
                });

                this.activeInputsList = this.inputsData?.map((item) => item?.slug);

                this.metricsData?.map((item, index) => {
                    this.selectedCols = {
                        ...this.selectedCols,
                        [item?.value]: this.activeColsList?.includes(item?.value) ? true : false,
                    };
                });

                // console.log('thisForecast1', this.forecast, this.selectedCols);
                this.loading = false;
            }

            // if (alreadySelected) {
            //     // await this.getFcTableDataHandler(payload);
            //     await this.getFcSavedTableDataHandler(payload);
            // } else {
            // }
        },

        // async handleAlreadySelectedDate() {
        //     await this.handleSelectDate(true);

        //     this.dateAlreadySelected = false;
        // },

        // async handleRspCal(week, selectedValue, weekId, productId) {
        //     console.log(
        //         "weekcalBody2211",
        //         this.selectedWeekVal,
        //         this.weekcalBody,
        //         this.deSelectWeekMsg
        //     );

        //     if (this.weekcalBody !== null) {
        //         await this.$refs.confirmDia.open(
        //             "Do not Select more than One week at a time",
        //             `You have already selected week do you want to proceed calculation or discard change`,
        //             { aggreeTxt: "Confirm", cancelTxt: "Cancel" }
        //         );

        //         // this.deSelectWeekMsg =
        //         //     "You have already selected week. Please discard previous changes or calculate week";
        //     } else {
        //         const body = {
        //             forwardPlanId: this.selectedPlanId,
        //             startDate: this.selectedStartDate,
        //             targetWeekNumber: week?.weekNumber,
        //             // productId: productId,
        //             // rSp: selectedValue,
        //             productId: "dddab84b-00f6-4ad4-a995-30f9f6535a87",
        //             rSp: 62,
        //             finalVolPerWeek: 56,
        //             page: this.page,
        //             pageSize: this.pageSize,
        //         };
        //         this.weekcalBody = body;
        //     }

        //     console.log("weekcalBody", this.weekcalBody, this.deSelectWeekMsg);
        // },

        async handleWeekValueSelect(weekNumber, key, value) {
            // console.log('weekNumber1212', weekNumber, key, value, this.forecast);

            // when no weekNumber exists in state
            if (!this.weekcalBody?.targetWeekNumber) {
                const body = {
                    forwardPlanId: this.selectedPlanId,
                    startDate: this.selectedStartDate,
                    page: this.page,
                    pageSize: this.pageSize,
                    targetWeekNumber: weekNumber,
                    productId: this.selectedProductId,
                    spForcastPerWeek: 0,
                    multiBuyRedemption: 0,
                    comment: '',
                    display: false,
                    catalogue: false,
                    [key]: value,
                };
                this.weekcalBody = body;
            }
            // when user is changing values in the same week
            else if (this.weekcalBody?.targetWeekNumber === weekNumber) {
                const body = {
                    ...this.weekcalBody,
                    productId: this.selectedProductId,
                    [key]: value,
                };
                this.weekcalBody = body;
            } else if (this.weekcalBody?.targetWeekNumber !== weekNumber) {
                const body = {
                    ...this.weekcalBody,
                    productId: this.selectedProductId,
                    spForcastPerWeek: 0,
                    multiBuyRedemption: 0,
                    comment: 'string',
                    display: false,
                    targetWeekNumber: weekNumber,
                    catalogue: false,
                    [key]: value,
                };
                this.weekcalBody = body;
                // await this.$refs.confirmDia.open(
                //     'Do not Select more than One week at a time',
                //     `You have already selected week do you want to proceed calculation or discard change`,
                //     { aggreeTxt: 'Confirm', cancelTxt: 'Cancel' }
                // );
            }

            // console.log(this.weekcalBody);
        },

        async handleCalculateResults(key) {
            // check if the value exists in the body
            console.log(this.weekcalBody[key]);
            if (this.weekcalBody[key]) {
                this.loading = true;

                await this.getWeekCalDataHandler(this.weekcalBody);
                this.iscCalculatedWeek = true;
                // console.log('calculatingresults', this.weekcalBody);

                // call some api here
                this.forecast = this.fcTableData.forecast;

                // this.weekcalBody = null;
                // console.log('thisForecast2', this.forecast, this.weekcalBody);

                this.loading = false;
            }
        },

        async clashingTableData() {
            this.loading = true;
            await this.GetClashingTableData(this.weekcalBody);
            this.loading = false;
        },
        handleViewClick(fullView) {
            this.isFullView = fullView;
            // if (fullView) {
            //     this.selectedDetails = [];
            // } else {
            //     this.selectedDetails = this.promoGridDetails;
            // }
        },

        handleInputsApply() {
            const selected = Object.entries(this.selectedInputs)
                .filter((item) => {
                    if (item[1]) {
                        return item[0];
                    }
                })
                .map((item) => item[0]);

            if (selected?.length > 0) {
                this.showInputsError = false;
                this.activeInputsList = selected;
                this.showInputsLookupDialog = false;
            } else {
                this.showInputsError = true;
            }
        },

        handleSelectAllInputs() {
            const selected = Object.entries(this.selectedInputs)
                .filter((item) => {
                    if (item[1]) {
                        return item[0];
                    }
                })
                .map((item) => item[0]);

            if (selected.length === this.inputsData?.length) {
                this.inputsData?.map((item) => {
                    this.selectedInputs = {
                        ...this.selectedInputs,
                        [item?.slug]: false,
                    };
                });
            } else {
                this.inputsData?.map((item) => {
                    this.selectedInputs = {
                        ...this.selectedInputs,
                        [item?.slug]: true,
                    };
                });
            }

            // console.log(
            //     selected.length === this.inputsData?.length,
            //     this.selectedInputs
            //     // selected,
            //     // this.selectedInputs
            // );
        },

        async deleteSelectedPlan() {
            this.dialogPlanDelete = false;
            if (this.selectedPlanId) {
                this.loading = true;
                await this.deleteFcPlanHandler(this.selectedPlanId);
                this.selectedPlanId = null;
                this.tearDown();
                this.loading = false;
            }
        },

        async fetchProducts(showLoading = true) {
            if (showLoading) {
                this.loading = true;
            }

            const payload = {
                segmentId: this.segmentId,
                customerId: this.customerId,
                forwardPlanId: this.selectedPlanId,
            };

            // console.log('BEFORE=', payload, this.selectedProductId);

            const res = await this.getProductsFromCustomerAndSegmentHandler(payload);

            this.productsList = res?.products;
            // console.log('BEFORE=', payload, this.selectedProductId);

            if (showLoading) {
                this.loading = false;
            }
        },
    },

    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
        window.scenarioBuilder = this;
        this.init();

        this.$parent.$watch({
            SummaryVueId: function (val) {
                console.log('CALLED=', val);
            },
        });
    },

    beforeDestroy() {
        this.tearDown();
        window.scenarioBuilder = undefined;
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },

    mounted() {
        if (this.$refs?.row1?.clientHeight) {
            this.row1Height = this.$refs?.row1?.clientHeight;
        }

        this.getFcPlanList();
        this.getExistingFcPlanList();
        this.initFilters();
        this.getClasingPlansList();

        // console.log("forecast12", this.fcTableData.forecast);
    },

    activated() {
        // this.initFilters();
        const selectedID = this.$parent.$data.SummaryVueId;
        const createPlanData = this.$parent.$data.createPlanData || {};

        // console.log('ID=', selectedID);

        if (Object.values(createPlanData).length > 0) {
            this.resetPage();

            const {
                newPlanName,
                newPlanStartDate,
                selectedPlanId,
                newPlanYear,
                newPlanCategory,
                switchValue,
            } = createPlanData;

            this.onDuplicateCurrentFcPlan(
                newPlanName,
                selectedPlanId,
                newPlanStartDate,
                newPlanYear,
                newPlanCategory,
                switchValue
            );
        } else if (selectedID) {
            this.resetPage();
            // console.log('ID=', selectedID);
            this.loadExistingFcPlan(selectedID);
        }

        this.$parent.$data.SummaryVueId = null;
        this.$parent.$data.createPlanData = null;
    },

    watch: {
        // colsDialog: function (val) {
        //     if (val) {
        //         Object.assign(this.selectedCols, this.activeCols);
        //     }
        // },

        // [this.SummaryVueId]: function () {
        //     console.log("ID=", this.SummaryVueId);
        // },
        selectedStartDate: function (val) {
            // console.log(val);
        },
        showPanel: function (val) {
            if (val) {
                this.row2Height = 420;
            } else {
                this.row2Height = 48;
            }
        },
        customerId: function () {
            if (this.customerId && this.segmentId) {
                this.fetchProducts();
            }
            //  else if (
            //     this.selectedStartDate &&
            //     this.customerId &&
            //     this.segmentId &&
            //     this.selectedProductId
            // ) {
            //     this.handleSelectDate();
            // }
        },
        segmentId: function () {
            if (this.customerId && this.segmentId) {
                this.fetchProducts();
            }
            // else if (
            //     this.selectedStartDate &&
            //     this.customerId &&
            //     this.segmentId &&
            //     this.selectedProductId
            // ) {
            //     this.handleSelectDate();
            // }
            // if (this.customerId && this.segmentId && this.selectedProductId) {
            //     this.fetchProducts();
            // }
        },
        selectedProductId: function () {
            if (
                this.productsList &&
                this.selectedStartDate &&
                this.customerId &&
                this.segmentId &&
                this.selectedProductId
            ) {
                this.handleSelectDate();
            }
            // if (this.selectedStartDate) {
            //     this.handleSelectDate();
            // }
        },
    },
};
</script>

<style src="./forecast_main.css"></style>
./forecast_main.vue
