<template>
    <div>
        <div class="pa-2 font-weight-bold text-h5 blue--text text--darken-4">Product Dashboard
        </div>
        <v-card :elevation="12" class="mx-2">
            <v-card-title>
                <div class="blue--text text--darken-4">Products</div>
                <v-spacer></v-spacer>
                <v-btn class="white--text" color="primary" @click="dialog = true">
                    <v-icon left>mdi-plus</v-icon>New Product
                </v-btn>
                <!--<v-btn rounded small class="white--text ml-5" color="grey">Edit Cogs<v-icon small class="ml-2">
                        mdi-currency-usd</v-icon>
                </v-btn>-->
                <div class="ml-5">
                    <v-text-field dense v-model="search" outlined prepend-inner-icon="mdi-magnify" label="Search"
                        single-line hide-details>
                    </v-text-field>
                </div>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer id="virtual-scroll-table" :headers="headers"
                :items="productData" :search="search" sort-by="category" class="pb-10">
                <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
                    <div :key="i" style="display: inline-block; padding: 16px 0;">
                        <div style="display: inline-block; padding: 16px 0;">{{ header.text }}</div>
                        <div style="float: right; margin-top: 8px">
                            <v-menu :close-on-content-click="false" :nudge-width="200" offset-y
                                transition="slide-y-transition" right fixed style="position: absolute; right: 0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                                        <v-icon small
                                            :color="activeFilters[header.value] && activeFilters[header.value].length < filters[header.value].length ? 'red' : 'default'">
                                            mdi-filter-variant
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-card height="500" class="d-flex flex-column">
                                    <v-card-title>
                                        <v-row no-gutters style="position:relative">
                                            <v-col cols="6">
                                                <v-btn text block @click="toggleAll(header.value)" color="success">
                                                    Select
                                                    All
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn text block @click="clearAll(header.value)" color="warning">Clear
                                                    All
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="flex-grow-1 overflow-auto">
                                        <v-list flat  dense class="pa-0 ">

                                            <v-list-item-group multiple v-model="activeFilters[header.value]"
                                                class="py-2">
                                                <div style="background:white" v-for="item in filters[header.value]"
                                                    :key="`${item}`">
                                                    <v-list-item :value="item" :ripple="false">
                                                        <template v-slot:default="{ active }">
                                                            <v-list-item-action class="ma-0">
                                                                <v-checkbox :input-value="active" :true-value="item"
                                                                     color="primary" :ripple="false"
                                                                    dense>
                                                                </v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="item"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-list-item>
                                                </div>
                                            </v-list-item-group>
                                            <v-divider></v-divider>

                                        </v-list>
                                    </v-card-text>
                                </v-card>

                            </v-menu>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.bundleId`]="{ item }">
                    <div>
                        {{ referenceData.bundles.find(d => d.id == item.bundleId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.segmentId`]="{ item }">
                    <div>
                        {{ referenceData.segments.find(d => d.id == item.segmentId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.categoryId`]="{ item }">
                    <div>
                        {{ referenceData.categories.find(d => d.id == item.categoryId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.brandId`]="{ item }">
                    <div>
                        {{ referenceData.brands.find(d => d.id == item.brandId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.tierId`]="{ item }">
                    <div>
                        {{ referenceData.tiers.find(d => d.id == item.tierId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.measureId`]="{ item }">
                    <div>
                        {{ referenceData.measures.find(d => d.id == item.measureId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">

                    <v-icon class="mr-4" @click="editItem(item)">
                        mdi-square-edit-outline
                    </v-icon>


                    <v-icon color="#B42318" @click="deleteItem(item)">
                        mdi-trash-can-outline
                    </v-icon>
                </template>
                <template v-slot:[`item.editcogs`]="{ item }">
                    <v-simple-checkbox v-model="item.editcogs"></v-simple-checkbox>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title>Deleting product: {{ editedItem.name }} </v-card-title>
                <v-card-subtitle class="my-2">Are you sure you want to delete the product:
                    <strong>{{ editedItem.name }}</strong>?
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn width="150px" text outlined class="grey--text" @click="closeDelete">
                        Cancel
                    </v-btn>
                    <v-btn width="150px" color="#D92D20" class="white--text" @click="deleteItemConfirm">
                        Delete
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" persistent max-width="850px">
            <v-card class="pa-2">
                <v-card-title>
                    <span class="text-h5 black--text">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text class="pa-2">
                    <v-form ref="newForm" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-text-field :rules="[rules.required]" dense outlined
                                                v-model="editedItem.name" label="Name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field :rules="[rules.required]" dense outlined
                                                v-model="editedItem.code" label="Code">
                                            </v-text-field>
                                        </v-col>

                                        <v-col>
                                            <v-text-field :rules="[rules.required]" dense outlined
                                                v-model="editedItem.coGs" label="COST OF GOODS(COGs)"></v-text-field>
                                        </v-col>


                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]" v-model="editedItem.brandId"
                                                :items="referenceData.brands" item-text="name" item-value="id" outlined
                                                dense label="Brand">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]" v-model="editedItem.categoryId"
                                                :items="referenceData.categories" item-text="name" item-value="id"
                                                outlined dense label="Category">
                                            </v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>

                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]" v-model="editedItem.segmentId"
                                                :items="referenceData.segments" item-text="name" item-value="id"
                                                outlined dense label="Segment">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]"
                                                v-model="editedItem.nielsenSegmentId"
                                                :items="referenceData.nielsenSegments" item-text="name" item-value="id"
                                                outlined dense label="Nielsen Segment">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]" v-model="editedItem.bundleId"
                                                :items="referenceData.bundles" item-text="name" item-value="id" outlined
                                                dense label="Bundle">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]" v-model="editedItem.tierId"
                                                :items="referenceData.tiers" item-text="name" item-value="id" outlined
                                                dense label="Tier">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-autocomplete :rules="[rules.required]" v-model="editedItem.measureId"
                                                :items="referenceData.measures" item-text="name" item-value="id"
                                                outlined dense label="Measure">
                                            </v-autocomplete>

                                        </v-col>
                                        <v-col>
                                            <v-text-field :rules="[rules.required]" dense outlined
                                                v-model="editedItem.size" label="Size">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- <v-col cols="4">
                                
                                <v-row>
                                    <v-col>
                                        <div>
                                            <div class="mb-2">Image</div>
                                            <div style="border:1px solid;height: 200px;cursor:pointer;"
                                                @click:prevent="uploadImgDialog"
                                                class="rounded-lg d-flex justify-center">
                                                <v-icon>mdi-cloud-upload-outline</v-icon>
                                                <div class="d-flex align-center font-weight-bold px-2">UPLOAD IMAGE</div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>-->
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn width="150px" large text outlined @click="close">
                        Cancel
                    </v-btn>
                    <v-btn width="150px" large color="primary" class="white--text" @click="save">
                        Save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Processing
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '../../stores/user'
import { masterDataStore } from '../../stores/masterdata'

export default {
    name: 'ProductData',
    data: () => ({
        loading: false,
        search: '',
        uploadImgDialog: false,
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        editedIndex: -1,
        rules: {
            required: value => !!value || 'Required.',
            length6: value => value && value.length >= 6 || 'At least 6 characters',
        },
        filters: { 'name': [], 'code': [], 'brandId': [] },
        activeFilters: {}

    }),
    async mounted() {
        this.loading = true
        await this.GetAllProducts()
        this.loading = false
    },
    computed: {
        ...mapState(useUserStore, ['userProfile', 'users']),
        ...mapState(masterDataStore, ['referenceData', 'specialRefData', 'products']),
        formTitle() {
            return this.editedIndex === -1 ? 'New Product' : 'Edit Product'
        },
        productData() {
            return this.products
        },
        headers() {
            return [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    cellClass: "font-weight-bold",
                    value: 'name',
                    class: "primary--text",
                    filter: value => {
                        return this.activeFilters && this.activeFilters.name ? this.activeFilters.name.includes(value) : true;
                    }
                },
                { text: 'Category', value: 'categoryId', cellClass: 'categoryCell', class: "primary--text" },
                { text: 'Segment', value: 'segmentId', cellClass: 'segmentCell', class: "primary--text" },
                {
                    text: 'Brand', value: 'brandId', cellClass: 'brandCell', class: "primary--text",
                    filter: value => {
                        return this.activeFilters && this.activeFilters.brand ? this.activeFilters.brand.includes(value) : true;
                    }
                },
                {
                    text: 'Code', value: 'code', class: "primary--text",
                    filter: value => {
                        return this.activeFilters && this.activeFilters.code ? this.activeFilters.code.includes(value) : true;
                    }
                },
                { text: 'Bundle', value: 'bundleId', class: "primary--text" },
                { text: 'Tier', value: 'tierId', cellClass: 'tierCell', class: "primary--text" },
                { text: 'Measure', value: 'measureId', class: "primary--text" },
                { text: 'Size', value: 'size', class: "primary--text" },

                { text: 'COGs', value: 'coGs', class: "primary--text" },
                { text: 'Actions', value: 'actions', sortable: false, class: "primary--text" }

            ]
        }
    },
    methods: {
        ...mapActions(useUserStore, ['LoadAllUsers']),
        ...mapActions(masterDataStore, ['CreateProduct', 'GetAllProducts', 'UpdateProduct', 'DeleteProduct']),
        editItem(item) {
            this.editedIndex = this.productData.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.products.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            this.loading = true
            await this.DeleteProduct(this.editedItem)
            await this.GetAllProducts()
            this.closeDelete()
            this.loading = false
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            this.$refs.newForm.resetValidation()
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        async save() {
            if (!this.$refs.newForm.validate()) {
                return
            }
            if (this.editedIndex > -1) {
                //edit
                this.loading = true
                Object.assign(this.productData[this.editedIndex], this.editedItem)
                console.log(this.editedItem)
                await this.UpdateProduct(this.editedItem)
                await this.GetAllProducts()
                this.loading = false
            } else {
                //new
                this.loading = true
                const res = await this.CreateProduct(this.editedItem)
                if (res.status == 200) {
                    this.errorReulst = false
                    this.userMsg = 'User Created'
                    this.snackbar = true
                } else {
                    this.errorReulst = true
                    this.userMsg = 'Something went wrong'
                    this.snackbar = true
                }

                this.loading = false
            }
            this.close()
        },
        initFilters() {
            for (var col in this.filters) {
                var newCol = col
                var ref = newCol + 's'
                if (col.endsWith('Id')) {
                    newCol = col.slice(0, -2)
                }
                this.filters[col] = this.productData.map((d) => { return d[col] }).filter(
                    (value, index, self) => { return self.indexOf(value) === index }
                )
            }
            // TODO restore previous activeFilters before add/remove item
            this.activeFilters = Object.assign({}, this.filters)
            /*if (Object.keys(this.activeFilters).length === 0) this.activeFilters = Object.assign({}, this.filters)
            else {
              setTimeout(() => {
                console.log(this.activeFilters)
                //this.activeFilters = Object.assign({}, this.filters)
              }, 1)
            }*/
        },
        toggleAll(col) {
            this.activeFilters[col] = this.productData.map((d) => { return d[col] }).filter(
                (value, index, self) => { return self.indexOf(value) === index }
            )
        },

        clearAll(col) {
            this.activeFilters[col] = []
        },

    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
        productData(val) {
            this.initFilters()
            //this.activeFilters = {} // TODO change this
            //this.activeFilters = Object.assign({}, this.filters)
        }
    },


}
</script>

<style>
#virtual-scroll-table {
    max-height: calc(100vh - 150px);
    overflow: auto;
}
</style>
