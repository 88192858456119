// export const calculateStatusOfProducts = (products) => {
//     const calc_status = (values) => {
//         var counts = {};
//         // calculate counts of all promo mechanics
//         values?.map((item) => {
//             if (item) {
//                 counts = {
//                     ...counts,
//                     [item]: (counts[item] || 0) + 1,
//                 };
//             }
//         });

//         // convert to array and sort the same
//         const count_values = Object.entries(counts)?.sort((a, b) => a[1] - b[1]);

//         // depending on the count, return the status labels
//         return count_values[count_values?.length - 1]?.at(1) > 1
//             ? `${count_values[count_values?.length - 1]?.at(0)} CLASH`
//             : count_values?.length > 2
//             ? 'CLASH'
//             : count_values?.length > 1
//             ? 'WATCH'
//             : '';
//     };

//     products = products?.map((item) => {
//         var status = {};
//         item?.clashing?.map((clash_item) => {
//             status = {
//                 ...status,
//                 [parseInt(clash_item?.weekNumber)]: calc_status(clash_item?.values),
//             };
//         });

//         return {
//             ...item,
//             status,
//         };
//     });

//     return products;
// };

const KEYS = {
    hp: {
        value: 'Half Price',
        label: 'HP',
    },
    mb: {
        value: 'Multibuy',
        label: 'MB',
    },
    edlp: {
        value: 'EDLP',
        label: 'EDLP',
    },
    deep: {
        value: 'Deep',
        label: 'Deep',
    },
    shallow: {
        value: 'Shallow',
        label: 'Shallow',
    },
};

const edlpMbCheck = (counts, key1, key2) => {
    if (counts[KEYS[key1]?.value] > 0 || counts[KEYS[key2]?.value] > 0) {
        if (
            (counts[KEYS[key1]?.value] || 0) < (counts[KEYS[key2]?.value] || 0) &&
            (counts[KEYS[key1]?.value] || 0) !== 1
        ) {
            return `${KEYS[key2]?.label} CLASH`;
        }
        if (
            (counts[KEYS[key2]?.value] || 0) < (counts[KEYS[key1]?.value] || 0) &&
            (counts[KEYS[key1]?.value] || 0) !== 1
        ) {
            return `${KEYS[key1]?.label} CLASH`;
        }
        return 'CLASH';
    }

    if (counts[KEYS?.mb?.value] > 0) {
        return 'CLASH';
    }
    if (counts[KEYS?.edlp?.value] > 0) {
        return 'WATCH';
    }
    return '';
};

const calc_status = (values) => {
    var counts = {};

    // calculate counts of all promo mechanics
    values?.map((item) => {
        if (item) {
            counts = {
                ...counts,
                [item]: (counts[item] || 0) + 1,
            };
        }
    });

    // console.log('WEEK=', counts);

    // check all depths first
    // ['hp', 'deep', 'shallow']
    if (counts[KEYS?.hp?.value] > 0) {
        if (counts[KEYS?.hp?.value] > 1) {
            return `${KEYS?.hp?.label} CLASH`;
        } else {
            return edlpMbCheck(counts, 'deep', 'shallow');
        }
    }

    if (counts[KEYS?.deep?.value] > 0) {
        if (counts[KEYS?.deep?.value] > 1) {
            return `${KEYS?.deep?.label} CLASH`;
        } else {
            return edlpMbCheck(counts, 'hp', 'shallow');
        }
    }

    if (counts[KEYS?.shallow?.value] > 0) {
        if (counts[KEYS?.shallow?.value] > 1) {
            return `${KEYS?.shallow?.label} CLASH`;
        } else {
            return edlpMbCheck(counts, 'hp', 'deep');
        }
    }

    // check counts of Multibuy then EDLP
    if (counts[KEYS?.mb?.value] > 1) {
        return 'CLASH';
    }

    if (counts[KEYS?.edlp?.value] > 1) {
        return 'WATCH';
    }

    return '';
};

export const calculateStatusOfProducts = (products) => {
    products = products?.map((item) => {
        var status = {};
        item?.clashing?.map((clash_item) => {
            status = {
                ...status,
                [parseInt(clash_item?.weekNumber)]: calc_status(clash_item?.values),
            };
            console.log('STATUS = ', calc_status(clash_item?.values));
        });

        return {
            ...item,
            status,
        };
    });

    return products;
};
