<template>
    <v-card class="mx-auto fill-height" width="100%" outlined>
        <v-card-text class="fill-height pa-0 ma-0">
            <v-row no-gutters class=" fill-height ">
                <v-col align-self="center" cols="5" class="px-10" >
                    <div style="width:360px;margin:auto">
                        <div class="d-flex px-10 mb-2 ">

                            <div class="text-h4 font-weight-bold primary--text d-flex">
                            <v-img src="@/assets/revedy.svg"  height="50" contain></v-img>
                            <span>REVEDY</span>
                               <!--<span style="color:#1A4A9F;">RGM</span><span style="color:#4072AF">plus</span>-->
                            </div>
                        </div>
                        <div class="px-10 primary--text font-weight-bold">
                            Your pricing strategy made easy, with smarter revenue growth management tools
                        </div>

                        <v-form class="d-flex flex-column pa-10" ref="form" @submit.prevent="onSubmit"
                            id="check-login-form">
                            <p v-if="showForgotPwd" class="font-weight-black text-center">
                                Forgot Password
                            </p>
                            <v-text-field :disabled="loading" v-if="!showForgotResult" outlined dense
                                prepend-icon="mdi-email" name="userName" v-model="userName" :rules=" userNameRules"
                                label="Email" type="text"></v-text-field>
                            <v-text-field :disabled="loading" v-if="!showForgotPwd" outlined dense id="mdi-password"
                                prepend-icon="mdi-lock" v-model="password" :rules="passwordRules" name="password"
                                label="Password" :type="showPwd ? 'text' : 'password'"
                                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPwd = !showPwd"
                                :error-messages="errorMsg" @focus="errorMsg = ''">
                            </v-text-field>

                            <div class="ma-2" v-if="showForgotResult">
                                <v-alert outlined text dense :type="forgotFailed? 'error':'success'">{{this.forgotMsg}}
                                </v-alert>
                            </div>


                            <v-btn :loading="loading"  v-if="!showForgotResult" class="ml-8" color="primary"
                                type="submit" form="check-login-form">{{showForgotPwd? 'Submit':'Sign In'}}</v-btn>
                            <v-btn rounded v-if="showForgotResult " class="ml-10" @click="backToHome" color="primary">
                                Back to Home</v-btn>

                            <div class="pl-10 mt-2 text-center primary--text">
                                {{showForgotPwd? 'Remembered your password?':'Forgotten Password?'}}<strong
                                    style=" cursor: pointer;" @click="showForgotPwd =! showForgotPwd; userName = ''"
                                    class="text-decoration-underline"> {{showForgotPwd? "Let's SignIn!":'We can help!'}}</strong>
                            </div>
                        </v-form>

                    </div>

                </v-col>
                <v-col cols="7" class=" fill-height left-login" align-self="center">
                    <div class="left-text my-15 mx-5">Your new (smarter) best friend for <br /> building price & promo
                        plans & strategies <br />to maximize your profit every year.</div>
                    <div class="d-flex justify-center">
                        <v-img max-width="70%" src="@/assets/loginpic1.png"></v-img>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>



</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '../stores/user'
export default {
    name: 'LoginForm',
    props: {
        source: String,
    },
    data() {
        return {
            showPwd: false,
            errorMsg: '',
            loading: false,
            userName: '',
            password: '',
            userNameRules: [
                v => !!v || 'Email is required',
                v => (v && v.length <= 40) || 'Email must be less than 40 characters',
                // eslint-disable-next-line 
                v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Invalid Email'
            ],
            passwordRules: [
                v => !!v || 'Password is required'

            ],
            passwordFailedRules: [
                () => (`The email and password you entered don't match`)
            ],
            showForgotPwd: false,
            forgotMsg: '',
            showForgotResult: false
        }
    },
    mounted() {
        this.setTenantId()
      
    },
    methods: {
        ...mapActions(useUserStore, ['Login', 'ForgotPassword', 'setTenantId']),
        validate() {

            return this.$refs.form.validate()
        },
        backToHome() {
            this.showForgotResult = false
            this.forgotMsg = ''
            this.showForgotPwd = false

        },
        async onSubmit() {
            if (this.validate()) {
                this.loading = true
                // send forgot pwd email
                if (this.showForgotPwd) {
                    const res = await this.ForgotPassword(this.userName)
                    if (res.status == 200) {
                        this.forgotMsg = res.data
                        this.showForgotResult = true
                        this.forgotFailed = false
                    } else {
                        this.forgotMsg = res.data.exception
                        this.showForgotResult = true
                        this.forgotFailed = true
                    }
                }
                else {
                    const res = await this.Login(this.userName, this.password)
                    if (res.status == 200) {
                        this.loginFailed = false
                        this.errorMsg = ''
                        this.$router.push('/')
                    }
                    else {
                        this.errorMsg = res.data.exception || `The email and password you entered don't match`
                        console.log('failed')
                    }
                }
                this.loading = false


            }
        }
    },
    computed: {
        ...mapState(useUserStore, ['tenant'])
    }
};
</script>

<style>
.left-login {
    background: linear-gradient(45deg, #10234C 0%, #285CB7 100%);
}

.left-text {
    font-size: 1.5rem;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
    color: white;
}
</style>
