<template>
  <div>
    <v-row ref="row1" class="d-flex px-4 py-2 mt-1">
      <div class="text-h5 font-weight-bold primary--text">
        Customer Price Request
      </div>
      <v-spacer></v-spacer>
      <v-btn elevation="0" class="mr-2" color="white" @click="onSwitch">
        <v-img class="mr-1" :src="require('@/assets/coin-swap.svg')" contain>
        </v-img
        >Switch to Contract Customer Pricing
      </v-btn>
      <v-btn elevation="0" class="white--text mr-2" color="primary">
        <v-img class="mr-1" :src="require('@/assets/send.svg')" contain> </v-img
        >Submit
      </v-btn>
      <v-btn elevation="0" class="primary--text" color="#E4EDFF">
        <v-icon color="primary" left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
    <v-row class="px-4 py-2">
      <v-card class="overflow-y-auto" width="100%">
        <v-card-title>
          <div class="text-h6 font-weight-semibold primary--text">
            Structured Pricing Customer
          </div>
          <v-spacer></v-spacer>
          <v-btn class="white--text" color="primary">
            <v-icon left>mdi-plus</v-icon>Add Customer
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row v-if="firstRowInputs">
            <v-col
              v-for="(input, index) in firstRowInputs.concat(secondRowInputs)"
              cols="12"
              md="2"
              class="pa-2"
              :key="`cp-input-row1-${index}`"
            >
              <div>
                {{ input.text }}
              </div>
              <v-text-field
                v-if="input.type === 'text'"
                :placeholder="input.placeHolder"
                persistent-placeholder
                outlined
                dense
                single-line
                :label="input.text"
                :type="input.type"
                :items="input.items"
                :background-color="input.bgColor || ''"
                :value="input.value"
                :readonly="input.readOnly"
              ></v-text-field>
              <v-select
                v-if="input.type === 'select'"
                :placeholder="input.placeHolder"
                persistent-placeholder
                :background-color="input.bgColor || ''"
                outlined
                dense
                single-line
                :label="input.text"
                :type="input.type"
                :items="input.items"
              ></v-select>
              <v-autocomplete
                v-if="input.type === 'autoComplete'"
                :placeholder="input.placeHolder"
                persistent-placeholder
                :background-color="input.bgColor || ''"
                outlined
                dense
                single-line
                :label="input.text"
                :type="input.type"
                :items="input.items"
                @change="input.onChange"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <CustomerPricingProductsTableVue :classification="classification" :customerName="customerName" :customerZone="zoneName" />
  </div>
</template>

<script>
import { variables } from "@/common/constants";
import { masterDataStore } from "../../stores/masterdata";
import { mapActions, mapState } from "pinia";
import { formatNumber } from "../../common/methods";
import { customers, zones} from '@/common/mockdata/structuredpricing'
import CustomerPricingProductsTableVue from "./CustomerPricingProductsTable.vue";
export default {
  name: "StructuredPricing",
  components:{
    CustomerPricingProductsTableVue
  },  
  data: () => {
    return {
      customerName: "",
      zoneName: "",
      classification: "",
      headers: [
        {
          text: "Products",
          value: "productName",
          class: "primary--text",
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Min",
          value: "min",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Max",
          value: "max",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Recommendation",
          value: "recommendation",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Alternative Request",
          value: "alternativeRequestPrice",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Alternative Request",
          value: "alternativeRequestPercent",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Direction",
          value: "withinlimit",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
        },
        {
          text: "Var.$",
          value: "variance",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
          align: "center",
        },
        {
          text: "Var.%",
          value: "variancePercent",
          class: "primary--text",
          width: 140,
          cellClass: "sp-tbcell",
          sortable: false,
          align: "center",
        },
      ],
      products: [
        {
          productName: "Product 1",
          min: 100,
          max: 200,
          recommendation: 150,
          alternativeRequestPrice: 200,
          alternativeRequestPercent: 10,
          withinlimit: true,
          variance: 50,
          variancePercent: 33,
          segment: "Segment 1",
        },
        {
          productName: "Product 2",
          min: 100,
          max: 200,
          recommendation: 150,
          alternativeRequestPrice: 200,
          alternativeRequestPercent: 10,
          withinlimit: false,
          variance: 50,
          variancePercent: 33,
          segment: "Segment 1",
        },
        {
          productName: "Product 3",
          min: 100,
          max: 200,
          recommendation: 150,
          alternativeRequestPrice: 200,
          alternativeRequestPercent: 10,
          withinlimit: true,
          variance: 50,
          variancePercent: 33,
          segment: "Segment 2",
        },
        {
          productName: "Product 4",
          min: 100,
          max: 200,
          recommendation: 150,
          alternativeRequestPrice: 200,
          alternativeRequestPercent: 10,
          withinlimit: true,
          variance: 50,
          variancePercent: 33,
          segment: "Segment 3",
        },
      ],
    };
  },
  async mounted() {
    await this.GetAllCustomers();
  },
  methods: {
    ...mapActions(masterDataStore, [
      "LoadRefData",
      "GetAllCustomers",
      "GetAllPlanTags",
      "GetAllPromoTypes",
      "GetAllElasticities",
    ]),
    onSwitch() {
      //this.$emit("switch", "Customer Contract Pricing");
      this.$emit("onSwitchMenu", "contractpricing");
    },
  },
  computed: {
    ...mapState(masterDataStore, ["referenceData", "specialRefData"]),
    footerProps() {
      return {
        "items-per-page-options": [15, 30, 50, -1],
        showFirstLastPage: true,
      };
    },
    firstRowInputs() {
      return [
        {
          text: "Customer Code",
          placeHolder: "customercode",
          type: "autoComplete",
          bgColor: variables.colors.primary50,
          items: customers.map((customer) => {
            return {
              text: customer.code,
              value: customer.name,
            };
          }),
          onChange: (e) => {
            this.customerName = e;
          },
        },
        {
          text: "Location",
          placeHolder: "Postcode",
          type: "autoComplete",
          bgColor: variables.colors.primary50,
          items: zones.map((zone) => {
            return {
              text: zone.postCode,
              value: zone.name,
            };
          }),
          onChange: (e) => {
            this.zoneName = e;
          },
        },
        {
          text: "Size Of Customer",
          placeHolder: "...in Litres",
          type: "autoComplete",
          bgColor: variables.colors.primary50,
          items: [
            {
              text: "0L",
              value: "A",
            },
            {
              text: "1000L",
              value: "B",
            },
            {
              text: "10000L",
              value: "C",
            },
          ],
          onChange: (e) => {
            this.classification = e;
          },
        },

        {
          text: "Growth Prospects",
          type: "select",
          placeHolder: "Medium",
          bgColor: variables.colors.primary50,
          items: [
            {
              text: "High",
              value: "high",
            },
            {
              text: "Medium",
              value: "medium",
            },
            {
              text: "Low",
              value: "low",
            },
          ],
        },
        {
          text: "Customer Relationship",
          type: "select",
          bgColor: variables.colors.primary50,
          placeHolder: "Select a Customer",
          items: [
            {
              text: "High",
              value: "high",
            },
            {
              text: "Medium",
              value: "medium",
            },
            {
              text: "Low",
              value: "low",
            },
          ],
        },
        {
          text: "Delivery & Order Sched.",
          bgColor: variables.colors.primary50,
          type: "select",
          placeHolder: "Select a Customer",
          items: [
            {
              text: "Daily",
              value: "daily",
            },
            {
              text: "Weekly",
              value: "weekly",
            },
            {
              text: "Fortnightly",
              value: "fortnightly",
            },
          ],
        },
        /*
        {
          text: "Approval",
          type: "select",
          bgColor: variables.colors.primary50,
          placeHolder: "Select a Customer",
          items: this.specialRefData?.customers?.map((customer) => {
            return {
              text: customer.name,
              value: customer.id,
            };
          }),
        },*/
      ];
    },
    secondRowInputs() {
      return [
        {
          text: "Customer Name",
          placeHolder: "customercode",
          type: "text",
          value: this.customerName,
          readOnly: true,
        },
        {
          text: "Customer Zone",
          type: "text",
          placeHolder: "Customer Zone",
          value: this.zoneName,
          readOnly: true,
        },
        {
          text: "Customer Classification",
          type: "text",
          placeHolder: "Customer Classification",
          value: this.classification,
          readOnly: true,
        },
        {
          text: "Strategic Importance",
          type: "select",
          placeHolder: "Low",
          bgColor: variables.colors.primary50,
          items: [
            {
              text: "High",
              value: "high",
            },
            {
              text: "Medium",
              value: "medium",
            },
            {
              text: "Low",
              value: "low",
            },
          ],
        } /*
        {
          text: "Core Product Ranging",
          type: "select",
          bgColor: variables.colors.primary50,
          placeHolder: "80%",
          items: [
            {
              text: "80%",
              value: "80",
            },
            {
              text: "70%",
              value: "70",
            },
            {
              text: "60%",
              value: "60",
            },
          ],
        },

        {
          text: "Additional Ranging",
          type: "select",
          placeHolder: "Select a plan",
          items: [],
        },*/,
      ];
    },
  },
};
</script>
<style>
.products-table input {
  cursor: cell;
}
.sp-tbcell {
  padding: 10px 13px 10px 13px !important;
}

.price-input .v-text-field__slot input {
  color: #285cb7 !important;
}
</style>