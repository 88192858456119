<template>
  <div>
    <div class="pa-2 font-weight-bold text-h5 blue--text text--darken-4">Client Management
    </div>

    <v-data-table :headers="headers" dense :items="tenants" sort-by="name" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Client
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="newForm" lazy-validation>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field :readonly="editedIndex != -1" :disabled="editedIndex != -1" dense outlined
                          v-model="editedItem.name" label="Name" :rules="[rules.required]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field :readonly="editedIndex != -1" :disabled="editedIndex != -1" dense outlined
                          v-model="editedItem.id" label="Id" :rules="[rules.required]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field dense outlined type="number" v-model="editedItem.licenseUserNumber"
                          label="License User No." :rules="[rules.required, rules.license]"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-menu v-model="validUptoMenu" :close-on-content-click="false" :nudge-right="140"
                          transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field @click="validUptoMenu = true" dense outlined :value="formatTime(editedItem.validUpto)" label="Valid To Date"
                              append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker :allowed-dates="allowedDates" v-model="editedItem.validUpto"
                            @input="validUptoMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field :readonly="editedIndex != -1" :disabled="editedIndex != -1" dense outlined
                          v-model="editedItem.adminEmail" label="Administrator's Email"
                          :rules="[rules.required, rules.email]"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field :readonly="editedIndex != -1" :disabled="editedIndex != -1" dense outlined
                          v-model="editedItem.connectionString" label="Database Connection String"
                          :rules="[rules.required]">
                        </v-text-field>
                      </v-col>


                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogToggleStatus" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{ editItem.isActive ? 'Deactivate' : 'Activate' }} Client

              </v-card-title>
              <v-card-subtitle class=" my-4">Are you sure you want to <strong>{{ editItem.isActive ?
                  'Deactivate' : 'Activate'
              }}</strong> this client - {{ editedItem.name }} ?</v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="toggleItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <v-chip small :color="item.isActive ? 'green' : 'red'" text-color="white"> {{ item.isActive ? 'Yes' : 'No' }}
        </v-chip>
      </template>
      <template v-slot:[`item.validUpto`]="{ item }">
        {{ formatTime(item.validUpto) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">
          mdi-square-edit-outline
        </v-icon>
        <v-icon color="red" v-if="item.isActive" @click="toggleStatus(item)">
          mdi-account-cancel-outline
        </v-icon>
        <v-icon color="green" v-if="!item.isActive" @click="toggleStatus(item)">
          mdi-account-check-outline
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Processing
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
const dayjs = require('dayjs')

export default {
  name: 'ClientList',
  data: () => ({
    dialog: false,
    validUptoMenu: false,
    dialogToggleStatus: false,
    loading: false,
    rules: {
      required: value => !!value || 'Required.',
      length6: value => value && value.length >= 6 || 'At least 6 characters',
      // eslint-disable-next-line 
      email: v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Invalid Email',
      license: val => !isNaN(val) && val > 0 || "has to greater than 0"

    },
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
        class: "primary--text"
      },
      { text: 'ID', value: 'id', class: "primary--text" },
      { text: 'Admin Email', value: 'adminEmail', class: "primary--text" },
      { text: 'Connection String', value: 'connectionString', width: 400, class: "primary--text" },
      { text: 'Active', value: 'isActive', class: "primary--text" },
      { text: 'License User No.', value: 'licenseUserNumber', class: "primary--text" },
      { text: 'Valid To', value: 'validUpto', class: "primary--text" },
      { text: 'Actions', value: 'actions', sortable: false, class: "primary--text" },
    ],
    editedIndex: -1,
    editedItem: {
      "id": "",
      "name": "",
      "connectionString": "",
      "adminEmail": "",
      licenseUserNumber: 0,
      validUpto:  dayjs(new Date()).format('YYYY-MM-DD')
    },
    defaultItem: {
      "id": "",
      "name": "",
      "connectionString": "",
      "adminEmail": "",
      licenseUserNumber: 0,
      validUpto:  dayjs(new Date()).format('YYYY-MM-DD')
    },
  }),

  computed: {
    ...mapState(useUserStore, ['tenants']),
    formTitle() {
      return this.editedIndex == -1 ? 'New Client' : 'Edit Client'
    },

    computedDateFormatted(){
      if(!this.editedItem.validUpto){
        return null
      }
      else{
        return dayjs(this.editedItem.validUpto).format('DD/MM/YYYY')
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogToggleStatus(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },
  mounted() {
    this.LoadAllTenants()
  },

  methods: {
    ...mapActions(useUserStore, ['LoadAllTenants', 'CreateTenant', 'ToggleTenantStatus', 'UpdateTenant']),
    allowedDates(val) {
      return dayjs(val).isAfter(dayjs(new Date())) 
    },
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD')
    },
    initialize() {
      this.LoadAllTenants()
    },

    editItem(item) {
      this.editedIndex = this.tenants.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.validUpto = dayjs(this.editedItem.validUpto).format('YYYY-MM-DD')
      this.dialog = true
    },

    toggleStatus(item) {
      this.editedIndex = this.tenants.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogToggleStatus = true
    },

    async toggleItemConfirm() {
      this.loading = true
      await this.ToggleTenantStatus({
        tenantId: this.editedItem.id
      }, this.editedItem.isActive ? false : true)
      this.LoadAllTenants()
      this.loading = false
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.newForm.resetValidation()
    },

    closeDelete() {
      this.dialogToggleStatus = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      if (!this.$refs.newForm.validate()) {
        return
      }
      if (this.editedIndex > -1) { // edit
        Object.assign(this.tenants[this.editedIndex], this.editedItem)
        this.loading = true
        this.editedItem.tenantId = this.editedItem.id
        this.editedItem.extendedExpiryDate = this.editedItem.validUpto
        await this.UpdateTenant(this.editedItem)
        this.loading = false

      } else {
        this.dialog = false
        this.loading = true
        await this.CreateTenant({ ...this.editedItem })
        this.LoadAllTenants()
        this.loading = false
      }
      this.close()
    },
  },
}
</script>
