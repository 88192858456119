export default [
    {
        id: '6ff800f4-3e89-43dc-9de5-036e573d79d8',
        details: 'Final volume per week (units)',
        slug: 'final_volume_per_week_units',
        type: 'User input',
    },
    {
        id: 'b3e272de-8818-42a1-a5ee-04ca02047d1f',
        details: 'Investment 2',
        slug: 'investment_two',
        type: 'SP Backend Lookup',
    },
    {
        id: 'c0c3193b-5bd0-4125-892e-0795ec4bac66',
        details: 'Terms dollars',
        slug: 'terms_dollars',
        type: 'SP Backend Lookup',
    },
    {
        id: '26ccc5e4-3086-45c9-9e6a-0b38ac24210d',
        details: 'Promo deal',
        slug: 'promo_deal',
        type: 'SP Backend Lookup',
    },
    {
        id: '2e8a10aa-7728-47cd-b053-12e8107637c8',
        details: 'COGS actuals',
        slug: 'cogs_actuals',
        type: 'Calculation',
    },
    {
        id: '0619958b-0c6d-4242-a596-141da1519259',
        details: 'Net revenue planned',
        slug: 'net_revenue_planned',
        type: 'Calculation',
    },
    {
        id: 'ff13831e-18c1-414f-a7cc-14eeee148e08',
        details: 'Multibuy redemption percentage',
        slug: 'multibuy_redemption_percentage',
        type: 'User input',
    },
    {
        id: 'ac180f6a-38ac-4350-a087-16f65ac18ff5',
        details: 'Promo Type',
        slug: 'promo_type',
        type: 'SP Backend Lookup',
    },
    {
        id: '5b55cfed-34a5-4db6-a683-193780fc3dfd',
        details: 'GSV planned',
        slug: 'gsv_planned',
        type: 'Calculation',
    },
    {
        id: '8cfa9c52-03b7-4e33-9b67-1b5eb88a9b08',
        details: 'Base units',
        slug: 'base_units',
        type: 'SP Backend Lookup',
    },
    {
        id: 'daec1ef0-51d7-4474-9228-2167c6c2ac74',
        details: 'Trade investment actuals',
        slug: 'trade_investment_actuals',
        type: 'Calculation',
    },
    {
        id: '07bea305-62b0-4eac-96da-b971983764c7',
        details: 'ROI NR',
        slug: 'roi_nr',
        type: 'Calculation',
    },
    {
        id: '62d15a02-49e6-4cf9-addb-2185ba58525a',
        details: 'ROI GM',
        slug: 'roi_gm',
        type: 'Calculation',
    },
    {
        id: 'c74c9775-ace5-4a2e-a2e8-2275eac39e25',
        details: 'SP Forecast volume per week (units)',
        slug: 'sp_forecast_volume_per_week_units',
        type: 'SP Backend Lookup',
    },
    {
        id: '932d1f5e-48f4-44a0-9bb8-26b46b4956c4',
        details: 'Catalogue',
        slug: 'catalogue',
        type: 'User input',
    },
    {
        id: '761c241e-beee-4f28-849c-3a4567d9c947',
        details: 'Promo discount',
        slug: 'promo_discount',
        type: 'SP Backend Lookup',
    },
    {
        id: '9630c36a-bb1b-43e2-b1f4-3bdc2632a77d',
        details: 'Percentage Promo funding planned',
        slug: 'percentage_promo_funding_planned',
        type: 'Calculation',
    },
    {
        id: 'a8b02cfb-6cb3-44bd-852a-3f397b995470',
        details: 'Retailer margin planned',
        slug: 'retailer_margin_planned',
        type: 'Calculation',
    },
    {
        id: 'fcd17208-8bbe-4ff7-985c-441ded5d1d66',
        details: 'Increamental NR',
        slug: 'increamental_nr',
        type: 'Calculation',
    },
    {
        id: '7169629e-17d8-4671-998e-47afabfc3dcd',
        details: 'COGS per unit',
        slug: 'cogs_per_unit',
        type: 'SP Backend Lookup',
    },
    {
        id: 'f94d11b3-87cc-4eaa-bc60-4f8e86336601',
        details: 'Off invoice discount',
        slug: 'off_invoice_discount',
        type: 'SP Backend Lookup',
    },
    {
        id: '3b5c85d2-d782-4a46-baee-4f9f2802733a',
        details: 'Deferred deal',
        slug: 'deferred_deal',
        type: 'SP Backend Lookup',
    },
    {
        id: 'ced21dc8-8906-4695-ae74-559c34bc785f',
        details: 'COGS per vol',
        slug: 'cogs_per_vol',
        type: 'Calculation',
    },
    {
        id: 'd2ce2be0-36e6-473d-a1b8-57e700b9749b',
        details: 'GSV actuals',
        slug: 'gsv_actuals',
        type: 'Calculation',
    },
    {
        id: '24c19287-9b85-4c4d-9756-5807a9ff8a59',
        details: 'Net revenue actuals',
        slug: 'net_revenue_actuals',
        type: 'Calculation',
    },
    {
        id: 'a31a4361-e186-4b45-a31c-594eda05f33c',
        details: 'TI per unit',
        slug: 'ti_per_unit',
        type: 'SP Backend Lookup',
    },
    {
        id: '7dca75b3-11c9-40ff-b1c3-60f4c89a6081',
        details: 'GM per unit',
        slug: 'gm_per_unit',
        type: 'SP Backend Lookup',
    },
    {
        id: '40e525ee-f19e-4fc3-b3b2-65fb4f251c04',
        details: 'Trade investment planned',
        slug: 'trade_investment_planned',
        type: 'Calculation',
    },
    {
        id: '702fe74f-af91-4e04-9a58-6b3774ce68d1',
        details: 'GM% planned',
        slug: 'gm_percentage_planned',
        type: 'Calculation',
    },
    {
        id: '1588644e-40d0-4144-b815-6f60c8a2f690',
        details: 'Net revenue per vol',
        slug: 'net_revenue_per_vol',
        type: 'Calculation',
    },
    {
        id: 'aad20ad1-61fc-4268-a223-6fbc7cae1ca9',
        details: 'Gross margin planned',
        slug: 'gross_margin_planned',
        type: 'Calculation',
    },
    {
        id: '96b5ce2c-2757-4e6f-b8b9-725a761444a1',
        details: 'TI% planned',
        slug: 'ti_percentage_planned',
        type: 'Calculation',
    },
    {
        id: 'c01fbfd6-c3cf-428f-a160-74252096e7d4',
        details: 'Increamental TI',
        slug: 'increamental_ti',
        type: 'Calculation',
    },
    {
        id: 'f497b2ae-01cf-406d-99dd-77f7567f40cd',
        details: 'percentage of profit pool planned',
        slug: 'percentage_of_profit_pool_planned',
        type: 'Calculation',
    },
    {
        id: 'dd85cc97-a5b6-471e-9ebf-7df076d160ca',
        details: 'Base RSP',
        slug: 'base_rsp',
        type: 'SP Backend Lookup',
    },
    {
        id: '743c2dbc-7929-431c-bb9f-802dc21f7a84',
        details: 'Retailer margin actuals',
        slug: 'retailer_margin_actuals',
        type: 'Calculation',
    },
    {
        id: '1533a0be-8dad-42ca-a5dc-878d111bffe5',
        details: 'Terms percentage',
        slug: 'terms_percentage',
        type: 'SP Backend Lookup',
    },
    {
        id: '524d3478-9887-4e6c-8593-89a5059eadfa',
        details: 'Fixed Amount (Per week)',
        slug: 'fixed_amount_per_week',
        type: 'SP Backend Lookup',
    },
    {
        id: '6b70528b-f00a-435c-93aa-8deecaf4c792',
        details: 'Incremental GM',
        slug: 'incremental_gm',
        type: 'Calculation',
    },
    {
        id: '6f4d54c5-e8f9-46bc-819a-90ce3773ba43',
        details: 'Retailer margin per vol',
        slug: 'retailer_margin_per_vol',
        type: 'Calculation',
    },
    {
        id: '8a838b40-dec1-4212-9f5b-9e49bb5a67bb',
        details: 'COGS planned',
        slug: 'cogs_planned',
        type: 'Calculation',
    },
    {
        id: '4d3d6dc4-e2c3-47ac-914f-a22336da2e8d',
        details: 'Promo Mechanic',
        slug: 'promomechanic',
        type: 'SP Backend Lookup',
    },
    {
        id: '78463ba6-26c6-422b-9b01-a53c5a969eda',
        details: 'RSV planned',
        slug: 'rsv_planned',
        type: 'Calculation',
    },
    {
        id: 'ad023395-3aac-40e2-9780-a6d87d194636',
        details: 'RM% planned',
        slug: 'rm_percentage_planned',
        type: 'Calculation',
    },
    {
        id: 'f2f193d7-c0f4-4503-a000-a6ffbdd7ce3d',
        details: 'Net landed price (Pre Fixed)',
        slug: 'net_landed_price_pre_fixed',
        type: 'SP Backend Lookup',
    },
    {
        id: '154bd739-2bbe-4a41-acd4-b1356fd00654',
        details: 'Gross margin actuals',
        slug: 'gross_margin_actuals',
        type: 'Calculation',
    },
    {
        id: '507f33af-d32b-49a9-a16b-ba16fa1f473c',
        details: 'Trade investment per vol',
        slug: 'trade_investment_per_vol',
        type: 'Calculation',
    },
    {
        id: '70aa4d24-c848-413d-87be-bca78744aa62',
        details: 'Display',
        slug: 'display',
        type: 'User input',
    },
    {
        id: '2febb050-df73-4a4a-af8e-c023cf16b5f2',
        details: 'Comments',
        slug: '_comments',
        type: 'User input',
    },
    {
        id: '397f2e62-719e-4925-99d5-c1362c8e3870',
        details: 'Actual volume per week (units)',
        slug: 'actual_volume_per_week_units',
        type: 'SP Backend Lookup',
    },
    {
        id: '3ad9fa49-f07c-46a8-a7ed-c45eb7c837f0',
        details: 'RSP',
        slug: 'rsp',
        type: 'User input',
    },
    {
        id: '95fc2b14-0dac-4023-87b1-c8db66a958e0',
        details: 'GSV per vol',
        slug: 'gsv_per_vol',
        type: 'Calculation',
    },
    {
        id: 'c28ba026-6d89-4240-91e9-cc625be7a40b',
        details: 'RSV per vol',
        slug: 'rsv_per_vol',
        type: 'Calculation',
    },
    {
        id: '6729d7aa-79b5-49df-967d-d37ce3811ccd',
        details: 'Volume (measure)',
        slug: 'volume_measure',
        type: 'Calculation',
    },
    {
        id: 'c9f3807d-9f22-4daa-bb7f-e4dcae0b4422',
        details: 'RSV actuals',
        slug: 'rsv_actuals',
        type: 'Calculation',
    },
    {
        id: '4a8576b0-dcce-4319-b176-e93945cb4849',
        details: 'Incremental volume',
        slug: 'incremental_volume',
        type: 'Calculation',
    },
    {
        id: '2f70ad29-089d-4357-b2bc-f2904bcba914',
        details: 'List price',
        slug: 'list_Price',
        type: 'SP Backend Lookup',
    },
    {
        id: 'c53f0c1c-4bb2-4703-b75c-f48b1d973a4e',
        details: 'Gross margin per vol',
        slug: 'gross_margin_per_vol',
        type: 'Calculation',
    },
    {
        id: 'faaedc73-601f-449e-a3e7-f94dcc2c61b6',
        details: 'GST / VAT',
        slug: 'gst_vat',
        type: 'SP Backend Lookup',
    },
];
