<template>
  <div>
    <div class="pa-2 font-weight-bold text-h5 blue--text text--darken-4">User Management
    </div>
    <v-card elevation="12">
      <v-card-title>
        <v-row class="ma-2">
        <v-btn rounded class="mr-2" color="primary" @click="refresh()">
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-btn v-if="canCreate" :disabled="userQuota.licenseUserNumber == userQuota.currentUserNumber || userQuota.licenseUserNumber < userQuota.currentUserNumber" rounded class="white--text" color="primary" @click="dialog = true">New User<v-icon small
            class="ml-2">
            mdi-plus</v-icon>
        </v-btn>
        </v-row>
    
       


      </v-card-title>
      <v-card-text>
        <v-divider class="my-1"> </v-divider>
        <v-row>
       
   <v-col  class="my-2 d-flex" >
    <v-col cols="2"><div class="font-weight-bold">User Quota Based on License:</div>
    </v-col>
   <v-col cols="10"><v-progress-linear color="primary" rounded striped :value="quotaPercent" height="20">
     <strong style="color:white;">{{ userQuota.currentUserNumber }} / {{ userQuota.licenseUserNumber }}</strong>
   </v-progress-linear></v-col>
  </v-col>
 </v-row>
 <v-divider class="mb-4"> </v-divider>
        <v-data-table :headers="headers" :items="users" sort-by="name" class="elevation-1" :loading="loading">
          <template v-slot:[`item.emailConfirmed`]="{ item }">
            <v-chip small :color="item.emailConfirmed ? 'green' : 'grey'" dark>
              {{ item.emailConfirmed ? 'YES' : 'NO' }}
            </v-chip>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <v-chip small :color="item.isActive ? 'green' : 'grey'" dark>
              {{ item.isActive ? 'YES' : 'NO' }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :disabled="item.id == userProfile.id" rounded small class="white--text"
              :color="item.isActive ? 'red' : 'green'" @click="toggleUser(item)">{{ item.isActive ? 'Disable' : 'Enable' }}

            </v-btn>


          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="primary white--text mb-4">
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="newForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field :rules="[rules.required, rules.length6]" dense outlined v-model="editedItem.userName"
                    label="User Name">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :rules="[rules.required, rules.email]" dense outlined v-model="editedItem.email"
                    label="Email">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="[rules.required]" dense outlined v-model="editedItem.firstName"
                    label="First Name">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :rules="[rules.required]" dense outlined v-model="editedItem.lastName"
                    label="Last Name">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="editedIndex === -1">
                <v-col>
                  <v-text-field outlined dense id="mdi-password" v-model="editedItem.password"
                    :rules="[rules.required, rules.length6]" label="Password" :type="showPwd ? 'text' : 'password'"
                    :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPwd = !showPwd">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field required outlined dense id="mdi-password" v-model="editedItem.confirmPassword"
                    label="Confirm Password" :rules="[rules.required, rules.length6]"
                    :type="showConfirmPwd ? 'text' : 'password'"
                    :append-icon="showConfirmPwd ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showConfirmPwd = !showConfirmPwd" :error-messages="errorMsg" @focus="errorMsg = ''">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field dense outlined v-model="editedItem.phoneNumber" label="Phone Number">
                  </v-text-field>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogToggle" max-width="500px">
      <v-card>
        <v-card-title class="primary white--text mb-4">{{ editedItem.isActive ? 'Disable' : 'Enable' }} User </v-card-title>
        <v-card-subtitle class="my-2">Are you sure you want to {{ editedItem.isActive ? 'Disable' : 'Enable' }} the User:
          <strong>{{ editedItem.firstName }} {{ editedItem.lastName }}</strong>?
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeToggle">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="toggleUserConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="errorReulst ? 'red' : 'green'" top :timeout="3000">
      {{ userMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '../../stores/user'
import { userPermissions } from '../../common/constants'
export default {
  name: 'UserList',
  data: () => ({
    errorMsg: '',
    snackbar: false,
    errorReulst: false,
    dialog: false,
    dialogToggle: false,
    loading: false,
    editedIndex: -1,
    showPwd: false,
    showConfirmPwd: false,
    userMsg: '',
    editedItem: {

    },
    defaultItem: {},
    rules: {
      required: value => !!value || 'Required.',
      length6: value => value && value.length >= 6 || 'At least 6 characters',
      // eslint-disable-next-line 
      email: v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Invalid Email'

    },
    headers: [
      {
        text: 'User Name',
        align: 'start',
        sortable: false,
        value: 'userName',
        class: "primary--text",

      },
      { text: 'First Name', value: 'firstName', class: "primary--text", },
      { text: 'Last Name', value: 'lastName', class: "primary--text", },
      { text: 'Email', value: 'email', class: "primary--text", },
      { text: 'Email Confirmed', value: 'emailConfirmed', align: 'center', class: "primary--text", },
      { text: 'Phone No.', value: 'phoneNumber', class: "primary--text", },
      { text: 'Active', value: 'isActive', align: 'center', class: "primary--text", },
      { align: 'center', text: 'Actions', value: 'actions', sortable: false, class: "primary--text" }

    ]

  }),
  mounted() {
    this.LoadAllUsers()
  },
  computed: {
    ...mapState(useUserStore, ['users', 'currentUserPermissions', 'userProfile', 'userQuota']),
    quotaPercent() {
      return (this.userQuota.currentUserNumber / this.userQuota.licenseUserNumber) * 100
    },
    canCreate() {
      return this.currentUserPermissions.findIndex(p => p == userPermissions.CREATE_USER) != -1
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New User' : 'Edit User'
    },
  },
  methods: {
    ...mapActions(useUserStore, ['LoadAllUsers', 'CreateUser', 'ToggleUserStatus']),
    refresh() {
      this.LoadAllUsers()
    },
    validatePwd() {
      if (this.editedItem.password != this.editedItem.confirmPassword) {
        this.errorMsg = "The passwords you entered doesn't match"
        return false
      }
      else {
        this.errorMsg = ''
        return true
      }
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    toggleUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogToggle = true
    },

    async toggleUserConfirm() {
      this.loading = true
      await this.ToggleUserStatus(this.editedItem)
      //  await deleteRefData(this.collectionName.toLocaleLowerCase(),this.editedItem)
      await this.LoadAllUsers()
      this.loading = false
      this.closeToggle()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.newForm.resetValidation()
      })
    },

    closeToggle() {
      this.dialogToggle = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      if (!this.$refs.newForm.validate()) {
        return
      }
      if (this.editedIndex > -1) {
        //edit
        this.loading = true
        Object.assign(this.users[this.editedIndex], this.editedItem)
        console.log(this.editedItem)
        //await updateRefData(this.collectionName.toLocaleLowerCase(), this.editedItem)
        this.loading = false
      } else {
        //new
        if (!this.validatePwd()) { return }
        this.loading = true
        const res = await this.CreateUser(this.editedItem)
        if (res.status == 200) {
          this.errorReulst = false
          this.userMsg = 'User Created'
          this.snackbar = true
        } else {
          this.errorReulst = true
          this.userMsg = 'Something went wrong'
          this.snackbar = true
        }

        await this.LoadAllUsers()
        this.loading = false
      }
      this.close()
    },

  }

}
</script>
