<template>
  <PowerBILoader
    :reportTitle="'Customer Pricing Performance'"
    :reportUrl="'https://app.powerbi.com/reportEmbed?reportId=6e02a777-4e9d-405f-9a67-2cacf99d9866&autoAuth=true&ctid=1bda8acf-807e-4a1c-b603-a2b6bb566cdb&navContentPaneEnabled=false'"
  ></PowerBILoader>
</template>
<script>
import PowerBILoader from "./PowerBILoader.vue";
export default {
  name: "CustomerPricingPerformance",
  components: {
    PowerBILoader,
  },
};
</script>