<template>
  <div v-if="userProfile">
    <AdminLayout v-if="userProfile.tenantId === 'root'" :userProfile="userProfile"></AdminLayout>
    <EndUserLayout v-else :userProfile="userProfile"></EndUserLayout>
  </div>
</template>

<script>

import { mapActions, mapState } from 'pinia';
import { useUserStore } from '../stores/user'
import { masterDataStore } from '../stores/masterdata'
import AdminLayout from '../components/RootAdmin/AdminLayout.vue'
import EndUserLayout from '@/components/EndUser/EndUserLayout.vue';

export default {
  name: 'HomeView',

  components: {
    AdminLayout,
    EndUserLayout
  },
  async mounted() {
    this.LoadCurrentUserProfile()
    this.LoadCurrentUserPermission()
    await this.LoadRefData()
    await this.GetAllCustomers()
    await this.GetAllPlanTags()
    await this.GetAllPromoTypes()
    await this.GetAllProducts()
    await this.GetAllElasticities()
  },
  methods: {
    ...mapActions(useUserStore, ['LoadCurrentUserProfile', 'LoadCurrentUserPermission']),
    ...mapActions(masterDataStore, ['LoadRefData', 'GetAllCustomers', 'GetAllPlanTags', 'GetAllPromoTypes', 'GetAllProducts', 'GetAllElasticities']),
  },
  computed: {
    ...mapState(useUserStore, ['userProfile'])
  }
};
</script>
