import { defineStore } from 'pinia';
import {
    getPlanList,
    getFcPlanList,
    getExistingFcPlanList,
    getClashigFcPlanList,
    getCusAndProdData,
    clasingPlansList,
    getClasingPlansList,
    getFcTableData,
    getWeekCalData,
    ClashingTableData,
    FrequencyTableData,
    getCustomerAndProducts,
    getFcPlanDetails,
    getExistingFcPlanDetails,
    getPlanDetails,
    getPlanFcDetails,
    updatePlanProducts,
    createPlan,
    deletePlan,
    updatePlanCogs,
    getFreqFcPlansList,
    FrequencyTable2Data,
    saveFcPlan,
    deleteFcPlan,
    saveAsFcPlan,
    getFcSavedTableData,
    getDetailsList,
    getProductsFromCustomerAndSegment,
} from '@/common/contextapi';
import planProductHeaders from '@/common/planproductsheaders';
import { emptyGuid } from '@/common/constants';
import { calculateStatusOfProducts } from '@/lib';

export const usePlanStore = defineStore({
    id: 'plan',
    state: () => ({
        plans: [],
        fcPlans: [],
        existingFcPlans: [],
        existingClashingFcPlans: [],
        CustomerData: [],
        existingFreqFCPlans: [],
        ProductData: [],
        clashingPlans: [],
        fcTableData: [],
        // FrequencyTData: null,
        // FrequencyT2Data: null,
        currentUserPlans: [], // plans to current user available
        draftPlan: {
            products: [],
            sumTotalUnits: 0,
            newName: undefined,
        },
        draftPlanData: [],
        draftFcPlan: {
            products: [],
            sumTotalUnits: 0,
            newName: undefined,
        },
        draftPlanBase: {
            products: [],
        },
        CusAndProd: [],
        productCols: planProductHeaders,
        unsavedChanges: [],
    }),
    getters: {},
    actions: {
        tearDown() {
            this.draftPlan = {
                products: [],
                sumTotalUnits: 0,
                newName: undefined,
            };
        },

        async UpdatePlanCogs(planList) {
            try {
                const res = await updatePlanCogs({ planList: planList });
            } catch (err) {
                console.log(err);
            }
        },

        async GetPlanList() {
            const res = await getPlanList();

            this.plans = res.data;
        },

        async getFcPlanList() {
            const res = await getFcPlanList();
            console.log('res12', res);
            this.fcPlans = res;
        },

        async getExistingFcPlanList() {
            const res = await getExistingFcPlanList();
            console.log('res22', res);
            this.existingFcPlans = res;
        },

        async getClashigFcPlanList(segmentId, categoryId) {
            const res = await getClashigFcPlanList(segmentId, categoryId);
            console.log('res22', res);
            this.existingClashingFcPlans = res;
        },

        async getFreqFcPlansListHandler(customerId, categoryId) {
            const res = await getFreqFcPlansList(customerId, categoryId);

            this.existingFreqFCPlans = res;
        },

        async getClasingPlansList() {
            const res = await getClasingPlansList();
            console.log('res166', res);
            this.clashingPlans = res;
        },

        async getFcTableDataHandler(payload) {
            console.log('PAYLOAD=', payload);
            const res = await getFcTableData(payload);
            console.log('res11121', res);
            this.fcTableData = res;
        },

        async getFcSavedTableDataHandler(payload) {
            console.log('PAYLOAD=', payload);
            const res = await getFcSavedTableData(payload);
            console.log('res11121', res);
            this.fcTableData = res;
        },

        async getProductsFromCustomerAndSegmentHandler(payload) {
            const res = await getProductsFromCustomerAndSegment(payload);

            return res;
        },

        async getWeekCalDataHandler(payload) {
            console.log('PAYLOAD=', payload);
            const res = await getWeekCalData(payload);
            console.log('resWeekCal', res);
            this.fcTableData = res;
        },

        async GetClashingTableData(payload) {
            console.log('PAYLOAD=', payload);
            var res = await ClashingTableData(payload);
            res = calculateStatusOfProducts(res);
            console.log('resWeekCal', res);
            return res;
        },
        async GetFrequencyTableData(payload) {
            console.log('PAYLOADT1=', payload);
            var res = await FrequencyTableData(payload);
            // res = calculateStatusOfProducts(res);
            console.log('FrequencyTableData12', res);

            // this.FrequencyTData = res;

            return res;
        },

        async GetFrequencyTable2Data(payload) {
            console.log('PAYLOADT2=', payload);
            var res = await FrequencyTable2Data(payload);

            // this.FrequencyT2Data = res;
            return res;
        },

        UpdateEditRowItem(item) {
            if (this.draftPlan.products.find((p) => p.inEdit == true)) {
                this.draftPlan.products.find((p) => p.inEdit == true).inEdit = false;
            }
            this.draftPlan.products.find((p) => p.productId == item.productId).inEdit = true;
        },

        async LoadPlanProductsForPromo(planId) {
            try {
                const res = await getPlanDetails(planId);
                return res.data.products;
            } catch (err) {
                console.log(err);
            }
        },

        async LoadFcPlanProductsForPromo(planId) {
            try {
                const res = await getFcPlanDetails(planId);
                return res.data.products;
            } catch (err) {
                console.log(err);
            }
        },

        async LoadExistingPlan(planId, newName) {
            try {
                console.log('LoadExistingPlandata', planId, newName);
                const res = await getPlanDetails(planId);
                var plan = res.data;

                console.log('plan12', plan);
                //init the cols to make it reactive
                plan.products.forEach((p) => {
                    planProductHeaders
                        .filter((h) => !h.formula)
                        .forEach((h) => {
                            p[h.value] =
                                p[h.value] == undefined
                                    ? h.defaultValue == undefined
                                        ? ''
                                        : h.defaultValue
                                    : p[h.value];
                        });
                    planProductHeaders
                        .filter((h) => h.formula && !h.special)
                        .sort((a, b) => a.calLvl - b.calLvl)
                        .forEach((h) => {
                            //call the formula to generate the value if it's calculated column
                            h.formula(p);
                        });
                    p.inEdit = false;
                    // special handle
                    p.termsPercent = parseFloat(p.termsPercent).toFixed(2);
                });

                //average values
                plan.products.forEach((p) => {
                    planProductHeaders
                        .filter((h) => h.special && h.formula)
                        .sort((a, b) => a.calLvl - b.calLvl)
                        .forEach((h) => {
                            //h.formula(p, plan.products)
                        });
                });
                // set comparision plan values
                plan.products.forEach((p) => {
                    p.comparisonPlanRSP = p.rsv;
                    p.comparisonPlanFreq = p.frequency;
                    p.comparisonPlanVol = p.volPerWeek;
                    p.comparisonPlanRM = p.rmPercent;
                });

                // calculate sums
                plan.sumTotalUnits = plan.products.reduce((a, item) => a + item.totalUnits, 0);
                plan.sumGSV = plan.products.reduce((a, item) => a + parseFloat(item.gsv), 0);
                plan.sumTI = plan.products.reduce(
                    (a, item) => a + parseFloat(item.tradeInvestment),
                    0
                );
                plan.sumNR = plan.products.reduce((a, item) => a + parseFloat(item.netRevenue), 0);
                plan.sumCOGS = plan.products.reduce((a, item) => a + parseFloat(item.cogs), 0);
                plan.sumGM = plan.products.reduce((a, item) => a + parseFloat(item.grossMargin), 0);
                plan.sumRM = plan.products.reduce(
                    (a, item) => a + parseFloat(item.retailerMargin),
                    0
                );
                plan.sumRSV = plan.products.reduce((a, item) => a + parseFloat(item.rsv), 0);

                console.log('plan123', plan);
                this.draftPlanBase = JSON.parse(JSON.stringify(plan));
                if (newName) {
                    plan.newName = newName;
                    plan.comparisionPlanId = plan.id;
                    delete plan.id;
                }
                console.log('newName12', newName);

                this.draftPlan = JSON.parse(JSON.stringify(plan));
                this.unsavedChanges = [];

                console.log('plan113', this.draftPlan, this.unsavedChanges);

                if (window.scenarioBuilder) {
                    window.scenarioBuilder.dirtyItems = [];
                }
            } catch (err) {
                console.log(err);
            }
        },

        async GetCusAndProdData(ClashingFcPlanId) {
            const res = await getCustomerAndProducts(ClashingFcPlanId);
            console.log('res1212', res);
            this.ProductData = res?.Products;
            this.CustomerData = res?.Customers;
        },

        async LoadExistingFcPlan(planId, newName) {
            try {
                console.log('planId12', planId, newName);
                const res = await getFcPlanDetails(planId);

                console.log('planId11', res);

                this.draftPlan = JSON.parse(JSON.stringify(res?.forecastData));

                this.draftPlanData = JSON.parse(JSON.stringify(res));

                return res?.forecastData;
            } catch (err) {
                console.error(err);
            }
        },

        duplicateCurrentPlan(newName) {
            console.log('newName12', newName);
            this.draftPlanBase = this.draftPlan;
            var newPlan = JSON.parse(JSON.stringify(this.draftPlan));
            newPlan.name = newName;
            newPlan.newName = newName;
            newPlan.comparisionPlanId = this.draftPlan.id;
            delete newPlan.id;
            newPlan.products.forEach((p) => {
                //p.id = emptyGuid
                p.isNew = true;
            });

            this.draftPlan = newPlan;
        },

        async getDetailsListHandler() {
            try {
                const response = await getDetailsList();
                return response;
            } catch (error) {
                return false;
            }
        },

        async duplicateCurrentFcPlan(
            newPlanName,
            selectedPlanId,
            newPlanStartDate,
            newPlanYear,
            newPlanCategory,
            switchValue
        ) {
            try {
                console.log(
                    'newName12',
                    newPlanName,
                    selectedPlanId,
                    newPlanYear,
                    newPlanCategory,
                    switchValue
                );

                const response = await getExistingFcPlanDetails(
                    newPlanName,
                    selectedPlanId,
                    newPlanStartDate,
                    newPlanYear,
                    newPlanCategory,
                    switchValue
                );

                var plan = response;

                this.draftPlan = JSON.parse(JSON.stringify(plan.forecastData));

                this.draftPlanData = JSON.parse(JSON.stringify(plan));

                // if (plan && plan.forecastData) {
                //     this.draftPlan = JSON.parse(
                //         JSON.stringify(plan.forecastData)
                //     );
                // } else {
                //     console.error("Plan or forecast data not found.");
                //     return;
                // }

                this.unsavedChanges = [];

                console.log('plan114', this.draftPlan);

                if (window.scenarioBuilder) {
                    window.scenarioBuilder.dirtyItems = [];
                }
            } catch (err) {
                console.error(err);
            }
        },

        AddPlanProduct(planProduct) {
            planProductHeaders.forEach((h) => {
                planProduct[h.value] = planProduct[h.value] || h.defaultValue || '';
            });
            this.draftPlan.products.unshift(planProduct);
        },

        async UpdatePlanProducts(products) {
            await updatePlanProducts(products);
            products.isNew = false;
        },

        async CreatePlan(planData) {
            try {
                const res = await createPlan(planData);
                var plan = res.data;
                this.draftPlan.id = plan.id;
                this.draftPlan.newName = undefined;
                this.draftPlan.createdBy = plan.createdBy;
                this.draftPlan.lastModifiedOn = plan.lastModifiedOn;
            } catch (err) {
                console.log(err);
            }
        },

        async DeletePlan(planId) {
            try {
                const res = await deletePlan(planId);
                if (this.draftPlan.id == planId) {
                    this.draftPlan = {
                        products: [],
                        sumTotalUnits: 0,
                        newName: undefined,
                    };
                }
                this.GetPlanList();
            } catch (err) {
                console.log(err);
            }
        },

        ClearUnSavedChanges() {
            this.unsavedChanges = [];
        },

        async saveFcPlanHandler(payload, startDate) {
            const response = await saveFcPlan(payload, startDate);

            return response.status;
        },

        async saveAsFcPlanHandler(payload, params) {
            const response = await saveAsFcPlan(payload, params);

            return response.status;
        },

        async deleteFcPlanHandler(id) {
            const response = await deleteFcPlan(id);

            return response;
        },
    },
});
