<template>
    <v-container v-if="reportUrl" fluid style="height: 100vh">
        <v-row v-if="loading" justify="center" class="fill-height">
            <v-col cols="auto" class="ma-auto">
                <div class="d-flex justify-center">
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </div>
            </v-col>
        </v-row>
        <iframe
            id="powerbiframe"
            style="visibility: hidden"
            :onLoadeddata="onLoad()"
            :title="reportTitle"
            width="100%"
            :height="height"
            :src="reportUrl"
            frameborder="0"
            allowFullScreen="true"
        ></iframe>
    </v-container>
</template>

<script>
export default {
    name: "PowerBILoader",
    data: () => {
        return {
            loading: true,
        };
    },
    components: {
        // PowerBIReportEmbed
        //https://app.powerbi.com/reportEmbed?reportId=99b3a69f-8f51-482c-bb77-6cc75f4b76dc&autoAuth=true&ctid=1bda8acf-807e-4a1c-b603-a2b6bb566cdb
    },
    methods: {
        onLoad() {
            setTimeout(() => {
                this.loading = false;
                document.getElementById("powerbiframe").style.visibility =
                    "visible";
            }, 3500);
        },
    },
    props: {
        reportTitle: {
            type: String,
            required: false,
        },
        reportUrl: {
            type: String,
            required: true,
        },
        height: {
            type: String,
            default: "100%",
        },
    },
};
</script>
