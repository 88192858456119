import { HOST_API } from "@/global-config";
import axios from "axios";
import jwt_decode from "jwt-decode";
const dayjs = require("dayjs");

const axiosInstance = axios.create({ baseURL: HOST_API });

export const logIn = async function (userName, password, tenantid) {
    const response = await axiosInstance.post(
        "/api/tokens",
        {
            email: userName,
            password: password,
        },
        {
            headers: {
                tenant: tenantid,
            },
        }
    );
    if (response.status === 200) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("refreshToken", response.data.refreshToken);
        window.localStorage.setItem(
            "refreshTokenExpiryTime",
            response.data.refreshTokenExpiryTime
        );
        return response;
    }
};

export const isAuthenticated = function () {
    const token = window.localStorage.getItem("token");
    if (token) {
        const decodedToken = jwt_decode(token);
        if (decodedToken.exp * 1000 > new Date().getTime()) {
            return true;
        }
    }
    return false;
};

export const forgotPassword = async function (userName, tenantid) {
    const result = await axiosInstance.post(
        "/api/users/forgot-password",
        {
            email: userName,
        },
        {
            headers: {
                tenant: tenantid,
            },
        }
    );
    return result;
};

export const resetPassword = async function (email, password, token, tenantid) {
    const result = await axiosInstance.post(
        "/api/users/reset-password",
        {
            email: email,
            password: password,
            token: token,
        },
        {
            headers: {
                tenant: tenantid,
            },
        }
    );
    if (result.status === 200) {
        return result.data;
    }
    return null;
};

export const formatDate = function (timeStr) {
    return dayjs(timeStr).format("DD MMM YYYY");
};

export const formatLastModifiedOn = function (timeStr) {
    return dayjs(timeStr).format("h:mm a. DD MMM YYYY");
};

export const formatNumber = function (number, decimal = 0) {
    const options = {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
    };
    if (typeof number == "string" && number.endsWith("%")) {
        return number;
    }
    number = parseFloat(number);
    if (isNaN(number)) {
        return number;
    }
    const formatted = Number(number).toLocaleString("en", options);
    return formatted;
};
