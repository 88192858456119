import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import Router from 'vue-router';
import ResetPasswordView from '../views/ResetPassword.vue';

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            meta: { requiresAuth: true },
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: { requiresAuth: false },
            // only authenticated users can create posts
        },
        {
            name: 'resetpassword',
            path: '/resetpassword',
            component: ResetPasswordView,
            meta: { requiresAuth: false },
        },
        {
            name: 'about',
            path: '/about',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/AboutView.vue'),
        },
    ],
});

export default router;
