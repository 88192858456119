<template>
           <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
            <v-card>
                <v-toolbar dark :color="options.color" dense flat>
                    <v-toolbar-title class="text-body-2 font-weight-bold white--text">
                        {{ title }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text v-show="!!message" class="pa-4 black--text" v-html="message"></v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>

                    <v-btn color="grey" class="body-2 font-weight-bold" outlined @click.native="agree">{{options.aggreeTxt}}</v-btn>
                    <v-btn v-if="!options.noconfirm" color="primary" text class="body-2 font-weight-bold"
                        @click.native="cancel">{{options.cancelTxt}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>

export default {
    name: 'ConfirmDialog',

    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            color: "primary",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            aggreeTxt: 'Leave',
            cancelTxt:'Stay'
        }
    }),
    methods:{
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
}
</script>
