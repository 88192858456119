<template>
    <v-app>
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        //
    }),
    methods: {},
    created() {},
};
</script>
<style>
html,
body {
    /* overflow-y: hidden !important; */
    font-family: Inter;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #01579b;
}

.v-btn {
    border-radius: 8px !important;
}

.v-dialog {
    border-radius: 16px !important;
}

.primary_dark {
    color: white !important; /* Set the text color to white */
}

.v-expansion-panel {
    border-radius: 16px !important;
}
</style>
