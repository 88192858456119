<template>
  <div>
    <v-card color="#ECF3FF">
      <v-card-title
        class="ma-2 align-center font-weight-bold text-h5 blue--text text--darken-4"
      >
        Promo Booster
      </v-card-title>
      <v-card-text>
        <v-card class="pa-4 rounded-lg">
          <v-row>
            <v-col>
              <div
                class="my-2 align-center font-weight-bold text-h7 blue--text text--darken-4"
              >
                Portfolio Selection
              </div>
              <div>
                <v-row>
                  <v-col>
                    <div class="my-2">Category</div>
                    <v-select
                      dense
                      :items="referenceData.categories"
                      item-text="name"
                      item-value="id"
                      v-model="selectedCategory"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col>
                    <div class="my-2">Customer</div>
                    <v-select
                      dense
                      :items="specialRefData.customers"
                      item-text="name"
                      item-value="id"
                      v-model="selectedCustomer"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="my-2">Segment</div>
                    <v-select
                      dense
                      :items="referenceData.segments"
                      item-text="name"
                      item-value="id"
                      v-model="selectedSegment"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col>
                    <div class="my-2">Plan</div>
                    <v-select
                      dense
                      :items="plans"
                      item-text="name"
                      item-value="id"
                      outlined
                      v-model="selectedPlan"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-divider vertical inset class="ma-2"></v-divider>
            <v-col>
              <div
                class="my-2 align-center font-weight-bold text-h7 blue--text text--darken-4"
              >
                Rank Results
              </div>
              <div>
                <v-row>
                  <v-col>
                    <div class="my-2">GM $</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <div class="my-2">RM $</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <div class="my-2">Volumne.</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-divider vertical inset class="mx-2"></v-divider>
            <v-col>
              <div
                class="my-2 align-center font-weight-bold text-h7 blue--text text--darken-4"
              >
                Macro Limits
              </div>
              <div>
                <v-row>
                  <v-col>
                    <div class="my-2">Price Upper</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <div class="my-2">Price Lower</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="my-2">Freq. Upper</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <div class="my-2">Freq. Lower</div>
                    <v-text-field
                      dense
                      v-model="gmValue"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col class="pa-12 align-center d-flex justify-center">
              <div>
                <div class="mb-4">
                  <v-btn class="white--text" color="primary" large block>
                    <v-icon left color="white"> mdi-swap-horizontal </v-icon>
                    Run Simulation
                  </v-btn>
                </div>
                <div>
                  <v-btn large outlined block disabled>
                    <v-icon left color="primary">
                      mdi-chart-box-outline
                    </v-icon>
                    Visualize Simulation
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-4 mt-8 rounded-lg">
          <v-card-title>
            <div class="blue--text text--darken-4">Promo Products</div>
            <v-spacer></v-spacer>
            <div class="ml-5">
              <v-text-field
                dense
                v-model="search"
                outlined
                prepend-inner-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              id="virtual-scroll-table"
              :headers="headers"
              :loading="loading"
              dense
              group-by="product"
              :items="promoProducts"
              :search="search"
              sort-by="category"
              class="pb-10"
            >
              <template v-slot:[`item.promoType`]="{ item }">
                <div v-if="getPromoType(item) != 'Error'">
                  {{ getPromoType(item) }}
                </div>
                <div v-else style="background: #fef3f2; color: #b42318">
                  {{ getPromoType(item) }}
                </div>
              </template>
              <template v-slot:[`group.header`]="{ group }">
                <td colspan="8" class="text-h6 font-weight-bold">
                  {{ group.toUpperCase() }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { masterDataStore } from "../../stores/masterdata";
import { usePlanStore } from "../../stores/plan";

export default {
  name: "PromoBooster",
  data: () => ({
    gmValue: 0,
    search: "",
    selectedCategory: null,
    selectedCustomer: null,
    selectedSegment: null,
    selectedPlan: null,
    loading: false,
    promoProducts: [],
    headers: [
      {
        text: "Products",
        value: "product",
        cellClass: "categoryCell",
        class: "primary--text",
      },
      {
        text: "Promo Type",
        value: "promoType",
        cellClass: "segmentCell",
        class: "primary--text",
      },
      {
        text: "Price Scenario",
        value: "rsp",
        cellClass: "segmentCell",
        class: "primary--text",
      },
      {
        text: "Price - Upper",
        value: "segmentId",
        cellClass: "segmentCell",
        class: "primary--text",
      },
      {
        text: "Price - Lower",
        value: "segmentId",
        cellClass: "segmentCell",
        class: "primary--text",
      },
      {
        text: "Frequency Scenario",
        value: "frequency",
        cellClass: "segmentCell",
        class: "primary--text",
      },
      {
        text: "Frequency - Upper",
        value: "segmentId",
        cellClass: "segmentCell",
        class: "primary--text",
      },
      {
        text: "Frequency - Lower",
        value: "segmentId",
        cellClass: "segmentCell",
        class: "primary--text",
      },
    ],
  }),
  methods: {
    ...mapActions(usePlanStore, [
      "LoadPlanProductsForPromo",
      "LoadFcPlanProductsForPromo",
    ]),
    getPromoType(item) {
      if (!item.rsp || !item.baseRSP) return "Error";
      let rate = (1 - item.rsp / item.baseRSP) * 100;
      if (rate < 0) return "Error";
      let promoType = "Error";
      //sorted = sorted.slice().sort((a, b) => a.rspRate - b.rspRate)
      for (let idx = 0; idx < this.sortedPromoTypes.length; idx++) {
        if (
          idx == this.sortedPromoTypes.length - 1 &&
          rate > this.sortedPromoTypes[idx].rspRate
        ) {
          promoType = this.sortedPromoTypes[idx].name;
          break;
        }
        if (
          rate == this.sortedPromoTypes[idx].rspRate ||
          (rate > this.sortedPromoTypes[idx].rspRate &&
            rate < this.sortedPromoTypes[idx + 1].rspRate)
        ) {
          promoType = this.sortedPromoTypes[idx].name;
          break;
        }
      }
      //item.promoType = promoType

      return promoType;
    },
  },
  computed: {
    ...mapState(masterDataStore, ["referenceData", "specialRefData"]),
    ...mapState(usePlanStore, ["plans"]),
    sortedPromoTypes() {
      return this.specialRefData.promotypes
        .slice()
        ?.sort((a, b) => a.rspRate - b.rspRate);
    },
  },
  created() {
    this.$watch(
      () => {
        return `${this.selectedCategory}-${this.selectedSegment}-${this.selectedPlan}-${this.selectedCustomer}`;
      },
      async () => {
        if (
          this.selectedCategory &&
          this.selectedCustomer &&
          this.selectedSegment &&
          this.selectedPlan
        ) {
          this.loading = true;
          const results = await this.LoadPlanProductsForPromo(
            this.selectedPlan
          );
          this.promoProducts = results;
          this.loading = false;
        }
      }
    );
  },
};
</script>
