import { defineStore } from 'pinia'
import { getAllReferenceData, getAllCustomers, getAllPlanTags, getAllProducts, createProduct, updateProduct, deleteProduct, getAllPromoTypes, getAllElasticities } from '@/common/contextapi'

export const masterDataStore = defineStore({
    id: 'masterData',
    state: () => ({
        products: [],
        //standard ref data that only has id and name
        referenceData: {},
        //special ref data
        specialRefData: {
            plantags:[],
            customers:[],
            promotypes:[],
            elasticities:[]
        }
    }),
    getters: {
    },
    actions: {
        async LoadRefData() {
            try {
                const res = await getAllReferenceData()
                this.referenceData = res
            }
            catch (err) {
                console.log(err)
            }
        },
        async GetAllCustomers() {
            const res = await getAllCustomers()
            var existingdata =  this.specialRefData
            existingdata.customers = res.data
            this.specialRefData = existingdata
        },

        async GetAllPlanTags() {
            const res = await getAllPlanTags()
            var existingdata =  this.specialRefData
            existingdata.plantags = res.data
            this.specialRefData = existingdata

        },

        async GetAllPromoTypes() {
            const res = await getAllPromoTypes()
            var existingdata =  this.specialRefData
            existingdata.promotypes = res.data
            this.specialRefData = existingdata

        },

        async GetAllElasticities() {
            const res = await getAllElasticities()
            var existingdata =  this.specialRefData
            existingdata.elasticities = res.data
            this.specialRefData = existingdata

        },

        async GetAllProducts() {
            try {
                const res = await getAllProducts()
                console.log(res)
                this.products = res.data
                return res
            }
            catch (err) {
                return err.response
            }
        },

        async CreateProduct(productObj) {
            try {
                const res = await createProduct(productObj)
                return res
            }
            catch (err) {
                return err.response
            }

        },
        async UpdateProduct(productObj) {
            try {
                const res = await updateProduct(productObj)
                return res
            }
            catch (err) {
                return err.response
            }

        },
        async DeleteProduct(productObj) {
            try {
                const res = await deleteProduct(productObj)
                return res
            }
            catch (err) {
                return err.response
            }

        }

    }
})