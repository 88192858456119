<template>
    <div>
        <div class="pa-2 font-weight-bold text-h5 blue--text text--darken-4">
            Welcome back {{ userProfile.firstName }}!
        </div>
        <v-card :elevation="12" class="mx-2">
            <v-card-title>
                <div class="blue--text text--darken-4">Recent Plans/ Scenarios</div>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" @click="refresh()" class="mr-2">
                    <v-icon> mdi-refresh </v-icon>
                </v-btn>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="white--text"
                            color="primary"
                            @click="cogsDialog = true"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="selectedForEditCogs.length == 0"
                        >
                            <v-icon left>mdi-currency-usd</v-icon>Edit COGS
                        </v-btn>
                    </template>
                    <span>Sync COGs from master data</span>
                </v-tooltip>
                <!--<v-btn rounded small class="white--text" color="#81D4FA">Add new Plan<v-icon class="ml-2">mdi-plus
                    </v-icon>
                </v-btn>-->

                <div class="ml-5">
                    <v-text-field
                        dense
                        v-model="search"
                        outlined
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                </div>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                id="virtual-scroll-plan-table"
                :headers="headers"
                :search="search"
                :items="userPlans"
                sort-desc
                sort-by="lastModifiedOn"
                class="pb-10 pl-2"
            >
                <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
                    <div :key="i" style="display: inline-block; padding: 16px 0">
                        <div style="display: inline-block; padding: 16px 0">
                            {{ header.text }}
                        </div>
                        <div style="float: right; margin-top: 8px">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-y
                                transition="slide-y-transition"
                                right
                                fixed
                                style="position: absolute; right: 0"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                                        <v-icon
                                            small
                                            :color="
                                                activeFilters[header.value] &&
                                                activeFilters[header.value].length <
                                                    filters[header.value].length
                                                    ? 'red'
                                                    : 'default'
                                            "
                                        >
                                            mdi-filter-variant
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list flat dense class="pa-0">
                                    <v-list-item-group
                                        multiple
                                        v-model="activeFilters[header.value]"
                                        class="py-2"
                                    >
                                        <div v-for="item in filters[header.value]" :key="`${item}`">
                                            <v-list-item :value="item" :ripple="false">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-action class="ma-0">
                                                        <v-checkbox
                                                            :input-value="active"
                                                            :true-value="item"
                                                            color="primary"
                                                            :ripple="false"
                                                            dense
                                                        >
                                                        </v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            v-text="item"
                                                        ></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </div>
                                    </v-list-item-group>
                                    <v-divider></v-divider>
                                    <v-row no-gutters>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                block
                                                @click="toggleAll(header.value)"
                                                color="success"
                                                >Select All
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                block
                                                @click="clearAll(header.value)"
                                                color="warning"
                                                >Clear All
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                    <div>
                        {{ item.name }}
                    </div>
                </template>
                <template v-slot:[`item.categoryId`]="{ item }">
                    <v-chip small color="#F2F4F7">
                        {{ referenceData.categories?.find((d) => d.id == item.categoryId)?.name }}
                    </v-chip>
                </template>
                <template v-slot:[`item.uniqueId`]="{ item }">
                    <div>
                        {{ referenceData.categories?.find((d) => d.id == item.categoryId)?.name }}
                        {{ item.name }}
                        {{ specialRefData.plantags?.find((d) => d.id == item.planTagId)?.name }}
                    </div>
                </template>
                <template v-slot:[`item.planTagId`]="{ item }">
                    <v-menu
                        v-if="canEditPlan(item)"
                        v-model="item.planTagMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                :color="
                                    specialRefData.plantags?.find((d) => d.id == item.planTagId)
                                        ?.colorCode
                                "
                                text-color="white"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{
                                    specialRefData.plantags?.find((d) => d.id == item.planTagId)
                                        ?.name
                                }}
                                <v-icon> mdi-menu-down </v-icon>
                            </v-chip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="tag in specialRefData.plantags"
                                :key="tag.id"
                                link
                                @click="updatePlanTag(item, tag.id), (item.planTagMenu = false)"
                            >
                                <v-list-item-title v-text="tag.name"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-chip
                        v-else
                        :color="
                            specialRefData.plantags?.find((d) => d.id == item.planTagId)?.colorCode
                        "
                        text-color="white"
                    >
                        {{ specialRefData.plantags?.find((d) => d.id == item.planTagId)?.name }}
                    </v-chip>
                </template>
                <template v-slot:[`item.comparisionPlanId`]="{ item }">
                    <v-menu
                        v-if="canEditPlan(item)"
                        v-model="item.cpMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                color="#F2F4F7"
                                v-bind="attrs"
                                v-on="on"
                                @click="editedItem = item"
                            >
                                {{
                                    availableComparisonPlans?.find(
                                        (d) => d.id == item.comparisionPlanId
                                    )?.name
                                }}
                                <v-icon> mdi-menu-down </v-icon>
                            </v-chip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="plan in availableComparisonPlansForCurrentPlans"
                                :key="plan.id"
                                link
                                @click="updateComparisonPlan(item, plan.id), (item.cpMenu = false)"
                            >
                                <v-list-item-title v-text="plan.name"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-chip v-else color="#F2F4F7">
                        {{
                            availableComparisonPlans?.find((d) => d.id == item.comparisionPlanId)
                                ?.name
                        }}
                    </v-chip>
                </template>
                <template v-slot:[`item.margin`]="{ item }">
                    <v-chip
                        small
                        :color="
                            item.margin <= 10
                                ? '#F8BBD0'
                                : item.margin <= 20
                                ? '#CCFF90'
                                : '#B9F6CA'
                        "
                        :text-color="
                            item.margin <= 10
                                ? '#FF4081'
                                : item.margin <= 20
                                ? '#9ac100'
                                : '#1B5E20'
                        "
                    >
                        {{ getMargin(item) }} %
                    </v-chip>
                </template>
                <template v-slot:[`item.revenue`]="{ item }">
                    <div class="font-weight-bold">${{ numberWithCommas(item.revenue) }}</div>
                </template>
                <template v-slot:[`item.startDate`]="{ item }">
                    <!--<v-menu v-if="editedItem.id && item.id === editedItem.id" v-model="startDatePickerMenu"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field background-color="#E4EDFF" hide-details single-line dense outlined
                                v-model="computedstartDateFormatted" label="Start Date" append-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker :allowed-dates="allowedStartDate" no-title v-model="editedItem.startDate"
                            @input="startDatePickerMenu = false">
                        </v-date-picker>
                    </v-menu>-->
                    <v-menu
                        v-if="canEditPlan(item)"
                        ref="menu"
                        v-model="item.startDatePickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                outlined
                                label
                                v-model="item.startDate"
                                color="#E4EDFF"
                                @click="editedItem = item"
                                text-color="#285CB7"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ formatDate(item.startDate)
                                }}<v-icon class="ml-2">mdi-calendar-blank-outline</v-icon>
                            </v-chip>
                        </template>
                        <v-date-picker
                            :allowed-dates="allowedStartDate"
                            no-title
                            v-model="item.startDate"
                            color="primary"
                            @input="(item.startDatePickerMenu = false), updatePlanItem(item)"
                        >
                            <!--<v-spacer></v-spacer>
                            <v-btn text color="primary" @click="item.startDatePickerMenu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                OK
                            </v-btn>-->
                        </v-date-picker>
                    </v-menu>
                    <v-chip
                        v-else
                        outlined
                        label
                        v-model="item.startDate"
                        color="#E4EDFF"
                        text-color="#285CB7"
                    >
                        {{ formatDate(item.startDate) }}
                    </v-chip>
                </template>
                <template v-slot:[`item.endDate`]="{ item }">
                    <v-menu
                        v-if="canEditPlan(item)"
                        v-model="item.endDatePickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                label
                                outlined
                                v-model="item.endDate"
                                color="#E4EDFF"
                                @click="editedItem = item"
                                text-color="#285CB7"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ formatDate(item.endDate)
                                }}<v-icon class="ml-2">mdi-calendar-blank-outline</v-icon>
                            </v-chip>
                        </template>
                        <v-date-picker
                            :allowed-dates="allowedEndDate"
                            no-title
                            v-model="item.endDate"
                            color="primary"
                            @input="(item.endDatePickerMenu = false), updatePlanItem(item)"
                        >
                        </v-date-picker>
                    </v-menu>
                    <v-chip
                        v-else
                        label
                        outlined
                        v-model="item.endDate"
                        color="#E4EDFF"
                        text-color="#285CB7"
                    >
                        {{ formatDate(item.endDate) }}
                    </v-chip>
                </template>
                <template v-slot:[`item.lastModifiedOn`]="{ item }">
                    <div>
                        {{ formatDate(item.lastModifiedOn) }}
                    </div>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <!--<template v-if="editedItem.id && item.id === editedItem.id">
                        <v-icon color="green" class="mr-2" @click="save()">
                            mdi-content-save-outline
                        </v-icon>
                        <v-icon color="#B42318" @click="close">
                            mdi-close-circle-outline
                        </v-icon>
                    </template>-->
                    <template>
                        <!--<v-icon class="mr-2" @click="editItem(item)">
                            mdi-chart-box-outline
                        </v-icon>-->
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="mr-2"
                                    @click="loadToSB(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>Load into Scenario Builder</span>
                        </v-tooltip>
                        <v-icon v-if="canEditPlan(item)" color="#B42318" @click="deleteItem(item)">
                            mdi-trash-can-outline
                        </v-icon>
                    </template>
                </template>
                <template v-slot:[`item.editcogs`]="{ item }">
                    <v-simple-checkbox
                        v-if="canEditPlan(item)"
                        color="primary"
                        v-model="item.editcogs"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.isPublic`]="{ item }">
                    <v-switch
                        v-if="canEditPlan(item)"
                        :disabled="!canEditPlan(item)"
                        v-model="item.isPublic"
                        @change="updatePlanItem(item)"
                    >
                    </v-switch>
                    <v-chip
                        v-else
                        outlined
                        v-model="item.isPublic"
                        :color="item.isPublic ? 'green' : '#E4EDFF'"
                    >
                        {{ item.isPublic ? 'Yes' : 'No' }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card class="pa-2">
                <v-card-title>
                    <!--<v-icon color="#D92D20">mdi-delete</v-icon>-->
                    <v-img
                        src="@/assets/deleteIcon.svg"
                        max-height="48px"
                        max-width="48px"
                        contain
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-icon @click="closeDelete()">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <div class="black--textfont-weight-bolder text--subtitle-2">
                        <strong>Delete Plan - {{ editedItem.name }}</strong>
                    </div>
                    <div>
                        Are you sure you want to delete this plan? This action cannot be undone.
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn width="180px" text outlined @click="dialogDelete = false">
                        Cancel
                    </v-btn>
                    <v-btn width="180px" text outlined color="#B42318" @click="deleteItemConfirm()">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title class="primary white--text mb-4">
                    <span class="text-h5">{{ formTitle }} Plan</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-text-field
                                :rules="[rules.required]"
                                dense
                                rounded
                                outlined
                                v-model="editedItem.name"
                                label=" "
                            >
                            </v-text-field>
                        </v-row>
                        <v-row>
                            <v-text-field
                                :rules="[rules.required]"
                                dense
                                rounded
                                outlined
                                v-model="editedItem.tag"
                                label="Plan Tag"
                            >
                            </v-text-field>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-menu
                                    v-model="startDatePickerMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            rounded
                                            v-model="computedstartDateFormatted"
                                            label="Start Date"
                                            append-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        :allowed-dates="allowedStartDate"
                                        no-title
                                        v-model="editedItem.startDate"
                                        @input="startDatePickerMenu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    v-model="endDatePickerMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            rounded
                                            v-model="computedendDateFormatted"
                                            label="End Date"
                                            append-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        :allowed-dates="allowedEndDate"
                                        no-title
                                        v-model="editedItem.endDate"
                                        @input="endDatePickerMenu = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        width="150px"
                        rounded
                        color="#81D4FA"
                        class="white--text"
                        @click="save"
                        :disabled="!editedItem.name"
                    >
                        Save
                    </v-btn>
                    <v-btn width="150px" rounded text outlined class="grey--text" @click="close">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Processing
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="cogsDialog" max-width="500px">
            <v-card>
                <v-card-title class="mb-4">Update COGs</v-card-title>
                <v-card-subtitle class="my-2"
                    >Are you sure you want to sync the COGs for the plans:
                    <v-list-item dense v-for="plan in selectedForEditCogs" :key="plan.id">
                        <v-list-item-title v-text="plan.name"></v-list-item-title>
                    </v-list-item>
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        width="150px"
                        text
                        outlined
                        class="grey--text"
                        @click="cogsDialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        width="150px"
                        color="primary"
                        class="white--text"
                        @click="editCogsConfirm"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
    </div>
</template>
<script>
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '../../stores/user';
import { masterDataStore } from '../../stores/masterdata';
import { usePlanStore } from '../../stores/plan';
import { updatePlan } from '@/common/contextapi';
import ConfirmDialog from './ConfirmDialog.vue';
const dayjs = require('dayjs');

export default {
    name: 'DashBoard',
    data: () => ({
        loading: false,
        cogsDialog: false,
        search: '',
        dialogDelete: false,
        defaultItem: {},
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        startDatePickerMenu: false,
        endDatePickerMenu: false,
        cpMenu: false,
        planTagMenu: false,
        basePlanMenu: false,
        dialog: false,
        editedItem: {},
        filters: { name: [], planTagId: [] },
        activeFilters: {},
        rules: {
            required: (value) => !!value || 'Required.',
        },
        marginRnage: [
            { min: 0, max: 0.1, bgcolor: 'red', txtcolor: 'red' },
            { min: 0.1, max: 0.2, bgcolor: 'red', txtcolor: 'red' },
            { min: 0.2, max: 0.3, bgcolor: 'red', txtcolor: 'red' },
        ],
    }),
    async mounted() {
        this.loading = true;
        await this.GetPlanList();
        await this.getFcPlanList();
        // await this.GetFrequencyTableData();
        await this.getExistingFcPlanList();
        // await this.getClashigFcPlanList();
        await this.getClasingPlansList();
        this.loading = false;
    },
    computed: {
        ...mapState(useUserStore, ['userProfile', 'users']),
        ...mapState(usePlanStore, ['plans', 'unsavedChanges', 'draftPlan']),
        ...mapState(masterDataStore, ['referenceData', 'specialRefData']),

        userPlans() {
            return this.plans.filter(
                (p) => p.isPublic || (!p.isPublic && p.createdBy == this.userProfile.id)
            );
        },
        selectedForEditCogs() {
            return this.plans.filter((p) => p.editcogs);
        },
        availableComparisonPlansForCurrentPlans() {
            return this.availableComparisonPlans.filter(
                (p) => p.id != this.editedItem?.id && p.id != '00000000-0000-0000-0000-000000000000'
            );
        },
        availableComparisonPlans() {
            var aps = this.plans.map((p) => ({ id: p.id, name: p.name }));
            aps.push({
                id: '00000000-0000-0000-0000-000000000000',
                name: 'N/A',
            });
            return aps;
        },
        computedstartDateFormatted() {
            return this.formatDate(this.editedItem.startDate);
        },
        computedendDateFormatted() {
            return this.formatDate(this.editedItem.endDate);
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New ' : 'Edit ';
        },
        headers() {
            return [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '120',
                    class: 'primary--text',
                    filter: (value) => {
                        return this.activeFilters && this.activeFilters.name
                            ? this.activeFilters.name.includes(value)
                            : true;
                    },
                },
                {
                    text: 'Category',
                    width: '100',
                    value: 'categoryId',
                    class: 'primary--text',
                },
                {
                    text: 'Plan Tag',
                    width: 140,
                    value: 'planTagId',
                    class: 'primary--text',
                    filter: (value) => {
                        return this.activeFilters && this.activeFilters.planTagId
                            ? this.activeFilters.planTagId.includes(value)
                            : true;
                    },
                    align: 'center',
                },
                {
                    text: 'Unique Id',
                    width: '250',
                    value: 'uniqueId',
                    class: 'primary--text',
                },
                {
                    text: 'Updated',
                    value: 'lastModifiedOn',
                    width: '120',
                    class: 'primary--text',
                    sort: this.dateSortFunc,
                },
                {
                    text: 'Start Date',
                    value: 'startDate',
                    width: '180',
                    class: 'primary--text',
                    sort: this.dateSortFunc,
                    align: 'center',
                },
                {
                    text: 'End Date',
                    value: 'endDate',
                    width: '180',
                    class: 'primary--text',
                    sort: this.dateSortFunc,
                    align: 'center',
                },
                {
                    text: 'Comparison Plan',
                    width: '160',
                    value: 'comparisionPlanId',
                    class: 'primary--text',
                    align: 'center',
                },
                {
                    text: 'Margin',
                    value: 'margin',
                    cellClass: 'font-weight-bold',
                    class: 'primary--text',
                },
                {
                    text: 'Net Revenue',
                    value: 'revenue',
                    class: 'primary--text',
                },
                {
                    text: 'Edit COGS',
                    value: 'editcogs',
                    sortable: false,
                    class: 'primary--text',
                    width: 50,
                },
                {
                    text: 'Public',
                    value: 'isPublic',
                    sortable: false,
                    class: 'primary--text',
                    width: 50,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    class: 'primary--text',
                },
            ];
        },
    },
    methods: {
        ...mapActions(useUserStore, ['LoadAllUsers', 'setSelectedItemObj']),
        ...mapActions(usePlanStore, [
            'GetPlanList',
            'getFcPlanList',
            'GetFrequencyTableData',
            'getExistingFcPlanList',
            'getClashigFcPlanList',
            'getClasingPlansList',
            'getFcTableData',
            'LoadExistingPlan',
            'LoadExistingFcPlan',
            'DeletePlan',
            'UpdatePlanCogs',
        ]),
        async refresh() {
            this.loading = true;
            await this.GetPlanList();
            await this.getFcPlanList();
            // await this.GetFrequencyTableData();
            await this.getExistingFcPlanList();
            // await this.getClashigFcPlanList();
            await this.getClasingPlansList();
            this.loading = false;
        },
        canEditPlan(plan) {
            return plan.createdBy == this.userProfile.id;
        },
        getMargin(plan) {
            let comparisonPlan = this.plans.find((p) => p.id == plan.comparisionPlanId);
            let val = ((plan.revenue - comparisonPlan?.revenue) * 100) / comparisonPlan?.revenue;
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(2);
        },
        async loadToSB(plan) {
            if (this.unsavedChanges.length > 0) {
                if (
                    await this.$refs.confirm.open(
                        'Unsaved Changes',
                        `There are unsaved changes in scenario builder for plan [${this.draftPlan.name}], are you sure you want to discard the changes and load current plan to scenario builder to overwrite?`,
                        {
                            color: 'warning',
                            aggreeTxt: 'Overwrite',
                            cancelTxt: 'Cancel',
                        }
                    )
                ) {
                    this.loading = true;
                    await this.LoadExistingPlan(plan.id);
                    this.loading = false;
                    this.setSelectedItemObj('scenariobuilder');
                }
            } else {
                this.loading = true;
                await this.LoadExistingPlan(plan.id);
                this.loading = false;
                this.setSelectedItemObj('scenariobuilder');
            }
        },
        async updateComparisonPlan(item, planId) {
            this.loading = true;
            item.comparisionPlanId = planId;
            await updatePlan(item);
            await this.GetPlanList();
            this.loading = false;
        },
        async updatePlanItem(item) {
            this.loading = true;
            item.uniqueId = `${
                this.referenceData.categories?.find((d) => d.id == item.categoryId)?.name
            } ${item.name} ${
                this.specialRefData.plantags?.find((d) => d.id == item.planTagId)?.name
            }`;
            await updatePlan(item);
            await this.GetPlanList();
            this.loading = false;
        },
        async updatePlanTag(item, tagId) {
            this.loading = true;
            item.planTagId = tagId;
            await updatePlan(item);
            await this.GetPlanList();
            this.loading = false;
        },
        allowedStartDate(val) {
            return this.editedItem && val
                ? dayjs(val).isBefore(dayjs(new Date('2100-01-01')))
                : true;
        },
        allowedEndDate(val) {
            return this.editedItem && val
                ? dayjs(val).isAfter(dayjs(new Date('2010-01-01')))
                : true;
        },
        dateSortFunc(a, b) {
            return new Date(a).getTime() > new Date(b).getTime() ? 1 : -1;
        },
        formatDate(timeStr) {
            return dayjs(timeStr).format('DD MMM YYYY');
        },
        getMarginTxtColor(margin) {},
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        numberToPercent(x) {
            return (x * 100).toFixed(2);
        },
        init() {},
        initFilters() {
            for (var col in this.filters) {
                this.filters[col] = this.plans
                    .map((d) => {
                        return d[col];
                    })
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
            }
            // TODO restore previous activeFilters before add/remove item
            this.activeFilters = Object.assign({}, this.filters);
            /*if (Object.keys(this.activeFilters).length === 0) this.activeFilters = Object.assign({}, this.filters)
            else {
              setTimeout(() => {
                console.log(this.activeFilters)
                //this.activeFilters = Object.assign({}, this.filters)
              }, 1)
            }*/
        },
        toggleAll(col) {
            this.activeFilters[col] = this.plans
                .map((d) => {
                    return d[col];
                })
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
        },

        clearAll(col) {
            this.activeFilters[col] = [];
        },
        deleteItem(item) {
            this.editedIndex = this.plans.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.loading = true;
            await this.DeletePlan(this.editedItem.id);
            this.closeDelete();
            this.loading = false;
        },

        close() {
            //this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        editItem(item) {
            this.editedIndex = this.plans.indexOf(item);
            this.editedItem = Object.assign({}, item);
            // this.dialog = true
        },

        async save() {
            if (this.editedIndex > -1) {
                //edit
                this.loading = true;
                Object.assign(this.plans[this.editedIndex], this.editedItem);
                console.log(this.editedItem);
                //await updateRefData(this.collectionName.toLocaleLowerCase(), this.editedItem)
                this.loading = false;
            } else {
                //new
                this.loading = true;
                // await createRefData(this.collectionName.toLocaleLowerCase(), this.editedItem)
                //await this.LoadRefData()
                this.loading = false;
            }
            this.close();
        },

        async editCogsConfirm() {
            this.loading = true;
            this.cogsDialog = false;
            await this.UpdatePlanCogs(this.selectedForEditCogs);
            await this.GetPlanList();
            this.loading = false;
        },
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        },
        plans(val) {
            this.initFilters();
            //this.activeFilters = {} // TODO change this
            //this.activeFilters = Object.assign({}, this.filters)
        },
    },
    components: {
        ConfirmDialog: ConfirmDialog,
    },
};
</script>

<style>
.margincell {
    padding: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
}

.marginlv1 {
    height: 100%;
    background: #b9f6ca;
    font-weight: bold;
    font-size: 16px !important;
    color: #1b5e20;
    justify-content: center;
    display: flex;
    align-items: center;
}

.marginlv2 {
    height: 100%;
    background: #ccff90;
    font-weight: bold;
    font-size: 16px !important;
    color: #9ac100;
    justify-content: center;
    display: flex;
    align-items: center;
}

.marginlv3 {
    height: 100%;
    background: #f8bbd0;
    font-weight: bold;
    font-size: 16px !important;
    color: #ff4081;
    justify-content: center;
    display: flex;
    align-items: center;
}

#virtual-scroll-plan-table {
    max-height: calc(100vh - 150px);
    overflow: auto;
}
</style>
