<template>
  <div v-if="viewReportMode">
    <v-btn
      elevation="0"
      class="white--text mr-2"
      color="primary"
      @click="viewReportMode = false"
    >
      <v-icon left>mdi-arrow-left</v-icon>Go Back
    </v-btn>
    <PowerBILoader
      :height="'95%'"
      :reportTitle="'Öutput'"
      :reportUrl="'https://app.powerbi.com/reportEmbed?reportId=6e02a777-4e9d-405f-9a67-2cacf99d9866&autoAuth=true&ctid=1bda8acf-807e-4a1c-b603-a2b6bb566cdb&navContentPaneEnabled=false'"
    ></PowerBILoader>
  </div>
  <v-container v-else fluid style="height: 98vh">
    <v-row v-if="!showComponent.length" justify="center" class="fill-height">
      <v-col  class="ma-auto">
        <div class="d-flex justify-center">
          <v-row >
            <v-col
              v-for="(card, index) in cards"
              :key="index"
              cols="12"
              md="4"
              class="pa-1"

            >
              <v-card class="" @click=" card.onClick? viewReportMode = true : card.show = true">
                
                <v-card-text class="justify-center price-card">
                  <div class="text-body-1 text-no-wrap text-center">{{ card.title }}</div>
                  <div class="d-flex justify-center"><v-img
                    :src="card.icon"
                    max-width="100"
                    max-height="100"
                  ></v-img>
                </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <template v-else>
      <v-btn
      elevation="0"
      class="white--text mr-2"
      color="primary"
      @click="cards.forEach((card) => (card.show = false))"
    >
      <v-icon left>mdi-arrow-left</v-icon>Go Back
    </v-btn>
      <component
        :is="showComponent[0]?.component"
        @switch="onSwitch"
      ></component>
    </template>
  </v-container>
</template>
<script>
import StructuredPricing from "./StructuredPricing.vue";
import CustomerContractPricing from "./CustomerContractPricing.vue";
import PowerBILoader from "./PowerBILoader.vue";
export default {
  name: "CustomerPricing",
  data: () => {
    return {
      viewReportMode: false,
      showStructuredPricing: false,
      cards: [
        {
          title: "Structured Pricing",
          component: StructuredPricing,
          show: false,
          icon: require("@/assets/structuredpricing.png")
        },
        {
          title: "Customer Contract Pricing",
          component: CustomerContractPricing,
          show: false,
          icon: require("@/assets/contractpricing.png")
        },
        {
          title: "Customer Pricing Performance",
          onClick: () => {
            console.log("Customer Pricing Performance")
          },
          show: false,
          icon: require("@/assets/pricingperformance.png")
        },
      ],
    };
  },
  computed: {
    showComponent() {
      return this.cards.filter((card) => card.show);
    },
  },

  components: {
    StructuredPricing,
    CustomerContractPricing,
    PowerBILoader,
  },
  methods: {
    onSwitch(target) {
      this.cards.forEach((card) => (card.show = false));
      this.cards.find((card) => card.title === target).show = true;
    },
  },
};
</script>
<style>
.price-card {
  background-color: #fff;
  cursor: pointer;
  color: #4274ce;

 
}

.price-card >  div {
   color: #4274ce;
  
  }

.price-card:hover {
  background-color: #4274ce;
  color: white;
}

.price-card:hover > div {
  color: white;
}

</style>