import { defineStore } from "pinia";
import { logIn, forgotPassword, resetPassword } from "../common/methods";
import {
    getAllTenants,
    getCurrentUserProfile,
    getAllUsers,
    getCurrentUserPermissions,
    createUser,
    toggleUserStatus,
    createTenant,
    getUserQuota,
    toggleTenantStatus,
    updateTenant,
} from "@/common/contextapi";

import { userPermissions } from "@/common/constants";

export const useUserStore = defineStore({
    id: "user",
    state: () => ({
        token: "",
        refreshToken: "",
        refreshTokenExpiryTime: "",
        userProfile: {},
        tenants: [],
        users: [],
        userQuota: [],
        currentUserPermissions: [],
        tenantid: "root", //default to root
        leftMenuIndex: 0,
        menuItems: [
            {
                name: "dashboard",
                text: "Dashboard",
                component: "Dashboard",
                class: "navContainer",
                style: { background: "#F9FAFB" },
                customicon: require("@/assets/menuicons/home-smile.svg"),
            },
            {
                hasBage: true,
                keepAlive: true,
                name: "scenariobuilder",
                text: "Scenario Builder",
                component: "ScenarioBuilder",
                class: "navContainer",
                style: { background: "#285CB7", color: "#CFE0FF" },
                customicon: require("@/assets/menuicons/scenario-builder.svg"),
            },
            {
                name: "forecast",
                isGroupHeader: true,
                component: "Forecast_Main",
                text: "Forecast Planner",
                groupClass: "navGroupContainer forecast_container",
                customicon: require("@/assets/menuicons/forecast.svg"),
                style: {},
                items: [
                    {
                        name: "forecast_summary",
                        text: "Plans Summary",
                        component: "Forecast_Summary",
                        style: { background: "white" },
                    },
                    {
                        name: "forecast_planner",
                        text: "Forecast Planner",
                        component: "Forecast_Main",
                        style: { background: "white" },
                    },
                    {
                        name: "forecast_output",
                        text: "View Output",
                        component: "Forecast_Output",
                        style: { background: "white" },
                    },
                ],
            },
            {
                name: "customerpricing",
                text: "Customer Pricing",
                isGroupHeader: true,
                customicon: require("@/assets/menuicons/customer-pricing.svg"),
                style: {},
                groupClass: "navGroupContainer pricing_container",
                items: [
                    {
                        name: "structuredPricing",
                        text: "Structured Pricing",
                        customicon: require("@/assets/structuredpricing.png"),
                        component: "StructuredPricing",
                        style: { background: "white" },
                    },
                    {
                        name: "contractpricing",
                        text: "Contract Pricing",
                        customicon: require("@/assets/contractpricing.png"),
                        component: "CustomerContractPricing",
                        style: { background: "white" },
                    },
                    {
                        name: "pricingperformance",
                        text: "Pricing Performance",
                        customicon: require("@/assets/pricingperformance.png"),
                        component: "CustomerPricingPerformance",
                        style: { background: "white" },
                    },
                ],
            },
            {
                name: "visualisation",
                text: "Visualisation",
                class: "navContainer",
                customicon: require("@/assets/menuicons/visualisation.svg"),
                style: { background: "#667085", color: "#FCFCFD" },
            },
            {
                name: "packArchi",
                text: "Pack Architecture",
                class: "navContainer",
                customicon: require("@/assets/menuicons/pack-architecture.svg"),
                style: { background: "#F79009", color: "white" },
                //indev: true,
            },
            {
                name: "priceIncrease",
                text: "Price Solver",
                class: "navContainer",
                customicon: require("@/assets/menuicons/price-increase.svg"),
                style: { background: "#15B79E", color: "white" },
                //indev: true,
                component: "PowerBILoader",
                props: {
                    reportTitle: "Test",
                    reportUrl:
                        "https://app.powerbi.com/reportEmbed?reportId=99b3a69f-8f51-482c-bb77-6cc75f4b76dc&autoAuth=true&ctid=1bda8acf-807e-4a1c-b603-a2b6bb566cdb&navContentPaneEnabled=false",
                },
            },
            {
                name: "promobooster",
                text: "Promo Booster",
                class: "navContainer",
                // component: "PromoBoosterTable",
                style: { background: "#F04438", color: "#FEF3F2" },
                customicon: require("@/assets/menuicons/promobooster.svg"),
            },
            {
                name: "masterdata",
                isGroupHeader: true,
                text: "Master Data",
                groupClass: "navGroupContainer master_data_container",
                requiredPermission: userPermissions.UPDATE_USER,
                customicon: require("@/assets/menuicons/masterdata.svg"),
                items: [
                    {
                        name: "products",
                        text: "Product Data",
                        component: "ProductData",
                        customicon: require("@/assets/menuicons/productdata.svg"),
                    },
                    {
                        name: "refdata",
                        text: "Customer Data",
                        component: "RefDataList",
                        requiredPermission: userPermissions.UPDATE_REFDATA,
                        customicon: require("@/assets/menuicons/customer-data.svg"),
                    },
                    {
                        name: "promodata",
                        text: "Promo Data",
                        customicon: require("@/assets/menuicons/promodata.svg"),
                        requiredPermission: userPermissions.UPDATE_REFDATA,
                    },
                    {
                        name: "bakcend",
                        text: "Backend",
                        customicon: require("@/assets/menuicons/backend.svg"),
                        requiredPermission: userPermissions.UPDATE_REFDATA,
                    },
                    {
                        name: "plantags",
                        text: "Plan Tags",
                        customicon: require("@/assets/menuicons/plantags.svg"),
                        requiredPermission: userPermissions.UPDATE_REFDATA,
                    },
                    {
                        name: "elasticity",
                        text: "Elasticity",
                        customicon: require("@/assets/menuicons/elasticity.svg"),
                        requiredPermission: userPermissions.UPDATE_REFDATA,
                    },
                ],
            },
            {
                name: "userlist",
                text: "Users",
                icon: "mdi-account-multiple",
                class: "navContainer",
                requiredPermission: userPermissions.UPDATE_USER,
                component: "UserList",
            },
        ],
        selectedItemObj: {},
    }),
    getters: {
        getCurrentUserToken: (state) => state.token,
    },
    actions: {
        setLeftMenuIndex(index) {
            this.leftMenuIndex = index;
        },
        setSelectedItemObj(item) {
            switch (typeof item) {
                case "string":
                    this.selectedItemObj = this.menuItems.find(
                        (i) => i.name === item
                    );
                    break;
                default:
                    this.selectedItemObj = item;
            }
        },
        setTenantId() {
            this.tenantid = "testclient";
            // if (window.location.host.startsWith('localhost')) {
            //     this.tenantid = 'testclient';
            //     //this.tenantid =  window.location.search &&  window.location.search.startsWith('?tenantid=')? window.location.search.substring(window.location.search.indexOf('=') + 1) : 'root'
            // }
            // // not local host
            // else {
            //     this.tenantid = window?.location?.host?.substring(
            //         0,
            //         window.location.host.indexOf('.')
            //     );
            // }
        },
        logoutUser() {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("refreshToken");
            window.localStorage.removeItem("refreshTokenExpiryTime");
            this.token = "";
            this.refreshToken = "";
            this.refreshTokenExpiryTime = "";
        },
        async Login(userName, password) {
            try {
                const res = await logIn(userName, password, this.tenantid);
                if (res.status === 200) {
                    this.token = res.token;
                    this.refreshToken = res.refreshToken;
                    this.refreshTokenExpiryTime = res.refreshTokenExpiryTime;
                    return res;
                }
            } catch (err) {
                return err.response;
            }
        },

        async ForgotPassword(userName) {
            try {
                const res = await forgotPassword(userName, this.tenantid);
                return res;
            } catch (err) {
                return err.response;
            }
        },

        async resetPassword(email, password, token) {
            try {
                const res = await resetPassword(
                    email,
                    password,
                    token,
                    this.tenantid
                );
                return res;
            } catch (err) {
                return false;
            }
        },
        LoadCurrentUserProfile() {
            getCurrentUserProfile().then((res) => {
                this.userProfile = res;
                this.tenantid = res.tenantId;
            });
        },
        LoadAllTenants() {
            getAllTenants().then((res) => {
                this.tenants = res;
            });
        },
        LoadAllUsers() {
            getAllUsers().then((res) => {
                this.users = res;
            });
            getUserQuota().then((res) => {
                this.userQuota = res.data;
            });
        },
        LoadCurrentUserPermission() {
            getCurrentUserPermissions().then((res) => {
                this.currentUserPermissions = res;
            });
        },
        async CreateUser(userObj) {
            try {
                return await createUser(userObj);
            } catch (err) {
                return err.response;
            }
        },
        async ToggleUserStatus(userObj) {
            try {
                return await toggleUserStatus(userObj);
            } catch (err) {
                return err.response;
            }
        },
        async CreateTenant(tenantObj) {
            try {
                return await createTenant(tenantObj);
            } catch (err) {
                return err.response;
            }
        },
        async GetUserQuota() {
            try {
                var data = await getUserQuota(this.tenantid);
                this.userQuota = data.data;
            } catch (err) {
                return err.response;
            }
        },
        async UpdateTenant(updateRequest) {
            try {
                await updateTenant(updateRequest);
            } catch (err) {
                return err.response;
            }
        },
        async ToggleTenantStatus(toggleRequest, activate) {
            try {
                await toggleTenantStatus(toggleRequest, activate);
            } catch (err) {
                return err.response;
            }
        },
    },
});
