<template>
    <div>
    <div class="pa-2 font-weight-bold text-h5 blue--text text--darken-4">Promo Booster
    </div>
    <v-card>
         <v-card-title>
                <div class="blue--text text--darken-4">Products</div>
                <v-spacer></v-spacer>
                <v-btn class="white--text" color="primary" @click="newDialog = true">
                    <v-icon left>mdi-plus</v-icon>New Promo Booster
                </v-btn>
                <!--<v-btn rounded small class="white--text ml-5" color="grey">Edit Cogs<v-icon small class="ml-2">
                        mdi-currency-usd</v-icon>
                </v-btn>-->
                <div class="ml-5">
                    <v-text-field dense v-model="search" outlined prepend-inner-icon="mdi-magnify" label="Search"
                        single-line hide-details>
                    </v-text-field>
                </div>
            </v-card-title>
            <v-card-text>
            <v-data-table hide-default-footer id="virtual-scroll-table" :headers="headers"
                :items="promoBoosterJobs" :search="search" sort-by="category" class="pb-10">
                </v-data-table>
        </v-card-text>
    </v-card>
    <v-dialog v-model="newDialog" persistent max-width="90%">
        <PromoBooster></PromoBooster>
    </v-dialog>
</div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '../../stores/user'
import { masterDataStore } from '../../stores/masterdata'
import PromoBooster from './PromoBooster.vue'

export default {
    name: 'PromoBoosterTable',
    components: {
        PromoBooster: PromoBooster
    },
    data: () => ({
        newDialog: false,
        search: '',	

        headers: [
        { text: 'Category', value: 'categoryId', cellClass: 'categoryCell', class: "primary--text" },
                { text: 'Segment', value: 'segmentId', cellClass: 'segmentCell', class: "primary--text" },
        ]
    }),
    async mounted(){
        await this.GetAllCustomers();
    },
    computed: {
        ...mapState(useUserStore, ['promoBoosterJobs']),
    },
    methods:{
        ...mapActions(masterDataStore, ['LoadRefData', 'GetAllCustomers', 'GetAllPlanTags', 'GetAllPromoTypes','GetAllElasticities']),
    }
}
</script>