<template>
  <div class="overflow-y-auto fill-height overflow-x-hidden" style="height: 98vh">
    <v-row ref="row1" class="d-flex px-4 py-2 mt-1">
      <div class="text-h5 font-weight-bold primary--text">
        Customer Price Request
      </div>
      <v-spacer></v-spacer>
      <v-btn elevation="0" class="mr-2" color="white" @click="onSwitch">
        <v-img class="mr-1" :src="require('@/assets/coin-swap.svg')" contain>
        </v-img
        >Switch to Structured Customer Pricing
      </v-btn>
      <v-btn elevation="0" class="white--text mr-2" color="primary">
        <v-img class="mr-1" :src="require('@/assets/send.svg')" contain> </v-img
        >Submit
      </v-btn>
      <v-btn elevation="0" class="primary--text" color="#E4EDFF">
        <v-icon color="primary" left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
    <v-row class="px-4 py-2">
      <v-card class="overflow-y-auto rounded-lg" width="100%">
        <v-card-title>
          <div class="text-h6 font-weight-medium primary--text">
            Contract Pricing Customer
          </div>
        </v-card-title>
        <v-card-text>
          <v-row v-if="firstRowInputs">
            <v-col
              v-for="(input, index) in firstRowInputs.concat(secondRowInputs)"
              cols="12"
              md="2"
              class="pa-2"
              :key="`cp-input-row1-${index}`"
            >
              <div>
                {{ input.text }}
              </div>
              <v-text-field
                v-if="input.type === 'text'"
                :placeholder="input.placeHolder"
                persistent-placeholder
                outlined
                dense
                single-line
                :label="input.text"
                :type="input.type"
                :items="input.items"
                :background-color="input.bgColor || ''"
              ></v-text-field>
              <v-select
                v-if="input.type === 'select'"
                :placeholder="input.placeHolder"
                persistent-placeholder
                :background-color="input.bgColor || ''"
                outlined
                dense
                single-line
                :label="input.text"
                :type="input.type"
                :items="input.items"
              ></v-select>
              <v-autocomplete
                v-if="input.type === 'autoComplete'"
                :placeholder="input.placeHolder"
                persistent-placeholder
                :background-color="input.bgColor || ''"
                outlined
                dense
                single-line
                :label="input.text"
                :type="input.type"
                :items="input.items"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="px-4 py-2">
      <v-card class="overflow-y-auto rounded-lg" width="100%">
        <v-card-title>
          <v-row>
            <v-col cols="7">
              <div class="font-weight-medium primary--text text-caption">
                Recommended Investment
              </div>
            </v-col>
            <v-col cols="1">
              <div class="font-weight-medium primary--text text-caption">
                Recommendation
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="py-4">
            <v-col cols="5"> Minimum </v-col>
            <v-col cols="2"> % of list price average </v-col>
            <v-col cols="1">
              <v-chip color="#D1FADF" class="px-4">8%</v-chip>
            </v-col>
          </v-row>
          <v-row class="py-4">
            <v-col cols="5"> Maximum </v-col>
            <v-col cols="2"> % of list price average </v-col>
            <v-col cols="1">
              <v-chip color="#D1FADF" class="px-4">12%</v-chip>
            </v-col>
          </v-row>
          <v-row class="py-4" style="background-color: #eaecf0">
            <v-col cols="5"> TOTAL </v-col>
            <v-col cols="2"> </v-col>
            <v-col cols="1">
              <v-chip color="#D1FADF" class="px-4">10%</v-chip>
            </v-col>
            <v-col cols="1"> Within limit </v-col>
          </v-row>
          <v-row class="py-4">
            <v-col cols="3"> Approval </v-col>
            <v-col cols="2"> Tier 1 </v-col>
            <v-col cols="2"> Key Account Approval </v-col>
            <v-col cols="3">
              <v-btn
                elevation="0"
                class="white--text mr-2"
                color="primary"
                block
              >
                <v-img
                  class="mr-4"
                  :src="require('@/assets/send.svg')"
                  contain
                  max-height="20"
                  max-width="20"
                >
                </v-img
                >Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="px-4 py-2">
      <v-card class="overflow-y-auto rounded-lg" width="100%">
        <v-data-table
          :headers="headers"
          :items-per-page="30"
          hide-default-footer
          fixed-header
          class="products-table"
          :items="tableItems"
          sort-by="category"
          @click:row="() => {}"
        >
          <template v-slot:[`item.amountPercentage`]="{ item }">
            <v-text-field
              v-model="item.amountPercentage"
              outlined
              dense
              single-line
              hide-details
              suffix="%"
            ></v-text-field>
          </template>
          <template v-slot:[`item.criteria`]="{ item }">
            <v-text-field
              v-model="item.criteria"
              outlined
              dense
              single-line
              hide-details
              color="primary"
              suffix="%"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
    <CustomerPricingProductsTable v-if="false"/>
  </div>
</template>
<script>
import { variables } from "@/common/constants";
import { masterDataStore } from "../../stores/masterdata";
import { mapActions, mapState } from "pinia";
import { formatNumber } from "../../common/methods";
import { tableItems } from "@/common/mockdata/contractpricing";
import CustomerPricingProductsTable from "./CustomerPricingProductsTable.vue";
export default {
  name: "CustomerContractPricing",
  components: {
    CustomerPricingProductsTable,
  },
  data: () => ({
    headers: [
      {
        text: "Terms",
        value: "terms",
        class: "primary--text",
        cellClass: "tbcell",
        width: "20%",
        sortable: false,
      },
      {
        text: "% Amount",
        value: "amountPercentage",
        class: "primary--text",
        cellClass: "tbcell",
        width: "10%",
        sortable: false,
      },
      {
        text: "Criteria",
        value: "criteria",
        class: "primary--text",
        cellClass: "tbcell",
        width: "10%",
        sortable: false,
      },
      {
        text: "Gold",
        value: "gold",
        class: "primary--text",
        cellClass: "tbcell",
        width: "20%",
        sortable: false,
      },
      {
        text: "Silver",
        value: "silver",
        class: "primary--text",
        cellClass: "tbcell",
        width: "20%",
        sortable: false,
      },
      {
        text: "Bronze",
        value: "bronze",
        class: "primary--text",
        cellClass: "tbcell",
        width: "20%",
        sortable: false,
      },
    ],
    tableItems: tableItems,
  }),
  methods: {
    onSwitch() {
     // this.$emit("switch", "Structured Pricing");
     this.$emit("onSwitchMenu", "structuredPricing");
    },
  },
  computed: {
    ...mapState(masterDataStore, ["referenceData", "specialRefData"]),
    footerProps() {
      return {
        "items-per-page-options": [15, 30, 50, -1],
        showFirstLastPage: true,
      };
    },
    firstRowInputs() {
      return [
        {
          text: "Location",
          placeHolder: "Postcode",
          type: "text",
          bgColor: variables.colors.primary50,
        },
        {
          text: "Size Of Customer",
          placeHolder: "...in Litres",
          type: "text",
          bgColor: variables.colors.primary50,
        },

        {
          text: "Growth Prospects",
          type: "select",
          placeHolder: "Medium",
          bgColor: variables.colors.primary50,
          items: [
            {
              text: "High",
              value: "high",
            },
            {
              text: "Medium",
              value: "medium",
            },
            {
              text: "Low",
              value: "low",
            },
          ],
        },
        {
          text: "Customer Relationship",
          type: "select",
          bgColor: variables.colors.primary50,
          placeHolder: "Select a Customer",
          items: this.specialRefData?.customers?.map((customer) => {
            return {
              text: customer.name,
              value: customer.id,
            };
          }),
        },
        {
          text: "Delivery & Order Sched.",
          bgColor: variables.colors.primary50,
          type: "select",
          placeHolder: "Select a Customer",
          items: this.specialRefData?.customers?.map((customer) => {
            return {
              text: customer.name,
              value: customer.id,
            };
          }),
        },
        {
          text: "Approval",
          type: "select",
          bgColor: variables.colors.primary50,
          placeHolder: "Select a Customer",
          items: this.specialRefData?.customers?.map((customer) => {
            return {
              text: customer.name,
              value: customer.id,
            };
          }),
        },
      ];
    },
    secondRowInputs() {
      return [
        {
          text: "Customer Zone",
          type: "text",
          placeHolder: "Customer Zone",
        },
        {
          text: "Customer Classification",
          type: "text",
          placeHolder: "Customer Classification",
        },
        {
          text: "Strategic Importance",
          type: "select",
          placeHolder: "Low",
          bgColor: variables.colors.primary50,
          items: [
            {
              text: "High",
              value: "high",
            },
            {
              text: "Medium",
              value: "medium",
            },
            {
              text: "Low",
              value: "low",
            },
          ],
        } /*
        {
          text: "Core Product Ranging",
          type: "select",
          bgColor: variables.colors.primary50,
          placeHolder: "80%",
          items: [
            {
              text: "80%",
              value: "80",
            },
            {
              text: "70%",
              value: "70",
            },
            {
              text: "60%",
              value: "60",
            },
          ],
        },

        {
          text: "Additional Ranging",
          type: "select",
          placeHolder: "Select a plan",
          items: [],
        },*/,
      ];
    },
  },
};
</script>