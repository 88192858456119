<template>
    <div>
        <div class="pa-2 font-weight-bold text-h5 blue--text text--darken-4">Master Data Management
        </div>
        <v-card elevation="12" class="pa-2">
            <v-card-title>

                <v-btn class="white--text" color="primary" @click="dialog = true">
                    <v-icon small class="mr-2">mdi-plus</v-icon>NEW
                    {{ collectionName }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" @click="refresh()">
                    <v-icon>
                        mdi-refresh
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-tabs v-model="tab" class="pl-4" center-active>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab v-for="item in items" :key="item.tab">
                    {{ item.tab }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items" :key="item.tab">
                    <v-card flat>

                        <v-card-text>
                            <v-data-table :loading="loading" :headers="headers" :items="item.content" sort-by="name"
                                class="elevation-1">
                                <template v-slot:[`item.actions`]="{ item }">

                                    <v-icon class="mr-4" @click="editItem(item)">
                                        mdi-square-edit-outline
                                    </v-icon>
                                    <v-icon color="#B42318" @click="deleteItem(item)">
                                        mdi-trash-can-outline
                                    </v-icon>

                                </template>
                                <template v-slot:[`item.colorCode`]="{ item }">
                                    <v-chip v-if="item.colorCode" :color="item.colorCode" dark small
                                        class="white--text">
                                        {{ item.colorCode.toUpperCase() }}
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.rspRate`]="{ item }">
                                    <v-chip small color="primary">
                                        {{ item.rspRate }} %
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.productId`]="{ item }">
                                   {{products?.find(p=>p.id == item.productId)?.name}}
                                </template>
                                <template v-slot:[`item.customerId`]="{ item }">
                                    <v-chip
                                        :color="specialRefData.customers?.find(d => d.id == item.customerId)?.colorCode"
                                        text-color="white">
                                        {{ specialRefData.customers?.find(d => d.id == item.customerId)?.name }}
                                 
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <v-dialog v-model="dialog" persistent max-width="400px">
            <v-card>
                <v-card-title class="primary white--text mb-4">
                    <span class="text-h5">{{ formTitle }} {{ collectionName }}</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="newForm" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-text-field v-if="editedIndex > -1" dense disabled outlined v-model="editedItem.id"
                                    label="Id">
                                </v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field v-if="collectionName != 'ELASTICITIES'" :rules="[rules.required]" dense
                                    outlined v-model="editedItem.name" label="Name">
                                </v-text-field>
                            </v-row>
                            <v-row v-if="collectionName == 'CUSTOMERS'">
                                <v-text-field :rules="[rules.required]" dense outlined v-model="editedItem.channel"
                                    label="Channel">
                                </v-text-field>
                            </v-row>
                            <v-row v-if="collectionName == 'CUSTOMERS'">
                                <v-text-field :rules="[rules.required]" dense outlined v-model="editedItem.region"
                                    label="Region">
                                </v-text-field>
                            </v-row>
                            <v-row v-if="collectionName == 'PROMOTYPES'">
                                <v-text-field :rules="[rules.required, rules.percent]" dense outlined
                                    v-model="editedItem.rspRate" label="% of RSP">
                                </v-text-field>
                            </v-row>
                            <v-row v-if="collectionName == 'CUSTOMERS' || collectionName == 'PLANTAGS'">
                                <v-text-field v-model="color" hide-details outlined readonly dense label="Color"
                                    @click="colorCodeMenu = true">
                                    <template v-slot:append>
                                        <v-menu v-model="colorCodeMenu" top nudge-right="50" mode="hexa"
                                            :close-on-content-click="false">
                                            <template v-slot:activator="{ on }">
                                                <div :style="swatchStyle" v-on="on"></div>
                                            </template>
                                            <v-card>
                                                <v-card-text class="pa-0">
                                                    <v-color-picker v-model="color" show-swatches
                                                        :swatches="swatches" />
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </v-text-field>

                            </v-row>
                            <template v-if="collectionName == 'ELASTICITIES'">
                                <v-row>
                                <v-autocomplete :rules="[rules.required]" v-model="editedItem.productId"
                                    :items="products" item-text="name" item-value="id" outlined dense
                                    label="Product">
                                </v-autocomplete>
                            </v-row>
                            <v-row>
                                <v-autocomplete :rules="[rules.required]" v-model="editedItem.customerId"
                                    :items="specialRefData.customers" item-text="name" item-value="id" outlined dense
                                    label="Customer">
                                </v-autocomplete>
                            </v-row>
                            <v-row>
                                <v-text-field :rules="[rules.required]" dense outlined
                                    v-model="editedItem.elasticityValue" label="Elasticity">
                                </v-text-field>
                            </v-row>
                            </template>
                          
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save" :disabled="collectionName =='ELASTICITIES'? !editedItem.productId:!editedItem.name">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="primary white--text mb-4">Deleting {{ collectionName }}: {{ editedItem.name }}
                </v-card-title>
                <v-card-subtitle class="my-2">Are you sure you want to delete the data:
                    <strong>{{ editedItem.name }}</strong>?
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Processing
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>

</template>



<script>
import { mapActions, mapState } from 'pinia';
import { masterDataStore } from '../../stores/masterdata'
import { updateRefData, createRefData, deleteRefData } from '@/common/contextapi'

export default {
    name: 'RefDataList',
    data() {
        return {
            dialog: false,
            color: '#000',
            mask: '!#XXXXXXXX',
            swatches: [
                ['#FF0000', '#AA0000', '#550000'],
                ['#FFFF00', '#AAAA00', '#555500'],
                ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'],
                ['#0000FF', '#0000AA', '#000055'],
            ],
            colorCodeMenu: false,
            dialogDelete: false,
            tab: 0,
            loading: false,
            editedIndex: -1,
            editedItem: {

            },
            rules: {
                required: value => !!value || 'Required.',
                percent: value => (!isNaN(value) && 0 <= value <= 100) || 'Valid value required between 0 to 100'
            },


        }
    },
    methods: {
        ...mapActions(masterDataStore, ['LoadRefData', 'GetAllCustomers', 'GetAllPlanTags', 'GetAllPromoTypes','GetAllElasticities']),
        numberToPercent(x) {
            return (x * 100).toFixed(2)
        },
        async refresh() {
            this.loading = true
            await this.LoadRefData()
            await this.GetAllCustomers()
            await this.GetAllPlanTags()
            await this.GetAllPromoTypes()
            await this.GetAllElasticities()
            this.loading = false
        },
        editItem(item) {
            this.editedIndex = this.items[this.tab].content.indexOf(item)
            this.editedItem = Object.assign({}, item)
            if (this.editedItem.colorCode) {
                this.color = this.editedItem.colorCode
            } else {
                this.color = '#000'
            }
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.items[this.tab].content.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            this.loading = true
            await deleteRefData(this.collectionName.toLocaleLowerCase(), this.editedItem)
            this.loading = false
            this.closeDelete()
            await this.refresh()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                this.$refs.newForm.resetValidation()

            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            if (!this.$refs.newForm.validate()) {
                return
            }
            if (this.collectionName === 'CUSTOMERS' || this.collectionName === 'PLANTAGS') {
                this.editedItem.colorCode = this.color
            }
            if (this.editedIndex > -1) {
                //edit
                this.loading = true
                Object.assign(this.items[this.tab].content[this.editedIndex], this.editedItem)
                await updateRefData(this.collectionName.toLocaleLowerCase(), this.editedItem)
                this.loading = false
            } else {
                //new
                this.loading = true
                await createRefData(this.collectionName.toLocaleLowerCase(), this.editedItem)
                this.loading = false

            }
            this.close()
            await this.refresh()
        },
    },
    async mounted() {
        await this.refresh()
    },
    computed: {
        ...mapState(masterDataStore, ['referenceData', 'specialRefData','products']),
        swatchStyle() {
            const { color, menu } = this
            return {
                backgroundColor: color,
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                borderRadius: menu ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out'
            }
        },
        headers() {
            var h = [
                {
                    text: 'Id',
                    width: 300,
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    class: "primary--text"
                },


            ]
            if (this.collectionName != 'ELASTICITIES') {
                h.push({ text: 'Name', value: 'name', class: "primary--text" },)
            }
            if (this.collectionName === 'ELASTICITIES') {
                h = h.concat([
                    { text: 'Customer', value: 'customerId', class: "primary--text" },
                    { text: 'Product', value: 'productId', class: "primary--text" },
                    { text: 'Elasticity', value: 'elasticityValue', class: "primary--text" },
                ])
            }
            if (this.collectionName === 'CUSTOMERS') {
                h.push({
                    text: 'Channel',
                    align: 'start',
                    sortable: false,
                    value: 'channel',
                    class: "primary--text"
                })
                h.push({
                    text: 'Region',
                    align: 'start',
                    sortable: false,
                    value: 'region',
                    class: "primary--text"
                })
            }
            if (this.collectionName === 'CUSTOMERS' || this.collectionName === 'PLANTAGS') {
                h.push({
                    text: 'Color',
                    align: 'start',
                    sortable: false,
                    value: 'colorCode',
                    class: "primary--text"
                })
            }
            if (this.collectionName === 'PROMOTYPES') {
                h.push({
                    text: '% of RSP',
                    align: 'start',
                    sortable: false,
                    value: 'rspRate',
                    class: "primary--text"
                })
            }
            h.push({ align: 'end', text: '', value: 'actions', sortable: false, class: "primary--text" })
            return h
        },
        items() {
            var ref = this.referenceData
            var specialRef = this.specialRefData
            var newItems = []
            for (const [key, value] of Object.entries(specialRef)) {
                newItems.push({
                    tab: key.toUpperCase(),
                    content: value
                })
            }


            for (const [key, value] of Object.entries(ref)) {
                newItems.push({
                    tab: key.toUpperCase(),
                    content: value
                })
            }


            return newItems

        },
        collectionName() {
            return this.items[this.tab].tab
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New ' : 'Edit '
        },

    }
}
</script>