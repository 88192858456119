const planProductHeaders = [
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Channel',
        value: 'channel',
        groupTitle: 'Customer',
        basicFilter: true,
        width:140

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Customer',
        value: 'customer',
        groupTitle: 'Customer',
        basicFilter: true,
        isDefault:true

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Region',
        value: 'region',
        groupTitle: 'Customer',
        basicFilter: true,
        width:140

    },
    //product
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Category',
        value: 'category',
        groupTitle: 'Product',
        basicFilter: true

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Segment',
        value: 'segment',
        groupTitle: 'Product',
        width: 140,
        basicFilter: true

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Brand',
        value: 'brand',
        groupTitle: 'Product',
        isDefault: true,
        basicFilter: true,
        width:120
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Product',
        value: 'product',
        groupTitle: 'Product',
        isDefault: true,
        width: 140,
        filterable: true,
        basicFilter: true
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Product Code',
        value: 'productCode',
        groupTitle: 'Product',
        isDefault: true,
        filterable: true,
        basicFilter: true,
        width:160
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Size',
        value: 'size',
        groupTitle: 'Product',
        isDefault: true,
        basicFilter: true,
        width: 110
    },

    //product other
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Nielsen Segment',
        value: 'nielsenSegment',
        groupTitle: 'ProductOther',
        width: 140,
        basicFilter: true

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Bundle',
        value: 'bundle',
        groupTitle: 'ProductOther',
        width: 150

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Tier',
        value: 'tier',
        groupTitle: 'ProductOther',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Measure',
        value: 'measure',
        groupTitle: 'ProductOther',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Measure Amount',
        value: 'measureAmount',
        groupTitle: 'ProductOther',

    },

    //promo details
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Strategy',
        value: 'strategy',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 150,
        basicFilter: true
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Promo Mechanics',
        value: 'promoMechanic',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 170,
        basicFilter: true
    },
    {
        cellClass: 'tbcell ',
        class: "primary--text",
        align: 'center',
        text: 'RSP',
        value: 'rsp',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 100,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Promo Type',
        value: 'promoType',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 100
    },
    {
        cellClass: 'tbcell ',
        class: "primary--text",
        align: 'center',
        text: 'Frequency',
        value: 'frequency',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 120,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Final Vol per Week',
        value: 'volPerWeek',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 120,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Estimated Vol Per Week',
        value: 'estimatedVolPerWeek',
        groupTitle: 'PromoDetails',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = item.baseUnit * Math.pow((1-item.promoDiscount/100), -3.5)
            item.estimatedVolPerWeek = isNaN(val) ? 0 : val.toFixed(0)
            return item.estimatedVolPerWeek

        },
        hasDependency: true,
        calLvl:2
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Comments',
        value: 'comments',
        groupTitle: 'PromoDetails',
        isDefault: true,
        width: 200,
        defaultValue:''
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Multibuy Redemption %',
        value: 'multibuyRedemption',
        groupTitle: 'PromoDetails',
        isDefault: true,
        defaultValue: 0
    },

    //execution
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Catalogue',
        value: 'catalogue',
        groupTitle: 'Execution',
        isDefault: true,
        sortable:false,
        defaultValue: false
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Display',
        value: 'display',
        groupTitle: 'Execution',
        isDefault: true,
        sortable:false,
        defaultValue: false
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Start Date',
        value: 'startDate',
        groupTitle: 'Execution',
        isDefault: true
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'End Date',
        value: 'endDate',
        groupTitle: 'Execution',
        isDefault: true
    },

    //Funding
    {
        cellClass: 'editable tbcell',
        class: "primary--text",
        align: 'center',
        text: 'List Price',
        value: 'listPrice',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
        width: 100

    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'COGs Per Unit',
        value: 'coGsPerUnit',
        groupTitle: 'Funding',
        isDefault: true,
        width: 100,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Off Invoice Discount',
        value: 'offInvoiceDiscount',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
        width: 100
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Deferred Deal',
        value: 'deferredDeal',
        groupTitle: 'Funding',
        isDefault: true,
        width: 100,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Promo Deal',
        value: 'promoDeal',
        groupTitle: 'Funding',
        isDefault: true,
        width: 100,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Fixed Amount(Per Week)',
        value: 'fixedAmtPerWeek',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Investment 2',
        value: 'investment2',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Terms %',
        value: 'termsPercent',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
        width: 120

    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'GST/VAT',
        value: 'gSTVAT',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
        width: 100
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Terms $',
        value: 'terms',
        groupTitle: 'Funding',
        defaultValue: 0,
        formula(item){
            let val =  (item.listPrice - item.offInvoiceDiscount) * item.termsPercent/100
            item.terms = isNaN(val)? 0 : val.toFixed(2)
            return item.terms
        },
        calLvl: 1
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'TI Per Unit',
        value: 'tiPerUnit',
        groupTitle: 'Funding',
        defaultValue: 0,
        formula(item){
            let val = (item.listPrice - item.netLandedPrice)
            item.tiPerUnit =  isNaN(val)? 0 : val.toFixed(2)
            return item.tiPerUnit
        },
        calLvl: 2

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Net Landed Price',
        value: 'netLandedPrice',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = item.promoMechanic == "Multibuy" ? (item.listPrice - item.offInvoiceDiscount - item.deferredDeal - (item.promoDeal * item.multibuyRedemption/100 ) - item.terms) :
            (item.listPrice - item.offInvoiceDiscount - item.deferredDeal - item.promoDeal - item.terms)
            item.netLandedPrice = isNaN(val) ? 0: val.toFixed(2)
            return item.netLandedPrice
        },
        calLvl: 1
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'GM Per Unit',
        value: 'gmPerUnit',
        groupTitle: 'Funding',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = (item.netLandedPrice  - item.coGsPerUnit).toFixed(2)
            item.gmPerUnit = val
            return item.gmPerUnit
        },
        calLvl: 2
    },

    //Base Details
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Base Units',
        value: 'baseUnit',
        groupTitle: 'BaseDetails',
        isDefault: true,
        defaultValue: 0,
        width: 100
    },
    {
        cellClass: 'tbcell editable',
        class: "primary--text",
        align: 'center',
        text: 'Base RSP',
        value: 'baseRSP',
        groupTitle: 'BaseDetails',
        isDefault: true,
        width: 100,
        defaultValue: 0,
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Promo Discount',
        value: 'promoDiscount',
        groupTitle: 'BaseDetails',
        defaultValue: 0,
        formula(item){
            let val = 1 - (item.rsp /item.baseRSP)
            item.promoDiscount = isNaN(val) ? 0 : (val * 100).toFixed(0)
            return item.promoDiscount
        },
        calLvl:1
    },

    //financials
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Total Units',
        value: 'totalUnits',
        groupTitle: 'Financials',
        isDefault: true,
        formula(item) {
            let val =isNaN(item.volPerWeek * item.frequency) ? 0 : item.volPerWeek * item.frequency
            item.totalUnits = val
            return item.totalUnits
        },
        calLvl: 1
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'GSV',
        value: 'gsv',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.totalUnits * item.listPrice).toFixed(0)
            item.gsv =  isNaN(val)? 0: val
             return item.gsv
        },
        calLvl: 2

    },

    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Trade Investment',
        value: 'tradeInvestment',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.gsv - item.netRevenue).toFixed(0)
            item.tradeInvestment = isNaN(val)? 0: val
            return item.tradeInvestment
        },
        calLvl: 3

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Net Revenue',
        value: 'netRevenue',
        groupTitle: 'Financials',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val =  (item.totalUnits * item.netLandedPrice).toFixed(0)   
            item.netRevenue = isNaN(val)? 0: val
            return item.netRevenue
        },
        calLvl: 2

    },

    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'COGs',
        value: 'cogs',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.totalUnits * item.coGsPerUnit).toFixed(0)
            item.cogs = isNaN(val)? 0: val
            return item.cogs
        },
        calLvl: 2

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Gross Margin',
        value: 'grossMargin',
        groupTitle: 'Financials',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = (item.netRevenue - item.cogs).toFixed(0)
            item.grossMargin = isNaN(val) ? 0: val
            return item.grossMargin
        },
        calLvl: 3
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'RSV',
        value: 'rsv',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.totalUnits * item.rsp).toFixed(0)
            item.rsv = isNaN(val) ? 0: val
            return item.rsv
        },
        calLvl: 2

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Retailer Margin',
        value: 'retailerMargin',
        groupTitle: 'Financials',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = (item.rsv/(1 + item.gSTVAT)).toFixed(0) - item.netRevenue
            item.retailerMargin = isNaN(val)? 0: val
            return item.retailerMargin
        },
        calLvl: 3

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'RM %',
        value: 'rmPercent',
        groupTitle: 'Financials',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = item.retailerMargin/(item.rsv/(1 + item.gSTVAT))*100
            item.rmPercent =  isNaN(val)  || val == Infinity || val == -Infinity? 0 : val.toFixed(2)
            return item.rmPercent
        },
        calLvl: 4
        
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'GM %',
        value: 'gmPercent',
        groupTitle: 'Financials',
        isDefault: true,
        defaultValue: 0,
        formula(item){
            let val = (item.grossMargin/ item.netRevenue) *100
            item.gmPercent = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.gmPercent
        },
        calLvl: 4
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'TI %',
        value: 'tiPercent',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.tradeInvestment/ item.netRevenue) *100
            item.tiPercent = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.tiPercent
        },
        calLvl: 3

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: '% of Profit Pool',
        value: 'percentOfProfitPool',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.grossMargin/ (item.grossMargin + item.retailerMargin)) *100
            item.percentOfProfitPool = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.percentOfProfitPool
        },
        calLvl: 4
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: '% Promo Funding',
        value: 'percentPromoFunding',
        groupTitle: 'Financials',
        defaultValue: 0,
        formula(item){
            let val = (item.promoDeal/ (item.baseRSP - item.rsp)) *100
            item.percentPromoFunding = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.percentPromoFunding
        },
        calLvl: 2

    },

    //financials per unit

    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'GSV Per Unit',
        value: 'gsvPerUnit',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.gsv/ item.totalUnits
            item.gsvPerUnit = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.gsvPerUnit
        },
        calLvl: 3

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Trade Investment Per Unit',
        value: 'tradeInvestmentPerUnit',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.tradeInvestment/ item.totalUnits
            item.tradeInvestmentPerUnit = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.tradeInvestmentPerUnit
        },
        calLvl: 2

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Net Revenue Per Unit',
        value: 'netRevenuePerUnit',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.netRevenue/ item.totalUnits
            item.netRevenuePerUnit = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.netRevenuePerUnit
        },
        calLvl: 3

    },

    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'COGs Per Unit2',
        value: 'coGsPerUnit2',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.cogs/ item.totalUnits
            item.coGsPerUnit2 = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.coGsPerUnit2
        },
        calLvl: 3

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Gross Margin Per Unit',
        value: 'grossMarginPerUnit',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.grossMargin/ item.totalUnits
            item.grossMarginPerUnit = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.grossMarginPerUnit
        },
        calLvl: 4

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'RSV Per Unit',
        value: 'rsvPerUnit',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.rsv/ item.totalUnits
            item.rsvPerUnit = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.rsvPerUnit
        },
        calLvl: 3

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Retailer Margin Per Unit',
        value: 'retailerMarginPerUnit',
        groupTitle: 'FinancialsPerUnit',
        defaultValue: 0,
        formula(item){
            let val = item.retailerMargin/ item.totalUnits
            item.retailerMarginPerUnit = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.retailerMarginPerUnit
        },
        calLvl: 4

    },
    //financials per vol
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Total Volume (Measure)',
        value: 'totalVolume',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = item.totalUnits/ item.measureAmount
            item.totalVolume = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.totalVolume
        },
        calLvl: 2

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'GSV Per Vol',
        value: 'gsvPerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.gsvPerUnit/ item.measureAmount)
            item.gsvPerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.gsvPerVol
        },
        calLvl: 4

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Trade Investment Per Vol',
        value: 'tradeInvestmentPerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.tradeInvestmentPerUnit/ item.measureAmount)
            item.tradeInvestmentPerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.tradeInvestmentPerVol
        },
        calLvl: 3

    },

    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Net Revenue Per Vol',
        value: 'netRevenuePerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.netRevenuePerUnit/ item.measureAmount)
            item.netRevenuePerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.netRevenuePerVol
        },
        calLvl: 4

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'COGs Per Vol',
        value: 'cogsPerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.coGsPerUnit2/ item.measureAmount)
            item.cogsPerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.cogsPerVol
        },
        calLvl: 4

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Gross Margin Per Vol',
        value: 'grossMarginPerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.grossMarginPerUnit/ item.measureAmount)
            item.grossMarginPerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.grossMarginPerVol
        },
        calLvl: 5

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'RSV Per Vol',
        value: 'rsvPerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.rsvPerUnit/ item.measureAmount)
            item.rsvPerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.rsvPerVol
        },
        calLvl: 5

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Retailer Margin Per Vol',
        value: 'retailerMarginPerVol',
        groupTitle: 'FinancialsPerVol',
        defaultValue: 0,
        formula(item){
            let val = (item.retailerMarginPerUnit/ item.measureAmount)
            item.retailerMarginPerVol = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(2)
            return item.retailerMarginPerVol
        },
        calLvl: 5

    },

    //ROI
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Incremental Volume',
        value: 'incrementalVolume',
        groupTitle: 'ROI',
        defaultValue: 0,
        formula(item){
            let val = item.volPerWeek - item.baseUnit
            item.incrementalVolume = isNaN(val) || val == Infinity || val == -Infinity? 0: val.toFixed(0)
            return item.incrementalVolume
        },
        calLvl: 1

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Incremental TI',
        value: 'incrementalTI',
        groupTitle: 'ROI',
        defaultValue: 0,
        special:true,
        

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Incremental NR',
        value: 'incrementalNR',
        groupTitle: 'ROI',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Incremental GM',
        value: 'incrementalGM',
        groupTitle: 'ROI',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'ROI NR',
        value: 'roiNR',
        groupTitle: 'ROI',
        defaultValue: 0,
       
    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'ROI GM',
        value: 'roiGM',
        groupTitle: 'ROI',
        isDefault: true
    },
    //Details
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'User Name',
        value: 'userName',
        groupTitle: 'Details',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Plan Name',
        value: 'planName',
        groupTitle: 'Details',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Final Plan Tag',
        value: 'planTag',
        groupTitle: 'Details',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Unique Identifier',
        value: 'uniqueId',
        groupTitle: 'Details',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Customer Product Code',
        value: 'customerProductCode',
        groupTitle: 'Details',

    },

    //error check
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Error: 52 week Freq.',
        value: 'error52WeekFreq',
        groupTitle: 'ErrorChecks',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Error: Base Price',
        value: 'errorBasePrice',
        groupTitle: 'ErrorChecks',

    },
    //more details
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Comparison Plan RSP',
        value: 'comparisonPlanRSP',
        groupTitle: 'ComparisonPlan',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Comparison Plan Freq',
        value: 'comparisonPlanFreq',
        groupTitle: 'ComparisonPlan',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Comparison Plan Vol',
        value: 'comparisonPlanVol',
        groupTitle: 'ComparisonPlan',

    },
    {
        cellClass: 'tbcell',
        class: "primary--text",
        align: 'center',
        text: 'Comparison Plan RM%',
        value: 'comparisonPlanRM',
        groupTitle: 'ComparisonPlan',

    },

]

export default planProductHeaders;