export const userPermissions = {
    VIEW_REFDATA:'Permissions.RefData.View',
    UPDATE_REFDATA:'Permissions.RefData.Update',
    UPDATE_USER:'Permissions.Users.Update',
    CREATE_USER:'Permissions.Users.Create'
}

export const emptyGuid = "00000000-0000-0000-0000-000000000000"

export const variables = {
    text: {
        size: {
            sm: 12,
            md: 14,
            lg: 16,
            xl: 18,
        },
        weight: {
            light: 300,
            normal: 400,
            medium: 500,
            bold: 700,
        },
    },
    colors: {
        primary: '#4274CE',
        secondary: '#FFCDD2',
        accent: '#3F51B5',
        background: '#F9FAFB',
        primary50: '#E4EDFF',
        primary700: '#285CB7',
    },
}