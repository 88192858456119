<template>
    <div v-if="viewReportMode">
        <v-btn
            elevation="0"
            class="white--text mr-2"
            color="primary"
            @click="viewReportMode = false"
        >
            <v-icon left>mdi-arrow-left</v-icon>Go Back
        </v-btn>
        <PowerBILoader
            :height="'95%'"
            :reportTitle="'Öutput'"
            :reportUrl="'https://app.powerbi.com/reportEmbed?reportId=55633fdd-4559-4ca4-977e-e4175b122514&autoAuth=true&ctid=1bda8acf-807e-4a1c-b603-a2b6bb566cdb&navContentPaneEnabled=false'"
        ></PowerBILoader>
    </div>
    <div v-else>
        <template v-if="draftPlan.name">
            <v-row ref="row1" class="d-flex px-4 py-2">
                <div class="text-h5 font-weight-bold primary--text">
                    Scenario Builder | {{ draftPlan.newName || draftPlan.name }}
                </div>
                <v-spacer></v-spacer>
                <v-alert v-if="hasChanges" dense type="warning" class="ma-0">
                    Plan won't be saved until click on save plan
                </v-alert>
                <v-spacer></v-spacer>
                <v-btn elevation="0" class="white--text mr-2" color="primary" @click="onViewReport">
                    <v-icon left>mdi-chart-box-outline</v-icon>View Output
                </v-btn>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            elevation="0"
                            class="primary--text mr-2"
                            color="#E4EDFF"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="draftPlan.newName != undefined"
                            @click="
                                (newPlanDialog = true),
                                    (newPlanMode = true),
                                    (duplicatePlanMode = true),
                                    (selectedPlanId = draftPlan.id)
                            "
                        >
                            <v-icon left>mdi-content-duplicate</v-icon>Duplicate Plan
                        </v-btn>
                    </template>
                    <span v-if="!hasChanges">Create a new plan <br />based on current plan</span>
                    <span v-else>Save as a new plan</span>
                </v-tooltip>
                <v-btn
                    elevation="0"
                    class="white--text mr-2"
                    color="primary"
                    :disabled="!hasChanges || !canEditPlan"
                    @click="onSavePlan"
                >
                    <v-icon left>mdi-content-save</v-icon>Save Plan
                </v-btn>
                <v-btn
                    text
                    elevation="0"
                    class="#ECF3FF--text"
                    color="#B42318"
                    @click="dialogPlanDelete = true"
                    :disabled="!canEditPlan"
                >
                    <v-icon color="#B42318" left>mdi-trash-can-outline</v-icon>Delete Plan
                </v-btn>
            </v-row>
            <v-row ref="row2" :class="'px-4'">
                <v-expansion-panels v-model="panel" multiple flat>
                    <v-expansion-panel @click="showPanel = !showPanel">
                        <v-expansion-panel-header class="py-2">{{
                            showPanel ? 'Hide Profit & Loss summary' : 'Show Profit & Loss summary'
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content color="#ECF3FF">
                            <div class="d-flex">
                                <v-col cols="6 px-0 pb-0">
                                    <v-card class="fill-height">
                                        <v-card-title class="pb-0">
                                            <div class="text-h5 primary--text">Profit & Loss</div>
                                        </v-card-title>
                                        <v-data-table
                                            dense
                                            disable-pagination
                                            hide-default-footer
                                            :headers="plheaders"
                                            :items="pldata"
                                        >
                                            <template v-slot:[`item.variancepercent`]="{ item }">
                                                <div v-if="item.variancepercent">
                                                    <v-icon
                                                        v-if="item.variancepercent < 0"
                                                        color="red"
                                                    >
                                                        mdi-arrow-down-thick
                                                    </v-icon>
                                                    <v-icon
                                                        v-else-if="item.variancepercent > 1"
                                                        color="green"
                                                    >
                                                        mdi-arrow-up-thick</v-icon
                                                    >
                                                    <v-icon v-else color="yellow"
                                                        >mdi-arrow-right-thick</v-icon
                                                    >
                                                    <span>{{ item.variancepercent }} %</span>
                                                </div>
                                            </template>
                                            <template v-slot:[`item.new`]="{ item }">
                                                {{ formatNumber(item.new) }}
                                            </template>
                                            <template v-slot:[`item.original`]="{ item }">
                                                {{ formatNumber(item.original) }}
                                            </template>
                                            <template v-slot:[`item.variance`]="{ item }">
                                                {{ formatNumber(item.variance) || '-' }}
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                                <v-col cols="3 pb-0">
                                    <v-card class="pa-8 py-13">
                                        <div class="mt-6">
                                            Plan growth from
                                            <strong class="primary--text">{{
                                                comparisonPlan.name
                                            }}</strong>
                                        </div>
                                        <div
                                            class="my-7 font-weight-bold d-flex align-center"
                                            style="font-size: 85px; color: #6ce9a6"
                                        >
                                            <div
                                                v-if="comparisonGrowth > 0"
                                                style="font-size: 45px; color: #6ce9a6"
                                            >
                                                +
                                            </div>
                                            <div
                                                :style="
                                                    comparisonGrowth < 0
                                                        ? 'color:red;'
                                                        : 'color:#6CE9A6;'
                                                "
                                            >
                                                {{ comparisonGrowth }}%
                                            </div>
                                        </div>
                                        <div>
                                            <v-icon v-if="comparisonGrowthInMillion < 0" color="red"
                                                >mdi-arrow-down-thin</v-icon
                                            >
                                            <v-icon v-else color="#12B76A"
                                                >mdi-arrow-up-thin</v-icon
                                            >
                                            <span
                                                class="mx-1"
                                                :style="
                                                    comparisonGrowthInMillion < 0
                                                        ? 'color:red;'
                                                        : 'color:#12B76A'
                                                "
                                                >$
                                                {{ formatNumber(comparisonGrowthInMillion) }}m</span
                                            ><span>from comparison plan</span>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="3  px-0 pb-0">
                                    <v-card class="py-13 pa-8">
                                        <div class="mt-6">Plan growth from last save</div>
                                        <div
                                            class="my-7 font-weight-bold"
                                            style="font-size: 85px; color: #ff9c66"
                                        >
                                            {{ sumNRPercent }}%
                                        </div>
                                        <div>
                                            Last saved:
                                            {{ formatLastModifiedOn(draftPlan.lastModifiedOn) }}
                                        </div>
                                    </v-card>
                                </v-col>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-row class="px-4 py-2">
                <v-card class="overflow-y-auto" width="100%">
                    <v-card-title>
                        <div class="blue--text primary--text">Plan Products</div>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="white--text"
                                    color="primary"
                                    @click="addProductDialog = true"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon left>mdi-plus</v-icon>Add Product
                                </v-btn>
                            </template>
                            <span>Add new Product based on master product data</span>
                        </v-tooltip>
                        <v-tooltip bottom color="grey">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="white--text ml-2"
                                    color="primary"
                                    @click="colsDialog = true"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-table-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>Show/Hide table columns</span>
                        </v-tooltip>
                        <!--<v-btn rounded small class="white--text ml-5" color="grey">Edit Cogs<v-icon small class="ml-2">
                        mdi-currency-usd</v-icon>
                </v-btn>-->
                        <div class="ml-5">
                            <v-text-field
                                class="rgm-input"
                                dense
                                v-model="search"
                                outlined
                                prepend-inner-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            >
                            </v-text-field>
                        </div>
                    </v-card-title>
                    <v-data-table
                        dense
                        :headers="headers"
                        :items-per-page="30"
                        fixed-header
                        class="planproducts-table"
                        :footer-props="footerProps"
                        :items="draftProducts"
                        :search="search"
                        sort-by="category"
                        @click:row="onRowClick"
                        :item-class="row_classes"
                    >
                        <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
                            <div :key="i" style="display: inline-block; padding: 16px 0">
                                <div style="display: inline-block; padding: 16px 0">
                                    {{ header.text }}
                                </div>
                                <div style="float: right; margin-top: 8px">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-width="200"
                                        offset-y
                                        transition="slide-y-transition"
                                        right
                                        fixed
                                        style="position: absolute; right: 0"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                                                <v-icon
                                                    small
                                                    :color="
                                                        activeFilters[header.value] &&
                                                        activeFilters[header.value].length <
                                                            filters[header.value].length
                                                            ? 'red'
                                                            : 'default'
                                                    "
                                                >
                                                    mdi-filter-variant
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card height="500" class="d-flex flex-column">
                                            <v-card-title>
                                                <v-row no-gutters style="position: relative">
                                                    <v-col cols="6">
                                                        <v-btn
                                                            text
                                                            block
                                                            @click="toggleAll(header.value)"
                                                            color="success"
                                                        >
                                                            Select All
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-btn
                                                            text
                                                            block
                                                            @click="clearAll(header.value)"
                                                            color="warning"
                                                            >Clear All
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text class="flex-grow-1 overflow-auto">
                                                <v-list flat dense class="pa-0">
                                                    <v-list-item-group
                                                        multiple
                                                        v-model="activeFilters[header.value]"
                                                        class="py-2"
                                                    >
                                                        <div
                                                            style="background: white"
                                                            v-for="item in filters[header.value]"
                                                            :key="`${item}`"
                                                        >
                                                            <v-list-item
                                                                :value="item"
                                                                :ripple="false"
                                                            >
                                                                <template
                                                                    v-slot:default="{ active }"
                                                                >
                                                                    <v-list-item-action
                                                                        class="ma-0"
                                                                    >
                                                                        <v-checkbox
                                                                            :input-value="active"
                                                                            :true-value="item"
                                                                            color="primary"
                                                                            :ripple="false"
                                                                            dense
                                                                        >
                                                                        </v-checkbox>
                                                                    </v-list-item-action>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title
                                                                            v-text="item"
                                                                        ></v-list-item-title>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </v-list-item>
                                                        </div>
                                                    </v-list-item-group>
                                                    <v-divider></v-divider>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <template>
                                <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            class="mr-2"
                                            v-if="canEditPlan"
                                            @click="duplicateProduct(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-plus-box-multiple-outline
                                        </v-icon>
                                    </template>
                                    <span>Duplicate current plan product</span>
                                </v-tooltip>
                                <v-icon
                                    color="#B42318"
                                    v-if="canEditPlan"
                                    @click="deleteItem(item)"
                                >
                                    mdi-trash-can-outline
                                </v-icon>
                            </template>
                        </template>
                        <template v-slot:[`item.startDate`]="{ item }">
                            <v-menu
                                v-if="canEditPlan"
                                ref="menu"
                                v-model="item.startDatePickerMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip
                                        bottom
                                        color="teal"
                                        :disabled="
                                            item.startDate.substring(0, 10) ==
                                            draftPlan.startDate.substring(0, 10)
                                        "
                                    >
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip
                                                outlined
                                                label
                                                v-model="item.startDate"
                                                color="#E4EDFF"
                                                @click="editedItem = item"
                                                :text-color="
                                                    item.startDate.substring(0, 10) ==
                                                    draftPlan.startDate.substring(0, 10)
                                                        ? '#285CB7'
                                                        : '#008080'
                                                "
                                                v-bind="attrs"
                                                v-on="{ ...tooltip, ...menu }"
                                            >
                                                {{ formatDate(item.startDate)
                                                }}<v-icon class="ml-2"
                                                    >mdi-calendar-blank-outline
                                                </v-icon>
                                            </v-chip>
                                        </template>
                                        <span
                                            >Diffrent to plan start date :
                                            {{ draftPlan.startDate.substring(0, 10) }}
                                        </span>
                                    </v-tooltip>
                                </template>
                                <v-date-picker
                                    :allowed-dates="allowedStartDate"
                                    no-title
                                    v-model="item.startDate"
                                    color="primary"
                                    @input="
                                        (item.startDatePickerMenu = false), updateDirtyItems(item)
                                    "
                                >
                                </v-date-picker>
                            </v-menu>
                            <v-tooltip
                                v-else
                                bottom
                                color="teal"
                                :disabled="
                                    item.startDate.substring(0, 10) ==
                                    draftPlan.startDate.substring(0, 10)
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        outlined
                                        label
                                        v-model="item.startDate"
                                        color="#E4EDFF"
                                        v-bind="attrs"
                                        v-on="on"
                                        :text-color="
                                            item.startDate.substring(0, 10) ==
                                            draftPlan.startDate.substring(0, 10)
                                                ? '#285CB7'
                                                : '#008080'
                                        "
                                    >
                                        {{ formatDate(item.startDate)
                                        }}<v-icon class="ml-2">mdi-calendar-blank-outline </v-icon>
                                    </v-chip>
                                </template>
                                <span
                                    >Diffrent to plan start date :
                                    {{ draftPlan.startDate.substring(0, 10) }}
                                </span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.endDate`]="{ item }">
                            <v-menu
                                v-if="canEditPlan"
                                v-model="item.endDatePickerMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip
                                        bottom
                                        color="teal"
                                        :disabled="
                                            item.startDate.substring(0, 10) ==
                                            draftPlan.startDate.substring(0, 10)
                                        "
                                    >
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip
                                                label
                                                outlined
                                                v-model="item.endDate"
                                                color="#E4EDFF"
                                                @click="editedItem = item"
                                                :text-color="
                                                    item.endDate.substring(0, 10) ==
                                                    draftPlan.endDate.substring(0, 10)
                                                        ? '#285CB7'
                                                        : '#008080'
                                                "
                                                v-bind="attrs"
                                                v-on="{ ...tooltip, ...menu }"
                                            >
                                                {{ formatDate(item.endDate)
                                                }}<v-icon class="ml-2"
                                                    >mdi-calendar-blank-outline
                                                </v-icon>
                                            </v-chip>
                                        </template>
                                        <span
                                            >Diffrent to plan end date :
                                            {{ draftPlan.endDate.substring(0, 10) }}
                                        </span>
                                    </v-tooltip>
                                </template>
                                <v-date-picker
                                    :allowed-dates="allowedEndDate"
                                    no-title
                                    v-model="item.endDate"
                                    color="primary"
                                    @input="
                                        (item.endDatePickerMenu = false), updateDirtyItems(item)
                                    "
                                >
                                </v-date-picker>
                            </v-menu>
                            <v-tooltip
                                v-else
                                bottom
                                color="teal"
                                :disabled="
                                    item.endDate.substring(0, 10) ==
                                    draftPlan.endDate.substring(0, 10)
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        label
                                        outlined
                                        v-model="item.endDate"
                                        color="#E4EDFF"
                                        v-bind="attrs"
                                        v-on="on"
                                        :text-color="
                                            item.endDate.substring(0, 10) ==
                                            draftPlan.endDate.substring(0, 10)
                                                ? '#285CB7'
                                                : '#008080'
                                        "
                                    >
                                        {{ formatDate(item.endDate)
                                        }}<v-icon class="ml-2">mdi-calendar-blank-outline </v-icon>
                                    </v-chip>
                                </template>
                                <span
                                    >Diffrent to plan end date :
                                    {{ draftPlan.endDate.substring(0, 10) }}
                                </span>
                            </v-tooltip>
                        </template>
                        <!--Customer-->
                        <template v-slot:[`item.customer`]="{ item }">
                            <v-menu
                                v-if="canEditPlan"
                                v-model="item.customerMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        :color="
                                            specialRefData.customers?.find(
                                                (d) => d.id == item.customerId
                                            )?.colorCode
                                        "
                                        text-color="white"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{
                                            specialRefData.customers?.find(
                                                (d) => d.id == item.customerId
                                            )?.name
                                        }}
                                        <v-icon> mdi-menu-down </v-icon>
                                    </v-chip>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="customer in specialRefData.customers"
                                        :key="customer.id"
                                        link
                                        @click="
                                            (item.customerId = customer.id),
                                                (item.customerMenu = false),
                                                updateDirtyItems(item)
                                        "
                                    >
                                        <v-list-item-title
                                            v-text="customer.name"
                                        ></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-chip
                                v-else
                                :color="
                                    specialRefData.customers?.find((d) => d.id == item.customerId)
                                        ?.colorCode
                                "
                                text-color="white"
                            >
                                {{
                                    specialRefData.customers?.find((d) => d.id == item.customerId)
                                        ?.name
                                }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.channel`]="{ item }">
                            {{
                                specialRefData.customers.find((b) => b.id == item.customerId)
                                    ?.channel
                            }}
                        </template>
                        <template v-slot:[`item.region`]="{ item }">
                            {{
                                specialRefData.customers.find((b) => b.id == item.customerId)
                                    ?.region
                            }}
                        </template>
                        <!--Products-->

                        <template v-slot:[`item.brand`]="{ item }">
                            {{
                                referenceData.brands.find(
                                    (b) =>
                                        b.id ==
                                        products.find((p) => p.id == item.productId)?.brandId
                                )?.name
                            }}
                        </template>
                        <template v-slot:[`item.segment`]="{ item }">
                            {{
                                referenceData.segments.find(
                                    (b) =>
                                        b.id ==
                                        products.find((p) => p.id == item.productId)?.segmentId
                                )?.name
                            }}
                        </template>
                        <template v-slot:[`item.category`]="{ item }">
                            {{
                                referenceData.categories.find(
                                    (b) =>
                                        b.id ==
                                        products.find((p) => p.id == item.productId)?.categoryId
                                )?.name
                            }}
                        </template>

                        <template v-slot:[`item.size`]="{ item }">
                            {{ products.find((p) => p.id == item.productId)?.size }}
                        </template>
                        <template v-slot:[`item.measureAmount`]="{ item }">
                            {{ products.find((p) => p.id == item.productId)?.measureAmount }}
                        </template>
                        <template v-slot:[`item.nielsenSegment`]="{ item }">
                            {{
                                referenceData.nielsenSegments.find(
                                    (b) =>
                                        b.id ==
                                        products.find((p) => p.id == item.productId)
                                            ?.nielsenSegmentId
                                )?.name
                            }}
                        </template>
                        <template v-slot:[`item.measure`]="{ item }">
                            {{
                                referenceData.measures.find(
                                    (b) =>
                                        b.id ==
                                        products.find((p) => p.id == item.productId)?.measureId
                                )?.name
                            }}
                        </template>
                        <template v-slot:[`item.bundle`]="{ item }">
                            {{
                                referenceData.bundles.find(
                                    (b) =>
                                        b.id ==
                                        products.find((p) => p.id == item.productId)?.bundleId
                                )?.name
                            }}
                        </template>
                        <template v-slot:[`item.tier`]="{ item }">
                            {{
                                referenceData.tiers.find(
                                    (b) =>
                                        b.id == products.find((p) => p.id == item.productId)?.tierId
                                )?.name
                            }}
                        </template>
                        <!--promo details-->
                        <template v-slot:[`item.strategy`]="{ item }">
                            <v-menu
                                v-if="canEditPlan"
                                v-model="item.strategyMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        color="#E4EDFF"
                                        text-color="#285CB7"
                                    >
                                        {{ item.strategy }}
                                        <v-icon> mdi-menu-down </v-icon>
                                    </v-chip>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="strategy in strategies"
                                        :key="strategy"
                                        link
                                        @click="
                                            (item.strategy = strategy),
                                                (item.strategyMenu = false),
                                                updateDirtyItems(item)
                                        "
                                    >
                                        <v-list-item-title v-text="strategy"></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-chip v-else color="#E4EDFF" text-color="#285CB7">
                                {{ item.strategy }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.promoMechanic`]="{ item }">
                            <v-menu
                                v-if="canEditPlan"
                                v-model="item.promoMechanicMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        color="#E4EDFF"
                                        text-color="#285CB7"
                                    >
                                        {{ item.promoMechanic }}
                                        <v-icon> mdi-menu-down </v-icon>
                                    </v-chip>
                                    <!--<div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        {{ item.promoMechanic }}
                                        <v-icon color="primary">mdi-menu-down</v-icon>
                                    </div>-->
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="promoMechanic in promoMechanicList"
                                        :key="promoMechanic"
                                        link
                                        @click="
                                            (item.promoMechanic = promoMechanic),
                                                (item.promoMechanicMenu = false),
                                                updateDirtyItems(item)
                                        "
                                    >
                                        <v-list-item-title
                                            v-text="promoMechanic"
                                        ></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-chip v-else color="#E4EDFF" text-color="#285CB7">
                                {{ item.promoMechanic }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.rsp`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    v-model="item.rsp"
                                    background-color="#E4EDFF"
                                    label=" "
                                    @change="recalculate(item)"
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.baseRSP`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    v-model="item.baseRSP"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    @change="recalculate(item)"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.promoType`]="{ item }">
                            <div v-if="getPromoType(item) != 'Error'">
                                {{ getPromoType(item) }}
                            </div>
                            <div v-else style="background: #fef3f2; color: #b42318">
                                {{ getPromoType(item) }}
                            </div>
                        </template>
                        <template v-slot:[`item.frequency`]="{ item }">
                            <div>
                                <v-tooltip
                                    bottom
                                    color="#B42318"
                                    :disabled="!isExceedFrequency(item)"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :disabled="!canEditPlan"
                                            class="rgm-input"
                                            :error="isExceedFrequency(item)"
                                            @keypress="numberOnly"
                                            @focus="$event.target.select()"
                                            :rules="[rules.frequency]"
                                            dense
                                            v-model="item.frequency"
                                            background-color="#E4EDFF"
                                            label=" "
                                            @change="recalculate(item)"
                                            filled
                                            outlined
                                            solo
                                            flat
                                            elevation="0"
                                            hide-details
                                            suffix="wks"
                                            v-on="on"
                                        >
                                        </v-text-field>
                                    </template>
                                    <span
                                        >Total frequency of <strong>{{ item.product }}</strong> in
                                        <strong>{{ item.customer }}</strong
                                        ><br />
                                        cannot be greater than 52, current:
                                        {{ groupProductTotalFrequency(item) }}</span
                                    >
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:[`item.volPerWeek`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    @keypress="numberOnly"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @change="recalculate(item)"
                                    @focus="$event.target.select()"
                                    v-model="item.volPerWeek"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.estimatedVolPerWeek`]="{ item }">
                            <div>
                                {{ formatNumber(item.estimatedVolPerWeek) }}
                            </div>
                        </template>
                        <template v-slot:[`item.baseUnit`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="numberOnly"
                                    v-model="item.baseUnit"
                                    @change="recalculate(item)"
                                    background-color="#E4EDFF"
                                    @focus="$event.target.select()"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.comments`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    dense
                                    v-model="item.comments"
                                    background-color="#E4EDFF"
                                    @change="updateDirtyItems(item)"
                                    label=" "
                                    filled
                                    @focus="$event.target.select()"
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.multibuyRedemption`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.multibuyRedemption"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                    suffix="%"
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.catalogue`]="{ item }">
                            <v-switch
                                :disabled="!canEditPlan"
                                v-model="item.catalogue"
                                @change="updateDirtyItems(item)"
                            >
                            </v-switch>
                        </template>
                        <template v-slot:[`item.display`]="{ item }">
                            <v-switch
                                :disabled="!canEditPlan"
                                v-model="item.display"
                                @change="updateDirtyItems(item)"
                            >
                            </v-switch>
                        </template>
                        <template v-slot:[`item.coGsPerUnit`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.coGsPerUnit"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.offInvoiceDiscount`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.offInvoiceDiscount"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.deferredDeal`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.deferredDeal"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.promoDeal`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    v-model="item.promoDeal"
                                    @change="recalculate(item)"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.fixedAmtPerWeek`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="numberOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.fixedAmtPerWeek"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.investment2`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="numberOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.investment2"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.termsPercent`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    @change="recalculate(item)"
                                    v-model="item.termsPercent"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                    suffix="%"
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.gSTVAT`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    v-model="item.gSTVAT"
                                    @change="recalculate(item)"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                    suffix="%"
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.totalUnits`]="{ item }">
                            <div>
                                {{ formatNumber(item.totalUnits) }}
                            </div>
                        </template>
                        <template v-slot:[`item.promoDiscount`]="{ item }">
                            <div>{{ item.promoDiscount }}%</div>
                        </template>
                        <template v-slot:[`item.gmPerUnit`]="{ item }">
                            <div>
                                {{ item.gmPerUnit }}
                            </div>
                        </template>
                        <template v-slot:[`item.gsv`]="{ item }">
                            <div>
                                {{ formatNumber(item.gsv) }}
                            </div>
                        </template>
                        <template v-slot:[`item.netRevenue`]="{ item }">
                            <div>
                                {{ formatNumber(item.netRevenue) }}
                            </div>
                        </template>
                        <template v-slot:[`item.tradeInvestment`]="{ item }">
                            <div>
                                {{ formatNumber(item.tradeInvestment) }}
                            </div>
                        </template>
                        <template v-slot:[`item.cogs`]="{ item }">
                            <div>
                                {{ formatNumber(item.cogs) }}
                            </div>
                        </template>
                        <template v-slot:[`item.grossMargin`]="{ item }">
                            <div>
                                {{ formatNumber(item.grossMargin) }}
                            </div>
                        </template>
                        <template v-slot:[`item.rsv`]="{ item }">
                            <div>
                                {{ formatNumber(item.rsv) }}
                            </div>
                        </template>
                        <template v-slot:[`item.retailerMargin`]="{ item }">
                            <div>
                                {{ formatNumber(item.retailerMargin) }}
                            </div>
                        </template>
                        <template v-slot:[`item.rmPercent`]="{ item }">
                            <div>{{ item.rmPercent }}%</div>
                        </template>
                        <template v-slot:[`item.listPrice`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!canEditPlan"
                                    class="rgm-input"
                                    :rules="[rules.isNumber]"
                                    dense
                                    @keypress="decimalOnly"
                                    @focus="$event.target.select()"
                                    v-model="item.listPrice"
                                    @change="recalculate(item)"
                                    background-color="#E4EDFF"
                                    label=" "
                                    filled
                                    outlined
                                    solo
                                    flat
                                    elevation="0"
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.gmPercent`]="{ item }">
                            <div>{{ item.gmPercent }}%</div>
                        </template>
                        <template v-slot:[`item.tiPercent`]="{ item }">
                            <div>{{ item.tiPercent }}%</div>
                        </template>
                        <template v-slot:[`item.percentOfProfitPool`]="{ item }">
                            <div>{{ item.percentOfProfitPool }}%</div>
                        </template>
                        <template v-slot:[`item.percentPromoFunding`]="{ item }">
                            <div>{{ item.percentPromoFunding }}%</div>
                        </template>
                        <template v-slot:[`item.comparisonPlanRSP`]="{ item }">
                            <div>
                                {{ formatNumber(item.comparisonPlanRSP) }}
                            </div>
                        </template>
                        <template v-slot:[`item.comparisonPlanFreq`]="{ item }">
                            <div>{{ item.comparisonPlanFreq }}wks</div>
                        </template>
                        <template v-slot:[`item.comparisonPlanVol`]="{ item }">
                            <div>
                                {{ formatNumber(item.comparisonPlanVol) }}
                            </div>
                        </template>
                        <template v-slot:[`item.comparisonPlanRM`]="{ item }">
                            <div>{{ formatNumber(item.comparisonPlanRM, 2) }}%</div>
                        </template>
                        <template v-slot:[`item.incrementalVolume`]="{ item }">
                            <div>
                                {{ formatNumber(item.incrementalVolume) }}
                            </div>
                        </template>
                        <template v-slot:[`item.incrementalTI`]="{ item }">
                            <div>
                                {{ formatNumber(getIncrementalTI(item)) }}
                            </div>
                        </template>
                        <template v-slot:[`item.incrementalNR`]="{ item }">
                            <div>
                                {{ formatNumber(getIncrementalNR(item)) }}
                            </div>
                        </template>
                        <template v-slot:[`item.incrementalGM`]="{ item }">
                            <div>
                                {{ formatNumber(getIncrementalGM(item)) }}
                            </div>
                        </template>
                        <template v-slot:[`item.roiNR`]="{ item }">
                            <div>{{ getROINR(item) }}%</div>
                        </template>
                        <template v-slot:[`item.roiGM`]="{ item }">
                            <div>{{ getROIGM(item) }}%</div>
                        </template>
                    </v-data-table>
                    <v-snackbar v-model="snackbar" centered :timeout="2000" color="success">
                        Product added to the top of the table
                    </v-snackbar>
                </v-card>
            </v-row>
            <v-dialog v-model="duplicatePlanDialog">
                <template>
                    <v-card-subtitle class="mt-1 mb-2"
                        >Name the new plan and select one to duplicate
                    </v-card-subtitle>
                    <v-form v-model="isFormValid2">
                        <v-card-text>
                            <span class="text--black">New plan Name</span>
                            <v-text-field
                                class="rgm-input"
                                :rules="[rules.required, newPlanNameRule]"
                                dense
                                outlined
                                v-model="newPlanName"
                                placeholder="Type new plan name here"
                            >
                            </v-text-field>
                            <span class="text--black">Existing plan to duplicate</span>
                            <v-autocomplete
                                :rules="[rules.required]"
                                v-model="selectedPlanId"
                                :items="userPlans"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                placeholder="Existing Plan"
                            >
                            </v-autocomplete>
                        </v-card-text>
                    </v-form>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            width="180px"
                            text
                            outlined
                            @click="(newPlanName = ''), (duplicatePlanDialog = false)"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            v-if="!hasChanges"
                            width="180px"
                            color="primary"
                            class="white--text"
                            :disabled="!isFormValid2"
                            @click="(duplicatePlanDialog = false), loadExistingPlan(newPlanName)"
                        >
                            Create New Plan
                        </v-btn>
                        <v-btn
                            v-else
                            width="180px"
                            color="primary"
                            class="white--text"
                            :disabled="!isFormValid2"
                            @click="(duplicatePlanDialog = false), saveAsNewPlan(newPlanName)"
                        >
                            Save As New Plan
                        </v-btn>
                    </v-card-actions>
                </template>
            </v-dialog>
        </template>
        <template v-else-if="!draftPlan.name && !loading">
            <div width="100%" class="d-flex align-item-center">
                <v-btn
                    v-if="!newPlanDialog"
                    x-large
                    class="mx-auto"
                    color="primary"
                    style="margin-top: 20%"
                    @click="newPlanDialog = true"
                    >Create or Load Plan</v-btn
                >
            </div>
        </template>
        <v-dialog v-model="newPlanDialog" max-width="420px" persistent>
            <v-card class="pa-2">
                <v-card-title class="px-2 pt-2"
                    >Scenario Builder
                    <v-spacer></v-spacer>
                    <v-icon @click="newPlanDialog = false">mdi-close</v-icon>
                </v-card-title>
                <template v-if="!newPlanMode">
                    <v-card-subtitle class="mt-1 mb-2"
                        >Select your plan to open or start a new plan
                    </v-card-subtitle>
                    <v-card-text>
                        <span class="text--black">Select a plan to open</span>
                        <v-autocomplete
                            :rules="[rules.required]"
                            v-model="selectedPlanId"
                            :items="userPlans"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            placeholder="Existing Plan"
                        >
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn text outlined @click="newPlanMode = true"> Start a new plan </v-btn>

                        <v-btn
                            width="180px"
                            color="primary"
                            class="white--text"
                            :disabled="!selectedPlanId"
                            @click="(newPlanDialog = false), loadExistingPlan()"
                        >
                            Load Plan
                        </v-btn>
                    </v-card-actions>
                </template>
                <template v-else>
                    <v-card-subtitle class="mt-1 mb-2"
                        >Name the new plan and select one to duplicate
                    </v-card-subtitle>
                    <v-form v-model="isFormValid">
                        <v-card-text>
                            <span class="text--black">New plan Name</span>
                            <v-text-field
                                class="rgm-input"
                                :rules="[rules.required, newPlanNameRule]"
                                dense
                                outlined
                                v-model="newPlanName"
                                placeholder="Type new plan name here"
                            >
                            </v-text-field>
                            <span class="text--black">Existing plan to duplicate</span>
                            <v-autocomplete
                                :readonly="duplicatePlanMode"
                                :disabled="duplicatePlanMode"
                                :rules="[rules.required]"
                                v-model="selectedPlanId"
                                :items="userPlans"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                placeholder="Existing Plan"
                            >
                            </v-autocomplete>
                        </v-card-text>
                    </v-form>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn width="180px" text outlined @click="onNewPlanCancel"> Cancel </v-btn>
                        <v-btn
                            v-if="!hasChanges"
                            width="180px"
                            color="primary"
                            class="white--text"
                            :disabled="!selectedPlanId || !newPlanName || !isFormValid"
                            @click="
                                (newPlanDialog = false),
                                    duplicatePlanMode
                                        ? onDuplicateCurrentPlan(newPlanName)
                                        : loadExistingPlan(newPlanName)
                            "
                        >
                            Create New Plan
                        </v-btn>
                        <v-btn
                            v-else
                            width="180px"
                            color="primary"
                            class="white--text"
                            :disabled="!isFormValid"
                            @click="(newPlanDialog = false), saveAsNewPlan(newPlanName)"
                        >
                            Save As New Plan
                        </v-btn>
                    </v-card-actions>
                </template>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addProductDialog" persistent width="500">
            <v-card>
                <v-card-title> Add product to plan </v-card-title>
                <v-card-text>
                    <div class="black--text">Product Filters:</div>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="categoryFilter"
                            :items="
                                referenceData?.categories?.map((c) => {
                                    return c.name;
                                })
                            "
                            outlined
                            dense
                            chips
                            small-chips
                            label="Category Filter"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="segmentFilter"
                            :items="
                                referenceData?.segments?.map((c) => {
                                    return c.name;
                                })
                            "
                            outlined
                            dense
                            chips
                            small-chips
                            label="Segment Filter"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="brandFilter"
                            :items="
                                referenceData?.brands?.map((c) => {
                                    return c.name;
                                })
                            "
                            outlined
                            dense
                            chips
                            small-chips
                            label="Brand Filter"
                            multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-divider></v-divider>
                    <div class="black--text">Choose a product from master data:</div>
                    <v-col cols="12">
                        <v-autocomplete
                            :rules="[rules.required]"
                            v-model="editedItem.productId"
                            :items="filteredProducts"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            label="Product"
                            placeholder="Try type something to start search"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        width="150px"
                        text
                        outlined
                        class="grey--text"
                        @click="(addProductDialog = false), clearAddProductFilters()"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        width="150px"
                        color="primary"
                        class="white--text"
                        :disabled="!editedItem.productId"
                        @click="addProductToPlan"
                    >
                        Add to plan
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="colsDialog" max-width="1200">
            <v-card>
                <v-card-title> Show/Hide Columns setting </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-checkbox
                            class="ml-3"
                            dense
                            v-model="selectAllCols"
                            label="Select All"
                            color="primary"
                            @change="onSelectAllChange"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="primary--text font-weight-bold">Customer</div>
                            <div>
                                <div v-for="col in getColDefinitions('Customer')" :key="col.value">
                                    <v-checkbox
                                        class="col-chck my-0 py-0"
                                        dense
                                        v-model="selectedCols[col.value]"
                                        :label="col.text"
                                        color="primary"
                                        hide-details
                                    ></v-checkbox>
                                </div>
                            </div>
                            <div class="mt-4 primary--text font-weight-bold">Product</div>
                            <div v-for="col in getColDefinitions('Product')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                            <div class="mt-4 primary--text font-weight-bold">Product Other</div>
                            <div v-for="col in getColDefinitions('ProductOther')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="primary--text font-weight-bold">Promo Details</div>
                            <div v-for="col in getColDefinitions('PromoDetails')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>

                            <div class="mt-4 primary--text font-weight-bold">Execution</div>
                            <div v-for="col in getColDefinitions('Execution')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="primary--text font-weight-bold">Funding</div>
                            <div v-for="col in getColDefinitions('Funding')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>

                            <div class="mt-4 primary--text font-weight-bold">Base Details</div>
                            <div v-for="col in getColDefinitions('BaseDetails')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="primary--text font-weight-bold">Financials</div>
                            <div v-for="col in getColDefinitions('Financials')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>

                            <div class="mt-4 primary--text font-weight-bold">
                                Financials Per Unit
                            </div>
                            <div
                                v-for="col in getColDefinitions('FinancialsPerUnit')"
                                :key="col.value"
                            >
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="primary--text font-weight-bold">Financials Per Vol.</div>
                            <div
                                v-for="col in getColDefinitions('FinancialsPerVol')"
                                :key="col.value"
                            >
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>

                            <div class="mt-4 primary--text font-weight-bold">ROI</div>
                            <div v-for="col in getColDefinitions('ROI')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="primary--text font-weight-bold">Details</div>
                            <div v-for="col in getColDefinitions('Details')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>

                            <div class="mt-4 primary--text font-weight-bold">Error Checks</div>
                            <div v-for="col in getColDefinitions('ErrorChecks')" :key="col.value">
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                            <div class="mt-4 primary--text font-weight-bold">Details</div>
                            <div
                                v-for="col in getColDefinitions('ComparisonPlan')"
                                :key="col.value"
                            >
                                <v-checkbox
                                    class="col-chck my-0 py-0"
                                    dense
                                    v-model="selectedCols[col.value]"
                                    :label="col.text"
                                    color="primary"
                                    hide-details
                                >
                                </v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        width="150px"
                        text
                        outlined
                        class="grey--text"
                        @click="colsDialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        width="150px"
                        color="primary"
                        class="white--text"
                        @click="onSelectedTableCols"
                    >
                        Apply
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" hide-overlay persistent width="400">
            <v-card class="pa-2">
                <v-card-title>
                    <v-img
                        src="@/assets/deleteIcon.svg"
                        max-height="48px"
                        max-width="48px"
                        contain
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-icon @click="closeDelete()">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <div class="black--textfont-weight-bolder text--subtitle-2">
                        <strong>Delete product from plan?</strong>
                    </div>
                    <div>
                        Are you sure you want to delete this product from the plan? The product will
                        remain in master data.
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn width="180px" text outlined @click="dialogDelete = false">
                        Cancel
                    </v-btn>
                    <v-btn width="180px" text outlined color="#B42318" @click="deleteItemConfirm()">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPlanDelete" hide-overlay persistent width="400">
            <v-card class="pa-2">
                <v-card-title>
                    <v-img
                        src="@/assets/deleteIcon.svg"
                        max-height="48px"
                        max-width="48px"
                        contain
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogPlanDelete = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <div class="black--textfont-weight-bolder text--subtitle-2">
                        <strong>Delete Plan - {{ draftPlan.name }}</strong>
                    </div>
                    <div>
                        Are you sure you want to delete this plan? This action cannot be undone.
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn width="180px" text outlined @click="dialogPlanDelete = false">
                        Cancel
                    </v-btn>
                    <v-btn width="180px" text outlined color="#B42318" @click="deletePlanConfirm()">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Processing
                    <v-progress-linear
                        :indeterminate="loading"
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="plansavesnackbar" centered :timeout="2000" color="success">
            Plan been saved successfully
        </v-snackbar>
        <v-alert v-if="alertError" dense text type="error">Ooops, something went wrong</v-alert>
        <ConfirmDialog ref="confirmDia"></ConfirmDialog>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { formatDate } from '@/common/methods';
import { formatLastModifiedOn } from '@/common/methods';

const plheaders = [
    {
        text: 'Description',
        value: 'description',
        class: 'primary--text',
        width: 180,
        cellClass: 'tbcell',
        sortable: false,
    },
    {
        text: 'Short Desc.',
        value: 'shortdesc',
        class: 'primary--text',
        width: 120,
        cellClass: 'tbcell',
        sortable: false,
    },
    {
        text: 'Original',
        value: 'original',
        class: 'primary--text',
        cellClass: 'tbcell',
        align: 'right',
    },
    {
        text: 'New',
        value: 'new',
        class: 'primary--text',
        cellClass: 'tbcell',
        align: 'right',
    },
    {
        text: 'Variance',
        value: 'variance',
        class: 'primary--text',
        cellClass: 'tbcell',
        align: 'right',
    },
    {
        text: 'Variance %',
        value: 'variancepercent',
        class: 'primary--text',
        cellClass: 'tbcell',
        align: 'left',
    },
];

const planproducts = [
    {
        id: '1',
        name: 'Product 1',
        channel: 'Grocery',
        customer: 'Coles',
        category: 'Kitchen',
        region: 'AUS',
        segment: 'Seg 1',
        brand: 'Brand A',
        startDate: '2022-10-05',
        endDate: '2022-10-22T06:34:12.526Z',
        RSP: 6.4,
        promo: 'Base',
        frequency: '53 wks',
    },
];
</script>
<script>
import { usePlanStore } from '@/stores/plan';
import { masterDataStore } from '@/stores/masterdata';
import { useUserStore } from '@/stores/user';
import { mapState, mapActions } from 'pinia';
import planProductHeaders from '../../common/planproductsheaders';
import { formatNumber } from '../../common/methods';
import { updatePlan } from '@/common/contextapi';
import Vue from 'vue';
import ConfirmDialog from './ConfirmDialog.vue';
import PowerBILoader from './PowerBILoader.vue';
const dayjs = require('dayjs');

export default {
    name: 'ScenarioBuilder',
    data: () => ({
        viewReportMode: false,
        alertError: false,
        isFormValid: false,
        isFormValid2: false,
        activeFilters: {},
        panel: 0,
        plansavesnackbar: false,
        duplicatePlanDialog: false,
        duplicatePlanMode: false,
        showPanel: true,
        search: '',
        dialogDelete: false,
        dialogPlanDelete: false,
        strategies: ['Promo', 'CPI', 'NPD'],
        promoMechanicList: ['EDLP', 'High Low', 'Multibuy'],
        addProductDialog: false,
        selectAllCols: false,
        colsDialog: false,
        dialog: false,
        loading: false,
        selectedPlanId: null,
        newPlanDialog: false,
        newPlanName: '',
        newPlanMode: false,
        editedItem: {},
        selectedCols: {},
        activeCols: {},
        snackbar: false,
        rules: {
            required: (value) => !!value || 'Required.',
            isNumber: (value) => !isNaN(value) || 'Invalid Number',
            positiveNumber: (value) =>
                (!isNaN(value) && parseFloat(value) >= 0) || 'Invalid Number',
            frequency: (value) =>
                (!isNaN(value) && value > 0 && value <= 52) ||
                'Total Frequency cannot be greater than 52',
        },
        row1Height: 0,
        row2Height: 420,
        toBeDeletedPlanProducts: [],
        defaultItem: {},
        dirtyItems: [],

        filterFuncs: {},

        //add new prod filters
        categoryFilter: [],
        brandFilter: [],
        segmentFilter: [],
    }),
    components: {
        ConfirmDialog: ConfirmDialog,
        PowerBILoader: PowerBILoader,
    },
    computed: {
        ...mapState(usePlanStore, [
            'draftPlan',
            'plans',
            'draftPlanBase',
            'productCols',
            'unsavedChanges',
        ]),
        ...mapState(masterDataStore, ['products', 'referenceData', 'specialRefData']),
        ...mapState(useUserStore, ['userProfile']),
        filteredProducts() {
            var filteredProducts = JSON.parse(JSON.stringify(this.products));
            if (this.categoryFilter.length > 0) {
                filteredProducts = filteredProducts.filter((p) =>
                    this.referenceData.categories
                        .filter((c) => this.categoryFilter.includes(c.name))
                        .map((c) => {
                            return c.id;
                        })
                        .includes(p.categoryId)
                );
            }
            if (this.brandFilter.length > 0) {
                filteredProducts = filteredProducts.filter((p) =>
                    this.referenceData.brands
                        .filter((c) => this.brandFilter.includes(c.name))
                        .map((c) => {
                            return c.id;
                        })
                        .includes(p.brandId)
                );
            }

            return filteredProducts;
        },
        canEditPlan() {
            return this.draftPlan.createdBy == this.userProfile.id || this.draftPlan.newName;
        },
        filters() {
            let filters = {};
            planProductHeaders
                .filter((h) => h.basicFilter)
                .forEach((h) => {
                    filters[h.value] = [];
                });
            return filters;
        },
        userPlans() {
            return this.plans.filter(
                (p) => p.isPublic || (!p.isPublic && p.createdBy == this.userProfile.id)
            );
        },
        // rows been added/edited/deleted
        hasChanges() {
            return this.draftPlan.newName != undefined || this.dirtyItems.length > 0;
        },

        headers() {
            planProductHeaders
                .filter((h) => h.basicFilter)
                .forEach((h) => {
                    h.filter = (value) => {
                        return this.activeFilters && this.activeFilters[h.value]
                            ? this.activeFilters[h.value].includes(value)
                            : true;
                    };
                });
            let hs = planProductHeaders
                .filter((h) => this.activeCols[h.value] == true)
                .concat([
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false,
                        class: 'primary--text',
                        width: 100,
                    },
                ]);
            hs.forEach((h) => {
                if (this.filterFuncs[h.value]) {
                    h.filter = this.filterFuncs[h.value];
                }
            });
            return hs;
        },
        draftProducts() {
            var products = this.draftPlan.products;
            return products;
        },
        pldata() {
            return [
                {
                    description: 'Volume (Units)',
                    shortdesc: 'Vol.',
                    original: this.draftPlanBase.sumTotalUnits,
                    new: this.sumTotalUnits,
                    variance: this.totalUnitsVar,
                    variancepercent: this.totalUnitsVarPercent,
                },
                {
                    description: 'Gross Sales Value',
                    shortdesc: 'GSV',
                    original: this.draftPlanBase.sumGSV,
                    new: this.sumGSV,
                    variance: this.sumGSVVar,
                    variancepercent: this.sumGSVPercent,
                },
                {
                    description: 'Trade Investment',
                    shortdesc: 'TI',
                    original: this.draftPlanBase.sumTI,
                    new: this.sumTI,
                    variance: this.sumTIDiff,
                    variancepercent: this.sumTIPercent,
                },
                {
                    description: 'Net Revenue',
                    shortdesc: 'NR',
                    original: this.draftPlanBase.sumNR,
                    new: this.sumNR,
                    variance: this.sumNRDiff,
                    variancepercent: this.sumNRPercent,
                },
                {
                    description: 'COGS',
                    shortdesc: 'COGS',
                    original: this.draftPlanBase.sumCOGS,
                    new: this.sumCOGS,
                    variance: this.sumCOGSDiff,
                    variancepercent: this.sumCOGSPercent,
                },
                {
                    description: 'Gross Margin',
                    shortdesc: 'GM',
                    original: this.draftPlanBase.sumGM,
                    new: this.sumGM,
                    variance: this.sumGMDiff,
                    variancepercent: this.sumGMPercent,
                },
                {
                    description: 'Retailer Margin',
                    shortdesc: 'RM',
                    original: this.draftPlanBase.sumRM,
                    new: this.sumRM,
                    variance: this.sumRMDiff,
                    variancepercent: this.sumRMPercent,
                },
                {
                    description: 'Retaile Sales value',
                    shortdesc: 'RSV',
                    original: this.draftPlanBase.sumRSV,
                    new: this.sumRSV,
                    variance: this.sumRSVDiff,
                    variancepercent: this.sumRSVPercent,
                },
                {
                    description: 'Gross Margin %',
                    shortdesc: 'GM %',
                    original: this.originalGMPercent,
                    new: this.newGMPercent,
                    variance: this.gmPercentDiff,
                },
                {
                    description: 'Retailer Margin %',
                    shortdesc: 'RM %',
                    original: this.originalRMPercent,
                    new: this.newRMPercent,
                    variance: this.rmPercentDiff,
                },
            ];
        },
        sumTotalUnits() {
            return this.draftPlan.products
                .filter((p) => p.totalUnits > 0)
                .reduce((a, b) => a + b.totalUnits, 0);
        },
        totalUnitsVar() {
            return this.sumTotalUnits - this.draftPlanBase.sumTotalUnits;
        },
        totalUnitsVarPercent() {
            return ((this.totalUnitsVar / this.draftPlanBase.sumTotalUnits) * 100).toFixed(2);
        },
        sumGSV() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.gsv))
                .reduce((a, b) => a + parseFloat(b.gsv), 0);
        },
        sumGSVVar() {
            return this.sumGSV - this.draftPlanBase.sumGSV;
        },
        sumGSVPercent() {
            return ((this.sumGSVVar / this.draftPlanBase.sumGSV) * 100).toFixed(2);
        },

        sumTI() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.tradeInvestment))
                .reduce((a, b) => a + parseFloat(b.tradeInvestment), 0);
        },
        sumTIDiff() {
            return this.sumTI - this.draftPlanBase.sumTI;
        },
        sumTIPercent() {
            return ((this.sumTIDiff / this.draftPlanBase.sumTI) * 100).toFixed(2);
        },

        sumNR() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.netRevenue))
                .reduce((a, b) => a + parseFloat(b.netRevenue), 0);
        },
        sumNRDiff() {
            return this.sumNR - this.draftPlanBase.sumNR;
        },
        sumNRPercent() {
            return ((this.sumNRDiff / this.draftPlanBase.sumNR) * 100).toFixed(2);
        },

        sumCOGS() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.cogs))
                .reduce((a, b) => a + parseFloat(b.cogs), 0);
        },
        sumCOGSDiff() {
            return this.sumCOGS - this.draftPlanBase.sumCOGS;
        },
        sumCOGSPercent() {
            return ((this.sumCOGSDiff / this.draftPlanBase.sumCOGS) * 100).toFixed(2);
        },
        sumGM() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.grossMargin))
                .reduce((a, b) => a + parseFloat(b.grossMargin), 0);
        },
        sumGMDiff() {
            return this.sumGM - this.draftPlanBase.sumGM;
        },
        sumGMPercent() {
            return ((this.sumGMDiff / this.draftPlanBase.sumGM) * 100).toFixed(2);
        },

        sumRM() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.retailerMargin))
                .reduce((a, b) => a + parseFloat(b.retailerMargin), 0);
        },
        sumRMDiff() {
            return this.sumRM - this.draftPlanBase.sumRM;
        },
        sumRMPercent() {
            return ((this.sumRMDiff / this.draftPlanBase.sumRM) * 100).toFixed(2);
        },

        sumRSV() {
            return this.draftPlan.products
                .filter((p) => !isNaN(p.rsv))
                .reduce((a, b) => a + parseFloat(b.rsv), 0);
        },
        sumRSVDiff() {
            return this.sumRSV - this.draftPlanBase.sumRSV;
        },
        sumRSVPercent() {
            return ((this.sumRSVDiff / this.draftPlanBase.sumRSV) * 100).toFixed(2);
        },
        originalGMPercent() {
            return (
                ((this.draftPlanBase.sumGM / this.draftPlanBase.sumNR) * 100)
                    .toFixed(2)
                    .toString() + '%'
            );
        },
        newGMPercent() {
            return ((this.sumGM / this.sumNR) * 100).toFixed(2).toString() + '%';
        },
        gmPercentDiff() {
            return (
                (
                    parseFloat(this.newGMPercent.slice(0, -1)) -
                    parseFloat(this.originalGMPercent.slice(0, -1))
                )
                    .toFixed(2)
                    .toString() + '%'
            );
        },
        originalRMPercent() {
            return (
                ((this.draftPlanBase.sumRM / (this.draftPlanBase.sumRSV / 1.1)) * 100)
                    .toFixed(2)
                    .toString() + '%'
            );
        },
        newRMPercent() {
            return ((this.sumRM / (this.sumRSV / 1.1)) * 100).toFixed(2).toString() + '%';
        },
        rmPercentDiff() {
            return (
                (
                    parseFloat(this.newRMPercent.slice(0, -1)) -
                    parseFloat(this.originalRMPercent.slice(0, -1))
                )
                    .toFixed(2)
                    .toString() + '%'
            );
        },

        tableHeight() {
            return window.innerHeight - this.row1Height - this.row2Height - 155;
        },
        comparisonPlan() {
            return this.plans.find((p) => p.id == this.draftPlan.comparisionPlanId) || {};
        },
        comparisonGrowth() {
            let val =
                ((this.sumNR - this.comparisonPlan?.revenue) / this.comparisonPlan?.revenue) * 100;
            return (isNaN(val) ? 0 : val).toFixed(2);
        },
        comparisonGrowthInMillion() {
            let val = (this.sumNR - this.comparisonPlan?.revenue) / 1000000;
            return (isNaN(val) ? 0 : val).toFixed(0);
        },
        footerProps() {
            return {
                'items-per-page-options': [15, 30, 50, -1],
                showFirstLastPage: true,
            };
        },
        sortedPromoTypes() {
            return this.specialRefData.promotypes.slice()?.sort((a, b) => a.rspRate - b.rspRate);
        },
    },
    methods: {
        ...mapActions(usePlanStore, [
            'GetPlanList',
            'LoadExistingPlan',
            'AddPlanProduct',
            'UpdateEditRowItem',
            'duplicateCurrentPlan',
            'UpdatePlanProducts',
            'CreatePlan',
            'DeletePlan',
            'tearDown',
            'ClearUnSavedChanges',
        ]),
        ...mapActions(masterDataStore, ['GetAllProducts']),
        ...mapActions(useUserStore, ['setSelectedItemObj']),
        onViewReport() {
            this.viewReportMode = true;
            //window.open("https://app.powerbi.com/groups/me/reports/55633fdd-4559-4ca4-977e-e4175b122514/ReportSection4c4e272100478c370c8b?experience=power-bi")
        },
        beforeWindowUnload(e) {
            console.log(111);
            if (this.dirtyItems.length > 0) {
                e.preventDefault();
                e.returnValue = 'sure?';
            }
        },
        clearAddProductFilters() {
            this.brandFilter = [];
            this.categoryFilter = [];
            this.segmentFilter = [];
        },
        isExceedFrequency(item) {
            var groupProducts = this.draftPlan.products.filter(
                (p) => p.productId == item.productId && p.customerId == item.customerId
            );
            var totalFrequency = groupProducts
                .map((p) => {
                    return p.frequency;
                })
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

            return totalFrequency > 52;
        },
        groupProductTotalFrequency(item) {
            var groupProducts = this.draftPlan.products.filter(
                (p) => p.productId == item.productId && p.customerId == item.customerId
            );
            var totalFrequency = groupProducts
                .map((p) => {
                    return p.frequency;
                })
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            return totalFrequency;
        },
        newPlanNameRule(value) {
            return this.plans.findIndex((p) => p.name == value) < 0 || 'Plan name already existed';
        },
        numberOnly(evt) {
            evt = evt ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();

            if (!/^[1-9]\d*$/g.test(expect)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        decimalOnly(evt) {
            evt = evt ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();
            if (evt.key == '.') {
                expect = parseFloat(expect);
            }

            if (!/^\s*?\d+(\.\d{1,2})?\s*$/.test(expect)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onRowError(val) {
            if (val) {
                this.editedItem.errors += this.editedItem.errors + 1;
            } else {
                this.editedItem.errors += this.editedItem.errors - 1;
            }
        },
        row_classes(item) {
            if (
                !this.draftPlan.newName &&
                item.isNew == true &&
                this.dirtyItems.length > 0 &&
                this.dirtyItems.findIndex((i) => i.id == item.id) != -1
            ) {
                return 'dirtyRow'; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        initFilters() {
            for (var col in this.filters) {
                this.filters[col] = this.draftPlan.products
                    .map((d) => {
                        return d[col];
                    })
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
            }
            // TODO restore previous activeFilters before add/remove item
            this.activeFilters = Object.assign({}, this.filters);
            /*if (Object.keys(this.activeFilters).length === 0) this.activeFilters = Object.assign({}, this.filters)
            else {
              setTimeout(() => {
                console.log(this.activeFilters)
                //this.activeFilters = Object.assign({}, this.filters)
              }, 1)
            }*/
        },
        async saveAsNewPlan(newPlanName) {
            this.loading = true;
            let planData = JSON.parse(JSON.stringify(this.draftPlan));
            planData.name = newPlanName;
            let products = JSON.parse(JSON.stringify(planData.products));
            planData.comparisionPlanId = planData.id;
            planData.revenue = this.sumNR;
            planData.margin = this.sumNRPercent;
            planData.isPublic = false;
            planData.planTagId = this.specialRefData.plantags.find((t) => t.name == 'Scenario')?.id;
            delete planData.products;
            await this.CreatePlan(planData);
            products.forEach((p) => {
                p.planId = this.draftPlan.id;
                p.isNew = true;
            });
            await this.UpdatePlanProducts({ planProducts: products });
            this.newPlanName = '';
            this.dirtyItems = [];
            this.ClearUnSavedChanges();
            this.plansavesnackbar = true;
            this.loading = false;
        },
        async onSavePlan() {
            // existing plan
            if (
                await this.$refs.confirmDia.open(
                    'Save Plan Changes',
                    `You are saving the changes of the plan [${this.draftPlan.name}] back to database, are you sure?`,
                    { aggreeTxt: 'Confirm', cancelTxt: 'Cancel' }
                )
            ) {
                this.loading = true;
                if (!this.draftPlan.newName) {
                    this.draftPlan.revenue = this.sumNR;
                    this.draftPlan.margin = this.sumNRPercent;

                    let plantoUpdate = JSON.parse(JSON.stringify(this.draftPlan));
                    delete plantoUpdate.products;
                    try {
                        await updatePlan(plantoUpdate);
                        await this.UpdatePlanProducts({ planProducts: this.dirtyItems });
                        this.dirtyItems = [];
                        this.plansavesnackbar = true;
                        this.ClearUnSavedChanges();
                    } catch (e) {
                        console.log(e);
                    }
                }
                // new plan
                else {
                    let planData = JSON.parse(JSON.stringify(this.draftPlan));
                    let products = JSON.parse(JSON.stringify(planData.products));
                    planData.revenue = this.sumNR;
                    planData.margin = this.sumNRPercent;
                    planData.isPublic = false;
                    planData.planTagId = this.specialRefData.plantags.find(
                        (t) => t.name == 'Scenario'
                    )?.id;
                    delete planData.products;
                    await this.CreatePlan(planData);
                    products.forEach((p) => {
                        p.planId = this.draftPlan.id;
                    });
                    await this.UpdatePlanProducts({ planProducts: products });

                    this.dirtyItems = [];
                    this.ClearUnSavedChanges();
                    this.plansavesnackbar = true;
                }
                this.newPlanName = '';
                this.loading = false;
            }
        },
        onNewPlanCancel() {
            if (this.duplicatePlanMode) {
                this.newPlanDialog = false;
                this.newPlanMode = false;
                this.selectedPlanId = null;
                this.newPlanName = '';
                this.duplicatePlanMode = false;
            } else {
                this.newPlanMode = false;
                this.newPlanName = '';
            }
        },
        recalculate(item) {
            planProductHeaders
                .filter((h) => h.formula)
                .slice()
                .sort((a, b) => a.calLvl - b.calLvl)
                .forEach((h) => {
                    h.formula(item);
                });
            this.updateDirtyItems(item);
        },
        updateDirtyItems(item) {
            let tempItem = this.dirtyItems.find((i) => i.id == item.id);
            if (tempItem) {
                tempItem = item;
            } else {
                this.dirtyItems.push(item);
                this.unsavedChanges.push(item);
            }
        },
        onRowClick(item) {
            this.editedItem = item;
        },
        getIncrementalTI(item) {
            let val = 0;
            let promoType = this.getPromoType(item);
            let range = this.draftPlan.products.filter(
                (p) =>
                    p.productId == item.productId &&
                    p.segment == item.segment &&
                    this.getPromoType(p) == promoType
            );
            let aveTiPerUnit =
                range.map((p) => p.tiPerUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            let aveBaseUnit =
                range.map((p) => p.baseUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            val = item.tiPerUnit * item.volPerWeek - aveTiPerUnit * aveBaseUnit;
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(0);
        },
        getIncrementalNR(item) {
            let val = 0;
            let promoType = this.getPromoType(item);
            let range = this.draftPlan.products.filter(
                (p) =>
                    p.productId == item.productId &&
                    p.segment == item.segment &&
                    this.getPromoType(p) == promoType
            );
            let aveNetLandedPrice =
                range
                    .map((p) => p.netLandedPrice)
                    .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / range.length;
            let aveBaseUnit =
                range.map((p) => p.baseUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            val = item.netLandedPrice * item.volPerWeek - aveNetLandedPrice * aveBaseUnit;
            console.warn('getIncrementalNR');
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(0);
        },
        getIncrementalGM(item) {
            let val = 0;
            let promoType = this.getPromoType(item);
            let range = this.draftPlan.products.filter(
                (p) =>
                    p.productId == item.productId &&
                    p.segment == item.segment &&
                    this.getPromoType(p) == promoType
            );
            let aveGMPerUnit =
                range.map((p) => p.gmPerUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            let aveBaseUnit =
                range.map((p) => p.baseUnit).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
                range.length;
            val = item.gmPerUnit * item.volPerWeek - aveGMPerUnit * aveBaseUnit;
            return isNaN(val) || val == Infinity || val == -Infinity ? 0 : val.toFixed(0);
        },
        getROINR(item) {
            let val = (this.getIncrementalNR(item) * 100) / this.getIncrementalTI(item);
            return this.getPromoType(item) == 'Base' ? 0 : isNaN(val) ? 0 : val.toFixed(0);
        },
        getROIGM(item) {
            let val = (this.getIncrementalGM(item) * 100) / this.getIncrementalTI(item);
            return this.getPromoType(item) == 'Base' ? 0 : isNaN(val) ? 0 : val.toFixed(0);
        },
        getPromoType(item) {
            if (!item.rsp || !item.baseRSP) return 'Error';
            let rate = (1 - item.rsp / item.baseRSP) * 100;
            if (rate < 0) return 'Error';
            let promoType = 'Error';
            //sorted = sorted.slice().sort((a, b) => a.rspRate - b.rspRate)
            for (let idx = 0; idx < this.sortedPromoTypes.length; idx++) {
                if (
                    idx == this.sortedPromoTypes.length - 1 &&
                    rate > this.sortedPromoTypes[idx].rspRate
                ) {
                    promoType = this.sortedPromoTypes[idx].name;
                    break;
                }
                if (
                    rate == this.sortedPromoTypes[idx].rspRate ||
                    (rate > this.sortedPromoTypes[idx].rspRate &&
                        rate < this.sortedPromoTypes[idx + 1].rspRate)
                ) {
                    promoType = this.sortedPromoTypes[idx].name;
                    break;
                }
            }
            //item.promoType = promoType

            return promoType;
        },
        onSelectedTableCols() {
            var cols = {};
            Object.assign(cols, this.selectedCols);
            this.activeCols = cols;
            this.colsDialog = false;
        },
        allowedStartDate(val) {
            return this.editedItem && val
                ? dayjs(val).isBefore(dayjs(this.editedItem.endDate))
                : true;
        },
        allowedEndDate(val) {
            return this.editedItem && val
                ? dayjs(val).isAfter(dayjs(this.editedItem.startDate))
                : true;
        },
        onSelectAllChange(val) {
            var cols = {};
            if (val) {
                planProductHeaders.forEach((h) => {
                    cols[h.value] = true;
                });
            } else {
                Object.assign(cols, this.selectedCols);
                for (let k in cols) {
                    cols[k] = false;
                }
            }
            this.selectedCols = cols;
        },
        onDuplicateCurrentPlan(newName) {
            this.loading = true;
            this.newPlanDialog = false;
            this.duplicateCurrentPlan(newName);
            this.newPlanMode = false;
            this.duplicatePlanMode = false;
            this.newPlanName = '';
            this.selectedPlanId = null;
            this.loading = false;
        },
        async loadExistingPlan(newName) {
            console.log('newName123', this.selectedPlanId, newName);
            this.loading = true;
            await this.LoadExistingPlan(this.selectedPlanId, newName);
            this.initFilters();
            this.dirtyItems = [];
            //await this.GetAllProducts()
            this.loading = false;
        },
        duplicateProduct(item) {
            let newItem = JSON.parse(JSON.stringify(item));
            newItem.id = new Date().getTime();
            newItem.isNew = true;
            this.dirtyItems.push(newItem);
            this.unsavedChanges.push(newItem);
            this.draftPlan.products.unshift(newItem);
            this.snackbar = true;
        },
        addProductToPlan() {
            var newPlanProduct = {};

            planProductHeaders.forEach((h) => {
                newPlanProduct[h.value] = h.defaultValue != undefined ? h.defaultValue : '';
            });
            // newPlanProduct = JSON.parse(JSON.stringify( this.draftPlan.products.find(p=>p.productId == this.editedItem.productId)))
            let masterProduct = this.products.find((p) => p.id == this.editedItem.productId);
            (newPlanProduct.id = new Date().getTime()), //fake id
                (newPlanProduct.isNew = true),
                (newPlanProduct.name = masterProduct.name);
            (newPlanProduct.productId = this.editedItem.productId),
                (newPlanProduct.startDate = this.draftPlan.startDate.substr(0, 10)),
                (newPlanProduct.endDate = this.draftPlan.endDate.substr(0, 10)),
                (newPlanProduct.product = masterProduct.name),
                (newPlanProduct.productCode = masterProduct.code),
                (newPlanProduct.brand = this.referenceData.brands.find(
                    (b) => b.id == masterProduct.brandId
                )?.name),
                this.dirtyItems.push(newPlanProduct);
            this.unsavedChanges.push(newPlanProduct);
            this.draftPlan.products.unshift(newPlanProduct);
            /* 
            planProductHeaders.filter(h => h.formula).sort((a, b) => a.calLvl - b.calLvl).forEach(h => {
                h.formula(newPlanProduct)
            })*/
            this.initFilters();

            this.addProductDialog = false;
            this.clearAddProductFilters();
            this.snackbar = true;
        },
        getColDefinitions(groupTitle) {
            return this.productCols.filter((c) => c.groupTitle == groupTitle);
        },
        init() {
            planProductHeaders
                .filter((h) => h.isDefault)
                .forEach((h) => {
                    this.activeCols[h.value] = true;
                });
        },
        deleteItem(item) {
            this.editedIndex = this.draftPlan.products.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deletePlanConfirm() {
            this.dialogPlanDelete = false;
            this.loading = true;
            await this.DeletePlan(this.draftPlan.id);
            this.setSelectedItemObj('dashboard');
            this.loading = false;
        },
        deleteItemConfirm() {
            this.toBeDeletedPlanProducts.push(this.editedItem);
            this.draftPlan.products.splice(this.editedIndex, 1);
            let tempItem = this.dirtyItems.find((i) => i.id == this.editedItem.id);
            if (tempItem) {
                if (tempItem.isNew) {
                    this.dirtyItems.splice(this.dirtyItems.indexOf(tempItem));
                } else {
                    tempItem.isDelete = true;
                }
            } else {
                this.editedItem.isDelete = true;
                this.dirtyItems.push(this.editedItem);
                this.unsavedChanges.push(this.editedItem);
            }
            this.closeDelete();
        },

        close() {
            //this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        toggleAll(col) {
            this.activeFilters[col] = this.draftPlan.products
                .map((d) => {
                    return d[col];
                })
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
        },

        clearAll(col) {
            this.activeFilters[col] = [];
        },
    },
    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
        window.scenarioBuilder = this;
        this.init();
    },
    beforeDestroy() {
        this.tearDown();
        window.scenarioBuilder = undefined;
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
    async mounted() {
        if (this.$refs?.row1?.clientHeight) {
            this.row1Height = this.$refs?.row1?.clientHeight;
        }

        this.GetPlanList();
        this.initFilters();
    },
    activated() {
        this.initFilters();
    },
    watch: {
        colsDialog: function (val) {
            if (val) {
                Object.assign(this.selectedCols, this.activeCols);
            }
        },
        showPanel: function (val) {
            if (val) {
                this.row2Height = 420;
            } else {
                this.row2Height = 48;
            }
        },
    },
};
</script>

<style>
.tbcell {
    height: 28px !important;
}

.col-chck .v-label {
    font-size: 12px;
}

.editable-removed {
    background: #e4edff;
}

.v-expansion-panel-content__wrap {
    padding: 0 !important;
}

.v-expansion-panel-header {
    min-height: 48px !important;
}

.rgm-input .v-text-field__slot input {
    color: #285cb7 !important;
}

.dirtyRow {
    box-shadow: inset 1px 1px 20px 5px #20b87d;
}

.planproducts-table input {
    cursor: cell;
}
</style>
