<template>
    <v-app>
        <v-navigation-drawer permanent app :mini-variant.sync="mini">
            <v-list-item class="px-2">
                <v-list-item-avatar>
                    <v-img
                        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
                    ></v-img>
                </v-list-item-avatar>

                <v-list-item-title>
                    <span style="color: #1a4a9f">RGM</span
                    ><span style="color: #4072af">plus</span>
                </v-list-item-title>

                <v-btn icon @click.stop="mini = !mini">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-list-item>

            <v-divider class="mx-2"></v-divider>

            <v-list nav dense>
                <v-list-item-group v-model="selectedItemIdx" color="primary">
                    <v-list-item v-for="(item, i) in items" :key="i">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <template v-slot:append>
                <!--<v-list nav dense>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
            </v-list>-->
                <v-divider class="mx-2"></v-divider>
                <v-list>
                    <v-list-item class="px-2">
                        <v-list-item-avatar>
                            <v-img
                                src="https://randomuser.me/api/portraits/women/85.jpg"
                            ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-h7">
                                {{ userProfile.firstName }}
                                {{ userProfile.lastName }}
                                <v-btn class="ml-4" icon @click="logout()">
                                    <v-icon>mdi-logout-variant</v-icon>
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ userProfile.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>

        <!-- Sizes your content based upon application components -->
        <v-main style="padding: 0; background: #e8f1f7">
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <!-- If using vue-router -->
                <ClientList v-if="selectedItem.name === 'clients'"></ClientList>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import ClientList from "./ClientList.vue";

import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";

export default {
    name: "AdminLayout",
    props: {
        userProfile: Object,
    },
    components: {
        ClientList,
    },
    methods: {
        ...mapActions(useUserStore, ["logoutUser"]),
        logout() {
            this.logoutUser();
            this.$router.push("login");
        },
    },
    data: () => ({
        mini: false,
        selectedItemIdx: 0,
        items: [
            { text: "Clients", name: "clients", icon: "mdi-domain" },
            //{ text: 'Users', name: 'users', icon: 'mdi-account-multiple' }
        ],
    }),
    computed: {
        ...mapState(useUserStore, ["currentUserPermissions"]),
        selectedItem() {
            return this.items[this.selectedItemIdx];
        },
    },
};
</script>
