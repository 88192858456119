export const tableItems = [
    {
        terms: "Demand Driving: Category",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving: NPD",
        amountPercentage: 0,
        criteria: 0,
        gold: "Minimum 3 NPD ranged eached year",
        silver: "Minimum 3 NPD ranged eached year",
        bronze: "Minimum 3 NPD ranged eached year",
    },
    {
        terms: "Demand Driving: Range",
        amountPercentage: 4,
        criteria: 80,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving: Secondary Display",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving : Visibility",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving : Consumer Activation",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving : Data Sharing",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Promotional : MOQ",
        amountPercentage: 4,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Promotional : Price",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Growth : Growth",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Efficiency : Logistics",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Scale : Scale",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Other : Grey Market",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving: Category",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Other : Margin Support",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Other : Other",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Long Term : Historic",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Long Term : Value simplicity & every day fair price",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Category",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : NPD",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Range",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Secondary Display",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Visibility",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Consumer Activation",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "Demand Driving: Category",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Data Sharing",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    {
        terms: "A&P : Media Advertising",
        amountPercentage: 0,
        criteria: 0,
        gold: '',
        silver: "",
        bronze: "",
    },
    

]