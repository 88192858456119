import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#4274CE", // #E53935
                secondary: colors.blue.lighten4, // #FFCDD2
                accent: colors.indigo.base, // #3F51B5
                background: "#F9FAFB",
                primary50: "#E4EDFF",
                primary_dark: {
                    base: "#0f3b88",
                    light: "#ffffff",
                },
                primary700: "#285CB7",
                error700: "#B42318",
                success700: "#027A48",
                error50: "#FEF3F2",
            },
        },
    },
});
