import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import { createPinia, PiniaVuePlugin  } from 'pinia'
import router from './router'
import { isAuthenticated } from './common/methods'
import { useUserStore } from './stores/user'

import VueRouter from 'vue-router';
Vue.use(PiniaVuePlugin)

Vue.use(VueRouter);
const pinia = createPinia()

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_WEBAPI_URL

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  if ( !isAuthenticated() && to.meta.requiresAuth &&  to.name !== 'login') next({ name: 'login' })
  else next()
})



new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
