<template>
    <v-app>
        <v-navigation-drawer permanent app :mini-variant.sync="mini">
            <div class="header-container">
                <v-list-item class="px-0">
                    <v-img src="@/assets/revedy.svg" height="50" contain></v-img>

                    <v-list-item-title>
                        <span class="header-logo-text">REVEDY</span></v-list-item-title
                    >

                    <v-btn style="color: black" icon @click.stop="mini = !mini">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item>

                <v-text-field
                    class="rounded-lg"
                    outlined
                    dense
                    placeholder="Search"
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
            </div>
            <!--@click.stop="onItemClicked($event, item)"-->
            <v-list class="px-2" nav dense>
                <v-list-item-group mandatory>
                    <template v-for="(item, i) in filteredItems">
                        <v-list-item
                            v-if="!item.isGroupHeader"
                            :class="item.class || ''"
                            :key="i"
                            @click.stop="onItemClicked($event, item)"
                            :input-value="selectedItemObj.name === item.name"
                            :color="selectedItemObj.name === item.name ? 'primary_dark' : ''"
                            :style="item.style"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="item.icon" v-text="item.icon"></v-icon>
                                <v-img v-else :src="item.customicon" contain> </v-img>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-tooltip
                                    bottom
                                    color="warning"
                                    v-if="item.hasBage && unsavedChanges.length > 0"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-badge offset-x="50" color="error" dot overlap v-on="on"
                                            ><v-list-item-title
                                                v-on="on"
                                                v-text="item.text"
                                            ></v-list-item-title>
                                        </v-badge>
                                    </template>
                                    <span>You have some unsaved changes</span>
                                </v-tooltip>
                                <v-list-item-title v-else v-text="item.text"> </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group
                            no-action
                            :class="item.groupClass || ''"
                            v-else
                            :key="i"
                            :style="item.style"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </template>
                            <template v-slot:prependIcon>
                                <v-list-item-icon v-if="item.icon">
                                    <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-img v-else :src="item.customicon" contain></v-img>
                            </template>
                            <v-list-item
                                v-for="child in item.items"
                                :key="child.text"
                                @click.stop="onItemClicked($event, child)"
                                :style="child.style"
                                :input-value="selectedItemObj.name === child.name"
                                :color="selectedItemObj.name === child.name ? 'primary_dark' : ''"
                            >
                                <v-list-item-icon>
                                    <v-icon v-if="child.icon" v-text="child.icon"></v-icon>
                                    <v-img
                                        v-else
                                        :src="child.customicon"
                                        contain
                                        max-width="24"
                                        max-height="24"
                                    >
                                    </v-img>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-tooltip
                                        bottom
                                        color="warning"
                                        v-if="child.hasBage && unsavedChanges.length > 0"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                offset-x="50"
                                                color="error"
                                                dot
                                                overlap
                                                v-on="on"
                                                ><v-list-item-title
                                                    v-on="on"
                                                    v-text="child.text"
                                                ></v-list-item-title>
                                            </v-badge>
                                        </template>
                                        <span>You have some unsaved changes</span>
                                    </v-tooltip>
                                    <v-list-item-title v-else v-text="child.text">
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                    <div v-if="InDevItems.length > 0" class="ma-2" :style="{ color: '#98A2B3' }">
                        Coming Soon
                    </div>

                    <v-list-item
                        v-for="devItem in InDevItems"
                        :key="devItem.text"
                        @click.stop="onItemClicked($event, devItem)"
                        :style="devItem.style"
                    >
                        <v-list-item-icon>
                            <v-icon v-if="devItem.icon" v-text="devItem.icon"></v-icon>
                            <v-img v-else :src="devItem.customicon" contain> </v-img>
                        </v-list-item-icon>
                        <v-list-item-title v-text="devItem.text"> </v-list-item-title>
                        <v-list-item-content> </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <template v-slot:append>
                <!--<v-list nav dense>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
            </v-list>-->
                <v-divider class="mx-2"></v-divider>
                <v-list>
                    <v-list-item class="px-2">
                        <v-list-item-avatar>
                            <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-h7">
                                {{ userProfile.firstName }}
                                {{ userProfile.lastName }}
                                <v-btn class="ml-4" icon @click="logout()">
                                    <v-icon>mdi-logout-variant</v-icon>
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ userProfile.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>

        <!-- Sizes your content based upon application components -->
        <v-main style="padding: 0; background: #ecf3ff">
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <keep-alive>
                    <component
                        :is="selectedItemObj.component"
                        v-bind="{ ...selectedItemObj.props }"
                        v-on="selectedItemObj.on"
                        @onSwitchMenu="onSwitchMenu"
                    ></component>
                </keep-alive>

                <!--<Dashboard v-if="selectedItem.name === 'dashboard'" @loadPlan="selectedItemIdx = 1"></Dashboard>
                <KeepAlive>
                    <ScenarioBuilder v-if="selectedItem.name === 'scenariobuilder'" @planDeleted="selectedItemIdx = 0">
                    </ScenarioBuilder>
                </KeepAlive>
                <ProductData v-if="selectedItem.name === 'products'"></ProductData>
                <RefDataList v-if="selectedItem.name === 'refdata' && canManageRefDate"></RefDataList>
                <UserList v-if="selectedItem.name === 'userlist' && canManageUsers"></UserList>
                <PromoBoosterTable v-if="selectedItem.name === 'promobooster'"></PromoBoosterTable>-->
            </v-container>
        </v-main>

        <v-dialog
            v-model="dialog"
            :max-width="options.width"
            :style="{ zIndex: options.zIndex }"
            @keydown.esc="cancel"
        >
            <v-card>
                <v-toolbar dark :color="options.color" dense flat>
                    <v-toolbar-title class="text-body-2 font-weight-bold white--text">
                        {{ title }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text
                    v-show="!!message"
                    class="pa-4 black--text"
                    v-html="message"
                ></v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>

                    <v-btn
                        color="grey"
                        class="body-2 font-weight-bold"
                        outlined
                        @click.native="agree"
                        >Leave</v-btn
                    >
                    <v-btn
                        v-if="!options.noconfirm"
                        color="primary"
                        text
                        class="body-2 font-weight-bold"
                        @click.native="cancel"
                        >Stay</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import Dashboard from './Dashboard.vue';
import ProductData from './ProductData.vue';
import RefDataList from '../TenantAdmin/RefDataList.vue';
import { mapActions, mapState } from 'pinia';
import { useUserStore } from '../../stores/user';
import UserList from '../TenantAdmin/UserList.vue';
import { userPermissions } from './../../common/constants';
import ScenarioBuilder from './ScenarioBuilder.vue';
import { usePlanStore } from '@/stores/plan';
import PromoBoosterTable from './PromoBoosterTable.vue';
import { Forecast_Main, Forecast_Output, Forecast_Summary } from './Forecast/index';
import PowerBILoader from './PowerBILoader.vue';
import CustomerPricing from './CustomerPricing.vue';
import StructuredPricing from './StructuredPricing.vue';
import CustomerContractPricing from './CustomerContractPricing.vue';
import CustomerPricingPerformance from './CustomerPricingPerformance.vue';
export default {
    name: 'EndUserLayout',
    props: {
        userProfile: Object,
    },
    components: {
        Dashboard: Dashboard,
        ProductData: ProductData,
        RefDataList: RefDataList,
        UserList: UserList,
        ScenarioBuilder: ScenarioBuilder,
        Forecast_Main,
        Forecast_Output,
        Forecast_Summary,
        PromoBoosterTable: PromoBoosterTable,
        PowerBILoader: PowerBILoader,
        CustomerPricing,
        StructuredPricing,
        CustomerContractPricing,
        CustomerPricingPerformance,
    },
    data: () => {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            SummaryVueId: null,
            createPlanData: null,
            message: null,
            title: null,
            options: {
                color: 'primary',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            mini: false,
        };
    },
    mounted() {
        this.setSelectedItemObj('dashboard');
    },
    methods: {
        ...mapActions(useUserStore, ['logoutUser', 'setLeftMenuIndex', 'setSelectedItemObj']),
        onChange(v) {
            console.log(v);
        },
        onSwitchMenu(target) {
            console.log('target', target);
            console.log(this.filteredItems);
            const parent = this.filteredItems.find((i) => i.name === target);
            if (!parent) {
                const child = this.filteredItems
                    .filter((i) => i.items && i.items.length > 0)
                    ?.find((p) => p.items.find((i) => i.name === target))
                    ?.items?.find((i) => i.name === target);
                if (child) this.setSelectedItemObj(child);
            } else {
                this.setSelectedItemObj(parent);
            }
        },
        onItemClicked(e, item) {
            if (item.onClick) {
                item.onClick?.();
            } else {
                this.setSelectedItemObj(item);
            }
        },
        async logout() {
            if (window.scenarioBuilder && window.scenarioBuilder.dirtyItems.length > 0) {
                if (
                    await this.open(
                        'Unsaved Changes',
                        'There are some unsaved changes in scenario builder, are your sure you want to discard the changes and log out?'
                    )
                ) {
                    window.scenarioBuilder.dirtyItems = [];
                    this.logoutUser();
                    this.$router.push('login');
                }
            } else {
                this.logoutUser();
                this.$router.push('login');
            }
        },
        loadPlanToSB() {},
        open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
    computed: {
        ...mapState(useUserStore, [
            'currentUserPermissions',
            'leftMenuIndex',
            'menuItems',
            'selectedItemObj',
        ]),
        ...mapState(usePlanStore, ['unsavedChanges']),
        filteredItems() {
            return this.menuItems.filter(
                (i) =>
                    (!i.indev && !i.requiredPermission) ||
                    this.currentUserPermissions.findIndex((p) => p === i.requiredPermission) > -1
            );
        },
        InDevItems() {
            return this.menuItems.filter((i) => i.indev);
        },
        canManageRefDate() {
            return (
                this.currentUserPermissions.findIndex((p) => p === 'Permissions.RefData.Update') >
                -1
            );
        },
        canManageUsers() {
            return (
                this.currentUserPermissions.findIndex((p) => p === 'Permissions.Users.Update') > -1
            );
        },
    },
};
</script>

<style>
.activeMenu {
    background: #285cb7;
}

.header-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 8px 0px 8px;
}

.header-logo-text {
    color: #1a4a9f;
    font-size: 24px;
    font-weight: 700;
}

.navGroupContainer > .v-list-group__header {
    border-radius: 8px;
    padding: 4px 16px;
    margin: 4px 0px;
}

.pricing_container > .v-list-group__header {
    background-color: #6938ef;
    color: white !important;
}

.forecast_container > .v-list-group__header {
    background-color: #6090e6;
    color: white !important;
}

.navGroupContainer > .v-list-group__items > .v-list-item {
    border-radius: 8px;
    padding: 4px 24px !important;
}

.navGroupContainer > .v-list-group__items {
    border-radius: 8px;
    padding: 0px 0px !important;
}

.navContainer {
    border-radius: 8px !important;
    padding: 4px 16px !important;
    margin: 4px 0px !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 12px;
}
</style>
