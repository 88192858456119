import { axios } from '@/lib';

const GET_REQUEST = async (url, params) => {
    return await axios
        .get(url, {
            params,
        })
        .then(
            (response) => {
                return response?.data;
            },
            (error) => {
                return {
                    status: false,
                    message: error?.message || JSON.stringify(error),
                    data: false,
                };
            }
        );
};

const POST_REQUEST = async (url, data, params) => {
    return await axios.post(url, data, { params }).then(
        (response) => {
            return response?.data;
        },
        (error) => {
            return {
                status: false,
                message: error?.message || JSON.stringify(error),
                data: false,
            };
        }
    );
};

const PUT_REQUEST = async (url, data, params) => {
    return await axios.put(url, data, { params }).then(
        (response) => {
            return response?.data;
        },
        (error) => {
            return {
                status: false,
                message: error?.message || JSON.stringify(error),
                data: false,
            };
        }
    );
};

const DELETE_REQUEST = async (url, params) => {
    return await axios
        .delete(url, {
            params,
        })
        .then(
            (response) => {
                return response?.data;
            },
            (error) => {
                return {
                    status: false,
                    message: error?.message || JSON.stringify(error),
                    data: false,
                };
            }
        );
};

export default { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST };
