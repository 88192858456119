<template>
    <v-dialog v-model="load" persistent width="300">
        <v-card color="primary_dark">
            <v-card-text>
                Processing
                <v-progress-linear
                    :indeterminate="load"
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Loading_Dialog',
    props: ['loading'],
    data: () => ({
        load: this.loading,
    }),
    watch: {
        load: function () {
            console.log(this.load);
        },
    },
};
</script>
