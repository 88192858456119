const PREFIX = '/api/v1';

const append = (route) => {
    return `${PREFIX}/${route}`;
};

export default {
    forecast: {
        create_plan: append('forcast/create/plan'),
        clone: append(`forcast/get/detail`),
        create_popup_data: append('forcast/get/dropdown'),
        details_list: append('forcast/get/forecast/details/all'),
        // table_data: append('forcast/get/all/products/week'),
        table_data: append('forcast/get/all/products/week/test/dev'),
        saved_table_data: append('forcast/get/saved/products'),
        existing_popup_data: append('forcast/get/forecast/all'),
        filtersList: append('forcast/get/customer/segment/category'),
        week_calculate: append('forcast/get/all/products/week/calculation'),
        freq_and_clash_forward_plans: append('forcast/filtered/forcast'),
        prod_and_customer_clash: append('forcast/clashing/available/customer/products'),
        save_plan: append('forcast/save'),
        save_as_plan: append('forcast/save-as'),
        clashing_table_data: append('forcast/get/clashing/report'),
        freq_fc_plan_table: append('forcast/get/scenario/frequency/report'),
        freq_compare_fc_plan_table: append('forcast/get/frequency/report'),
        delete_fc_plan: append('forcast/delete/forcast/plan/weeks'),
        get_products: append('forcast/get/filtered/products'),
    },
};
