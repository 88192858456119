import axios from 'axios';
import router from './../router/index';
import { useUserStore } from '@/stores/user';
import { emptyGuid } from './constants';
import fcPlanData from './fcDemoData.json';
import prodAndCus from './prodAndCus.json';
import clashingTableData from './clashingTableData.json';
import frequencyTableData from './frequencyReportTable.json';

import existingFcPlan from './existingFcPlan.json';
import newTableData from './fcDemoData.json';
import { HOST_API } from '@/global-config';
import APICall from './commonService.js';
import commonService from '@/api/common-service';
import endpoints from '@/lib/endpoints';

// const TESTING_URL = 'https://testrevedy.azurewebsites.net';
// const TESTING_URL = 'https://scenariobuilderwebapi-uat.azurewebsites.net';

const instance = axios.create({
    baseURL: HOST_API,
});

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if (err.response.status === 401) {
            const userStore = useUserStore();
            router.push('login');
        }
        return Promise.reject(err);
    }
);

instance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

export const getCurrentUserProfile = async function () {
    const result = await instance.get('/api/personal/profile');
    return result.data;
};

export const getAllTenants = async function () {
    const result = await instance.get('/api/tenants');
    return result.data;
};

export const getAllUsers = async function () {
    const result = await instance.get('/api/users');
    return result.data;
};

export const getCurrentUserPermissions = async function () {
    const result = await instance.get('api/personal/permissions');
    return result.data;
};

export const getAllReferenceData = async function () {
    const result = await instance.get('api/v1/referencedata');
    return result.data;
};

export const updateRefData = async function (refdataCollectionName, refdataObj) {
    const result = await instance.put(
        `/api/v1/referencedata/${refdataCollectionName}/${refdataObj.id}`,
        refdataObj
    );
    if (result.status === 200) {
        return result.data;
    }
    return null;
};

export const createRefData = async function (refdataCollectionName, refdataObj) {
    const result = await instance.post(
        `/api/v1/referencedata/${refdataCollectionName}`,
        refdataObj
    );

    if (result.status === 200) {
        return result.data;
    }

    return null;
};

export const deleteRefData = async function (refdataCollectionName, refdataObj) {
    const result = await instance.delete(
        `/api/v1/referencedata/${refdataCollectionName}/${refdataObj.id}`
    );

    if (result.status === 200) {
        return result.data;
    }

    return null;
};

export const createUser = async function (userObj) {
    const result = await instance.post('/api/users', {
        firstName: userObj.firstName,
        lastName: userObj.lastName,
        email: userObj.email,
        userName: userObj.userName,
        password: userObj.password,
        confirmPassword: userObj.confirmPassword,
        phoneNumber: userObj.phoneNumber || '',
    });
    return result;
};

export const toggleUserStatus = async function (userObj) {
    const result = await instance.post(`/api/users/${userObj.id}/toggle-status`, {
        activateUser: !userObj.isActive,
        userId: userObj.id,
    });
    return result;
};

export const getAllCustomers = async function () {
    const res = await instance.get('/api/v1/referencedata/customers');
    return res;
};

export const getAllPlanTags = async function () {
    const res = await instance.get('/api/v1/referencedata/plantags');
    return res;
};

export const getAllPromoTypes = async function () {
    const res = await instance.get('/api/v1/referencedata/promotypes');
    return res;
};

export const getAllElasticities = async function () {
    const res = await instance.get('/api/v1/referencedata/elasticities');
    return res;
};

export const getAllProducts = async function () {
    const res = await instance.get('/api/v1/products/all');
    return res;
};

export const createProduct = async function (productObj) {
    productObj.measureAmount = productObj.measureAmount || productObj.size;
    const res = await instance.post('/api/v1/products', productObj);
    return res;
};

export const updateProduct = async function (productObj) {
    productObj.measureAmount = productObj.measureAmount || productObj.size;
    const res = await instance.put(`/api/v1/products/${productObj.id}`, productObj);
    return res;
};
export const deleteProduct = async function (productObj) {
    const res = await instance.delete(`/api/v1/products/${productObj.id}`);
    return res;
};

export const getPlanList = async function () {
    const res = await instance.get('/api/v1/plans/all');
    return res;
};

export const getClasingPlansList = async function () {
    return await commonService.GET_REQUEST(endpoints.forecast.filtersList);

    // return existingFcPlan;
};

export const getExistingFcPlanList = async function () {
    return await commonService.GET_REQUEST(endpoints.forecast.existing_popup_data);
};

export const getClashigFcPlanList = async function (segmentId, categoryId) {
    console.log('segmentIdScategoryId', segmentId, categoryId);

    return await commonService.POST_REQUEST(endpoints.forecast.freq_and_clash_forward_plans, {
        segmentId,
        categoryId,
    });
};

export const getFreqFcPlansList = async function (customerId, categoryId) {
    return await commonService.POST_REQUEST(endpoints.forecast.freq_and_clash_forward_plans, {
        customerId,
        categoryId,
    });
};

export const getFcPlanList = async function () {
    return await commonService.GET_REQUEST(endpoints.forecast.create_popup_data);
};

export const getWeekCalData = async function (payload) {
    console.log('payloadweekData', payload);

    return await commonService.POST_REQUEST(endpoints.forecast.week_calculate, payload);

    // const res = await instance.get(
    //     `${TESTING_URL}/api/v1/forcast/get/forecast/all`
    // );
    // return true;
};

export const ClashingTableData = async function (payload) {
    console.log('payloadweekData', payload);

    return await commonService.POST_REQUEST(endpoints.forecast.clashing_table_data, payload);

    // return clashingTableData;
};

export const FrequencyTableData = async function (payload) {
    console.log('frequencyT1Payload', payload);

    return await commonService.POST_REQUEST(endpoints.forecast.freq_fc_plan_table, payload);

    // return frequencyTableData;
};

export const FrequencyTable2Data = async function (payload) {
    console.log('frequencyT2Payload', payload);

    return await commonService.POST_REQUEST(endpoints.forecast.freq_compare_fc_plan_table, payload);

    // return frequencyTableData;
};

export const getFcTableData = async function (payload) {
    payload = {
        ...payload,
        page: 2,
        pageSize: 1,
    };
    return await commonService.POST_REQUEST(endpoints.forecast.table_data, payload);
};

export const getFcSavedTableData = async function (payload) {
    payload = {
        ...payload,
        page: 2,
        pageSize: 1,
    };
    return await commonService.POST_REQUEST(endpoints.forecast.saved_table_data, payload);
};

export const updatePlan = async function (planObj) {
    try {
        const res = await instance.put(`/api/v1/plans/${planObj.id}`, planObj);
        return res;
    } catch (err) {
        return err.response;
    }
};

export const getPlanDetails = async function (planId) {
    console.log('planIddata', planId);
    const res = await instance.get(`/api/v1/plans/dapper?id=${planId}`);
    return res;
};

export const getFcPlanDetails = async function (planId) {
    return await commonService.GET_REQUEST(endpoints.forecast.clone, { Id: planId });
};

export const getCustomerAndProducts = async function (planId) {
    return await commonService.GET_REQUEST(endpoints.forecast.prod_and_customer_clash, {
        forwardPlanId: planId,
    });

    // return prodAndCus;
};

export const getDetailsList = async function () {
    return await commonService.GET_REQUEST(endpoints.forecast.details_list);
};

export const getProductsFromCustomerAndSegment = async function (payload) {
    return await commonService.POST_REQUEST(endpoints.forecast.get_products, payload);
};

export const getExistingFcPlanDetails = async function (
    newPlanName,
    selectedPlanId,
    newPlanStartDate,
    newPlanYear,
    newPlanCategory,
    switchValue
) {
    console.log('res1122', newPlanName, selectedPlanId, newPlanYear, newPlanCategory, switchValue);
    const body = {
        categoryId: newPlanCategory,
        forwardPlanName: newPlanName,
        startDate: newPlanStartDate,
        year: newPlanYear,
        scenarioBuilderPlanId: selectedPlanId,
        isPublic: switchValue,
    };
    const res = await commonService.POST_REQUEST(endpoints.forecast.create_plan, body);
    // const res = await instance.post(`/api/v1/forcast/create/plan`, body);
    console.log('res1122', res);
    return res;
};

export const updatePlanProducts = async function (products) {
    var copiedProducts = JSON.parse(JSON.stringify(products));
    copiedProducts.planProducts
        .filter((p) => p.isNew)
        .forEach((p) => {
            p.id = emptyGuid;
        });
    const res = await instance.post(`/api/v1/plans/products`, copiedProducts);
    return res;
};

export const createPlan = async function (planData) {
    const res = await instance.post(`/api/v1/plans`, planData);
    return res;
};

export const deletePlan = async function (planId) {
    const res = await instance.delete(`/api/v1/plans/${planId}`);
    return res;
};

export const updatePlanCogs = async function (planList) {
    const res = await instance.post(`/api/v1/plans/updatecogs`, planList);
    return res;
};

export const createTenant = async function (tenantObj) {
    const res = await instance.post(`/api/tenants`, tenantObj);
    return res;
};

export const getUserQuota = async function (tenantId) {
    const res = await instance.get(`/api/users/quota`);
    return res;
};

export const updateTenant = async function (updateRequest) {
    const res = await instance.post(
        `/api/tenants/${updateRequest.tenantId}/upgrade`,
        updateRequest
    );
    return res;
};

export const toggleTenantStatus = async function (toggleRequest, activate) {
    const res = await instance.post(
        `/api/tenants/${toggleRequest.tenantId}/${activate ? 'activate' : 'deactivate'}`,
        toggleRequest
    );
    return res;
};

export const newTableFormat = async function (updateRequest) {
    const res = newTableData?.forecast;
    console.log('newTableData', res);
    return res;
};

export const saveFcPlan = async function (payload, startDate) {
    return await commonService.POST_REQUEST(endpoints.forecast.save_plan, payload, { startDate });
};

export const saveAsFcPlan = async function (payload, params) {
    return await commonService.POST_REQUEST(endpoints.forecast.save_as_plan, payload, params);
};

export const deleteFcPlan = async function (id) {
    return await commonService.DELETE_REQUEST(endpoints.forecast.delete_fc_plan, {
        forcastPlanId: id,
    });
};
